// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Requests: 'طلبات',
  'Create New Request': 'إنشاء طلب جديد',
  'Request Created Successfully': 'تم إضافة طلب بنجاح',
  'Is Request?': 'خاص بالطلبات',
  'Thank You Request Is Completed': 'شكرا لك اكتمال طلب',
  'Next Step': 'الخطوة التالية',
  "WE Are Sorry You Don't Have The Permisssion to see this Form": 'نأسف لعدم الحصول على إذن لرؤية هذا النموذج',
  "We're sorry, you don't have permission": 'نحن آسفون، ليس لديك صلاحيه',
  Read: 'رؤية',
  Create: 'إنشاء',
  'Form Id': 'ID النموذج',
  'Edit FormRoles': 'تحرير صلاحيات النموذج',
  'Delete FormRole': 'حذف صلاحيات النموذج',
  'Are you sure you want to delete this FormRoles? All the data will be permanently removed from our servers forever. This action cannot be undone':
    'هل أنت متأكد أنك تريد حذف صلاحيات النموذج هذا؟سيتم إزالتها بشكل دائم من خوادمنا إلى الأبد.لا يمكن التراجع عن هذا الإجراء',
  'The FormRole has been modified successfully': 'تم تعديل صلاحيات النموذج بنجاح',
  'The FormRole has been deleted successfully': 'تم حذف صلاحيات النموذج بنجاح',
  'En Name': 'الاسم انجليزي',
  'Mobile No.': 'رقم المحمول',
  'ID NO.': 'رقم بطاقة الهوية',
  'Reason Of Visit': 'سبب الزيارة',
  'Visitor Status': 'حالة الزائر',
  'Select Visitor Status': 'حدد حالة الزائر',
  'Age Group': 'الفئة العمرية',
  'Select Age Group': 'حدد الفئة العمرية',
  'Level Of Education': 'مستوى التعليم',
  'Select Level Of Education': 'حدد مستوى التعليم',
  'Employee status': 'حالة الموظف',
  'Select Employee status': 'حدد حالة الموظف',
  Depondents: 'الفروع',
  'Select dependents': 'حدد فرع',
  'Select Reason Of Visit': 'اختر سبب الزيارة',
  'Registeration Method': 'طريقة التسجيل',
  'Select Registeration Method': 'حدد طريقة التسجيل',
  Occupations: 'المهن',
  'Select Occupations': 'حدد المهنه',
  'Registeration Report from': 'تقرير العائدين من',
  Subject: 'الموضوع',
  Details: 'التفاصيل',
  'ADD NEW VISITOR': 'إضافة زائر جديد',
  'Add Data': 'إضافة بيانات',
  'Generate user name': 'انشاء مستخدم',
  'User Data': 'بيانات المستخدم',
  'Create Requests': 'إنشاء طلب جديد',
  'Identical Card': 'البطاقة التعريفية',
  'Follo Up Visitor': 'زائرون متابعه',
  'Previous Visitors': 'زائرون سابقون',
  'Visitor changed, Successfully': 'تم تعديل الزائر، بنجاح',
};
