import React, { useEffect, useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { dir } from '../utils/localisation';

export default function CustomTableAr(props) {
  const { queryData, dataColumns, className } = props;

  const newQueryData = queryData.map((e, index) => {
    return { ...e, index: index + 1 };
  });
  const data = queryData;

  const columns = useMemo(() => dataColumns, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  return (
    <div className="flex flex-col bg-white p-4">
      <div className="-my-2 overflow-x-auto ">
        <div className={`py-2 align-middle inline-block min-w-full  sm:px-6 lg:px-8 ${className}`}>
          <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className=" divide-y-2 divide-gray-200 min-w-full" {...getTableProps()}>
              <thead className=" text-sm font-bold text-black ">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="border-[#dddddd] items-center  text-mainfont border-b-2 space-x-1"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        className={`px-6 py-3 ${dir('text-right', 'text-right')}    uppercase font-bold text-sm `}
                        // className={`  px-1 ${dir('border-r text-left', 'border-l text-right')} `}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <span className=" whitespace-nowrap ">{column.render('Header')}</span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal"
                {...getTableBodyProps()}
              >
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className=" border-[#dddddd] relative mb-2 py-2 max-w-full text-sm border-b rounded-lg space-x-1 "
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className={`px-6 py-4  whitespace-pre-line font-bold text-base ${dir(
                              ' text-right',
                              ' text-right'
                            )} `}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
