import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import Loading from '../../SharedComponents/Loading';
import ChevronLeft from '../../SharedComponents/Svg/ChevronLeft';
import ChevronRight from '../../SharedComponents/Svg/ChevronRight';
import { ChangeRequestStatus, CheckRequestFormHaveAnswer, GetDataOfRequest, getRequestDataWithId } from './ApRequests';
import CreateFormData from './CreateFormData';
import EditFormData from './EditFormData';
import FormContainer from './FormContainer';
import CheckCircle from '../../SharedComponents/Svg/CheckCircle';
import { parse } from 'postcss';
import RequestHolder from './RequestHolder';
import toast from 'react-hot-toast';
import Notification from '../../SharedComponents/Notification';
import GenerateUserForm from './GenerateUserForm';
import FormUSerData from './FormUserData';
export default function Request(props) {
  // TO DO
  // if Parameter  = 0  || null || undefined
  // return Form === 1018
  // if statusID = 1
  // return Form === 1010
  const params = useParams();
  console.log('params', params);
  const { requestId, status } = params;
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const ChangeRequestStatusMutation = useMutation(ChangeRequestStatus, {
    onSuccess: (data) => {
      console.log('ChangeRequestStatusMutation', data);
      history.push(`/request/${parseInt(status) + 1}/${requestId}`);

    },
  });

  const CheckRequestFormHaveAnswerMutation = useMutation(CheckRequestFormHaveAnswer, {
    onError: () => {},
    onSuccess: (data) => {
      if (data.isDone) {
        ChangeRequestStatusMutation.mutate({
          id: requestId,
          requestStatus: parseInt(status) + 1,
        });
      } else {
        toast.custom(<Notification error message={t('Please Fill All Data Before going to next step')} />);
      }
      // console.log('CheckRequestFormHaveAnswerMutation', data);
    },
  });

  const requestBody = {
    RequestDataId: requestId,
  };

  const { data, isLoading, isError } = useQuery(['GetRequestDataWithId', requestBody], getRequestDataWithId, {
    retry: false,
  });

  // const formID = status === '0' ? 1017 : status === '1' ? 1018 : status === '2' ? 1019 : status === '3' ? 1020 : null;

  if (isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError) return 'error';

  const nextStep = () => {
    if (parseInt(status) === 0) {
      CheckRequestFormHaveAnswerMutation.mutate({
        formId: [1017],
        requestDataId: requestId,
      });
    } else if (parseInt(status) === 1) {
      CheckRequestFormHaveAnswerMutation.mutate({
        formId: [1018],
        requestDataId: requestId,
      });
    } else if (parseInt(status) === 2) {
      CheckRequestFormHaveAnswerMutation.mutate({
        formId: [1019, 1022],
        requestDataId: requestId,
      });
    } else if (parseInt(status) === 3) {
      CheckRequestFormHaveAnswerMutation.mutate({
        formId: [1020],
        requestDataId: requestId,
      });
    }
  };
  const steps = [
    { id: 1, title: 'Add Data' },
    { id: 2, title: 'Generate user name' },
    { id: 3, title: 'Identical Card' },
  ];
  return (
    <div>
      <>
        {/* {addAnswerListMutation.isSuccess && <Notification message={t('Request Created Successfully')} />} */}
        <header className="flex justify-between items-center">
          <BreadCrumbs to="/requests/0" name={t('Request ID ')} />
          <div className="flex items-center space-x-4 ">
            {status > 0 && (
              <Link
                type="button"
                to={`/request/${parseInt(status) - 1}/${requestId}`}
                className={` flex items-center justify-center py-2 px-2 rounded-md text-white bg-darkblue hover:bg-darkbluehover  `}
              >
                <span>
                  <ChevronLeft className="w-6 h-6" />
                </span>
                {/* {t('Previous Step')} */}
              </Link>
            )}
            {status < 4 && (
              <Link
                to={`/request/${parseInt(status) + 1}/${requestId}`}
                className={` flex items-center justify-center py-2 px-2 rounded-md text-white bg-lightgreen hover:bg-lightgreenhover  `}
              >
                {/* <span>{t('Next Step')}</span> */}
                <ChevronRight className="w-6 h-6" />
              </Link>
            )}
          </div>
        </header>

        <div className="bg-white mt-4 pt-4 px-6">
          <h2 className="text-xl font-bold px-4 pb-4 border-b">{t('ADD / EDIT NEW VISITOR')}</h2>
        </div>
        <div className="bg-white py-4">
          <div className="flex space-x-12 mx-6 border-b">
            {steps.map((step, index) => {
              return (
                <div className={`flex  relative py-4 px-2`}>
                  <span
                    className={` ${
                      step.id <= status ? 'bg-move text-white' : 'text-gray-400'
                    } text-xl rounded  w-8 h-8 flex items-center justify-center`}
                  >
                    {step.id}
                  </span>
                  <span className={`text-lg px-2 ${step.id <= status ? 'text-move' : 'text-gray-400'} `}>
                    {step.title}
                  </span>
                  {}
                  <hr
                    className={`absolute bottom-0 left-0 w-full  border ${
                      step.id <= status ? 'border-darkblue' : 'hidden'
                    } `}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="bg-white ">
          {/* <div className="pt-2">
          <div>
            {languages.data && languages.data?.data && (
              <LanguageList
                className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                languages={languages.data?.data}
                languageId={languageId}
                setLanguageId={setLanguageId}
              />
            )}
          </div>
        </div> */}
          <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />

          {status === '0' && <RequestHolder formID={1017} requestStatus={0} />}
          {status === '1' && <RequestHolder formID={1018} requestStatus={1} />}
          {status === '2' && (
            <>
              <GenerateUserForm />
              {/* <RequestHolder formID={1019} requestStatus={2} /> <hr /> <RequestHolder formID={1022} requestStatus={2} />{' '} */}
            </>
          )}
          {status === '3' && <FormUSerData />}
          {status === '4' && (
            <>
              <ThankYou />
            </>
          )}

          {/* {console.log(+status, '+', data.data.request.requestStatus)} */}
          {/* {getSingleDataOfRequest.isError && (
            <>
              {status === '0' && <CreateFormData formID={1017} requestStatus={0} />}
              {status === '1' && <CreateFormData formID={1018} requestStatus={1} />}
              {status === '2' && <><CreateFormData formID={1019} requestStatus={2} /><hr />{' '} <CreateFormData formID={1022} requestStatus={2} /></>}
              {status === '3' && <CreateFormData formID={1020} requestStatus={3} />}
              {status === '4' && (
                <>
                  <ThankYou />
                </>
              )}
            </>
          )} */}

          {/* {+status !== 4 && (
            <div className="p-4 text-right">
              <button
                onClick={nextStep}
                className="bg-darkblue flex justify-center items-center hover:bg-darkbluehover text-white px-4 py-2 rounded"
              >
                {t('Next Step')}
              </button>
            </div>
          )} */}
        </div>
      </>
    </div>
  );
}

const ThankYou = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-center justify-center flex-col p-24">
        <CheckCircle className="w-20 h-20 text-green-400" />
        <h1 className="text-4xl my-8 text-gray-800 "> {t('Thank You Request Is Completed')}</h1>
      </div>
    </>
  );
};
