import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import Search from '../SharedComponents/Svg/Search';
import PlusCircle from './Svg/PlusCircle';

export default function TableSearch(props) {
  const { onSearch } = props;

  const onKeyDownCapture = (e) => {
    console.log('e', e);
  };

  const { t, i18n } = useTranslation();
  return (
    <div className="flex items-center justify-between pb-6">
      <div className={`flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'} space-x-4 text-gray-600`}>
        <Formik initialValues={{ target: { value: '' } }} onKeyDownCapture={onKeyDownCapture}>
          {({ values }) => {
            return (
              <div className="flex items-center relative  w-max">
                <Field
                  name="target.value"
                  onKeyDownCapture={(e) => {
                    if (e.key === 'Enter') {
                      console.log(e);
                      // onSearch(e);
                    }
                  }}
                  className="border rounded w-64 py-2 px-2 border-[#d1d5db] text-[#373d4c]"
                  type="text"
                  placeholder={t('Search')}
                />
                <button
                  type="button"
                  onClick={(e) => {
                    onSearch(values);
                    console.log(values);
                  }}
                  className={`border rounded py-2 px-2 h-full flex items-center bg-move hover:bg-movehover text-white absolute  ${
                    i18n.language === 'en' ? 'right-0' : 'left-0'
                  }`}
                >
                  <Search className="w-7 h-7" />
                </button>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
