import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from '../../../SharedComponents/OutsideClickHandler';
import ChevronDown from '../../../SharedComponents/Svg/ChevronDown';
import { dir } from '../../../utils/localisation';

export default function DropDown(props) {
  const {
    title,
    placeholder,
    errors,
    values,
    loading,
    error,
    list,
    FieldValue,
    setFieldValue,
    FieldName,
    disabled,
    dropData,
  } = props;
  let defaultValue = list?.find((e) => e.id === FieldValue);
  const [value, setValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState(defaultValue ? defaultValue?.frmQoptionLngs[0].title : '');
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const { t, i18n } = useTranslation();
  const onClick = (data) => {
    setFieldValue(FieldName, data.id);
    dropData && dropData(data);
    setSearchParam(data.frmQoptionLngs[0].title);
    setIsOpen(false);
  };
  useEffect(() => {
    if (FieldValue?.length === 0) {
      setSearchParam(FieldValue);
    }
  }, [FieldValue]);

  return (
    <label className="relative pt-4 w-full">
      <OutsideClickHandler setIsOpen={setIsOpen}>
        <span className={`text-[#666666] text-sm font-medium pb-2 ${dir('', 'text-right')}`}>{t(title)} *</span>
        <input
          disabled={disabled}
          autoComplete="off"
          name="groupId"
          placeholder={t(placeholder)}
          type="text"
          className={`w-full p-3 border border-[#d1d5db] text-[#999999] rounded-md`}
          // defaultvalue={defaultValue.name}
          value={searchParam}
          onChange={onSearchParam}
          onFocus={() => {
            setIsOpen(true);
          }}
        />
        <ChevronDown
          className={`text-[##758081] absolute ${dir('right-0 mr-4', 'left-0 ml-4')}  bottom-0 mb-4 w-6 h-6`}
        />

        {/* <p className="pt-1 text-red-500">{errors[name]}</p> */}
        <div
          className={` absolute bottom-0 text-sm -mt-2 overflow-hidden left-0 translate-y-full z-10  ${
            isOpen ? 'block' : 'hidden'
          }   pl-0 w-full bg-white transform  `}
        >
          <ul
            style={{
              direction: dir('rtl', 'ltr'),
            }}
            className=" max-h-64 border  divide-y overflow-y-scroll"
          >
            {list
              .filter((e) => e?.frmQoptionLngs[0].title?.toLowerCase().includes(searchParam?.toLowerCase()))
              .map((e) => {
                return (
                  <button
                    key={e.id}
                    onClick={(x) => {
                      onClick(e);
                    }}
                    className={`block p-2 w-full hover:bg-gray-50 ${dir('text-left', 'text-right')} `}
                    type="button"
                  >
                    {e.frmQoptionLngs[0].title}
                  </button>
                );
              })}
          </ul>
        </div>
      </OutsideClickHandler>
    </label>
  );
}
