import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { dir } from '../../utils/localisation';
import ChevronRight from '../Svg/ChevronRight';

export default function CustomMain(props) {
  const { routes, title, icon, sidebarToggle } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const JSONToken = JSON.parse(token);
  const [userToggle, setUserToggle] = useState(false);
  const userName = JSONToken.name;
  return (
    <div className={`${isOpen ? 'bg-[#354e96]' : ''} `}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? 'bg-[#354e96]' : ''
        } flex justify-between items-center  w-full duration-200 mt-4 py-2 px-6  hover:bg-[#354e96]  hover:text-[#fdce00]`}
      >
        <div className={` flex items-center`}>
          <img alt="x" src={icon} />
          {sidebarToggle && (
            <>
              <span className="mx-4">
                <div className="flex items-center space-x-2 space-x-reverse">
                  <b>{t(title)}</b>
                </div>
              </span>
            </>
          )}
        </div>

        <ChevronRight className={`w-5 h-5 transform ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
      </button>
      {isOpen &&
        routes?.map((route, index) => {
          return (
            <NavLink
              key={route.id}
              to={route.to}
              activeClassName="bg-[#354e96]  text-white border-white"
              activeStyle={{
                borderColor: '#fff',
              }}
              className={`flex justify-between bg-[#354e96] items-center duration-200 text-sm font-regular py-2 pl-2  border-gray-800  hover:text-[#fdce00] `}
            >
              <span className="mx-4">
                <div className={`flex  space-x-4 items-center ${dir('space-x-reverse', 'space-x-reverse')}`}>
                  <div className="w-1 h-1 bg-[#bababa] rounded-full "></div>
                  <div className="font-bold">
                    <span>{t(route.name)}</span>
                  </div>
                </div>
                {route.subName && (
                  <span className="mt-2 block  bg-[#1a3175] mr-4 px-1 py-1 w-full ">{t(route.subName)}</span>
                )}
              </span>
            </NavLink>
          );
        })}
    </div>
  );
}
