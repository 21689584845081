import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';
import { ChangeRequestStatus, getLanguages } from '../../Requests/ApRequests';
import { useHistory, useParams } from 'react-router';
import { dir } from '../../../utils/localisation';
import axios from 'axios';
// import { sendMessage } from '../../utils/sendMessage';

export default function AddComplaintDepartment(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, submitionUserId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-green-500 space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-green-600 shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        {' '}
        <span>اضافة قسم</span>
      </button>

      {isOpen && (
        <ModalContainer
          submitionUserId={submitionUserId}
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, submitionUserId } = props;
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const userData = localStorage.getItem('token');
  const userId = JSON.parse(userData).userId;

  const initialValues = {
    name: 'string',
  };
  const history = useHistory();
  const queryClient = useQueryClient();

  // Mutate Data to Create New Page
  const mutation = useMutation(addComplaintDepartment, {
    onSuccess: async (data, variables) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['complaintDepartments']);
        setIsOpen(false);
      }
    },
  });

  const onSubmit = (e) => {
    try {
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // onSubmit={onSubmit}
                title={`اضف قسم للشكوى`}
                isLoading={mutation.isLoading}
                add={true}
              >
                {/* Submit Form */}

                <label className={`flex  flex-col ${dir('justify-start', 'justify-end')}  space-y-2`}>
                  <span className={`font-bold text-base ${dir('text-right', 'text-right')}`}>الاسم</span>
                  <Field name="name" className="border rounded py-2 px-3" as="textarea" />
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const addComplaintDepartment = async (e) => {
  console.log('e', e);
  const { data } = await axios.post(`/ComplainDepartment/Add`, e);
  return data;
};
