// /api/Submittion/GetCountByFormIdChart

import axios from 'axios';
import { DateTime } from 'luxon';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';

export default function CountByFormIdChart(props) {
  const { formId, name, icon } = props;
  const initialQuery = {
    formId: formId,
    fromDate: DateTime.now().startOf('month').toISODate(),
    toDate: DateTime.now().endOf('month').toISODate(),
  };
  const fetchCountByFormIdChart = async (e) => {
    const { data } = await axios.post('Submittion/GetCountByFormId', e.queryKey[1]);
    return data;
  };

  const { data, isLoading, isError } = useQuery(
    [`CountByFormIdChart-${formId}`, initialQuery],
    fetchCountByFormIdChart
  );

  if (isLoading)
    return (
      <div className="bg-white h-32 text-center flex justify-center items-center relative rounded-md shadow border p-4">
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  if (isError) return '';
  const info = data.data;

  const newData = {
    labels: [`${name}`],
    redraw: true,
    datasets: [
      {
        label: 'قيد المراجعة',
        data: [info.statusPending],
        backgroundColor: ['rgba(245, 158, 11, 1)'],
        borderColor: ['rgba(245, 158, 11, 1)'],
        borderWidth: 1,
      },
      {
        label: 'مقبول',
        data: [info.statusAccepted],
        backgroundColor: ['rgba(16, 185, 129, 1)'],
        borderColor: ['rgba(16, 185, 129, 1)'],
        borderWidth: 1,
      },
      {
        label: 'مرفوض',
        data: [info.statusRejected],
        backgroundColor: ['rgba(239, 68, 68, 1)'],
        borderColor: ['rgba(239, 68, 68, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            label: false,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="bg-white rounded-md shadow border p-4">
        <div className="flex  items-center justify-between">
          <div className="flex space-x-2 items-center space-x-reverse">
            {icon}
            <span>{name}</span>
          </div>
          <div>
            <span>{info.totalCount}</span>
          </div>
        </div>
        <hr className="block my-4" />
        <Bar data={newData} options={options} />
      </div>
    </>
  );
}
