import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import OutsideClickHandler from '../../../SharedComponents/OutsideClickHandler';
import ChevronDown from '../../../SharedComponents/Svg/ChevronDown';
import { dir } from '../../../utils/localisation';
import FormError from '../FormError';

export default function FormDropDown(props) {
  const {
    title,
    placeholder,
    errors,
    values,
    loading,
    error,
    list,
    FieldValue,
    setFieldValue,
    FieldName,
    disabled,
    languageId,
  } = props;
  let defaultValue = list?.find((e) => e.id === FieldValue);
  const { questionType, purposeOfRequest } = useParams();
  const [value, setValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState(
    defaultValue ? defaultValue?.frmQoptionLngs.filter((item) => item.languageId === languageId)[0].title : ''
  );
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const { t, i18n } = useTranslation();
  const onClick = (data) => {
    setFieldValue(FieldName, data.id);
    setSearchParam(data.frmQoptionLngs.filter((item) => item.languageId === languageId)[0].title);
    setIsOpen(false);
  };
  useEffect(() => {
    if (FieldValue?.length === 0) {
      setSearchParam(FieldValue);
    }
    if (FieldName === 'visitors') {
      setFieldValue(FieldName, questionType);
    }
    if (FieldName === 'purposeOfRequest') {
      console.log('ssssss');
      console.log(FieldName, purposeOfRequest);
      setFieldValue(FieldName, purposeOfRequest);
    }
  }, [FieldValue]);

  return (
    <label
      className={`relative pt-4 w-full ${FieldName === 'visitors' || FieldName === 'purposeOfRequest' ? 'hidden' : ''}`}
    >
      <OutsideClickHandler setIsOpen={setIsOpen}>
        <span className={`flex  flex-col  justify-start space-y-2 ${dir('', 'text-right')}`}>{t(title)} *</span>
        <input
          disabled={disabled}
          autoComplete="off"
          name="groupId"
          placeholder={t(placeholder)}
          type="text"
          className={`w-full border rounded py-2 px-3`}
          value={searchParam}
          onChange={onSearchParam}
          onFocus={() => {
            setIsOpen(true);
          }}
        />
        <FormError name={FieldName} errors={errors} />

        <ChevronDown
          className={`text-[##758081] absolute ${dir('right-0 mr-4', 'left-0 ml-4')}  top-0 mt-12  w-6 h-6`}
        />

        {/* <p className="pt-1 text-red-500">{errors[name]}</p> */}
        <div
          className={` absolute bottom-0 text-sm  overflow-hidden left-0  mt-2 translate-y-full z-10  ${
            isOpen ? 'block' : 'hidden'
          }   pl-0 w-full bg-white transform  `}
        >
          <ul
            style={{
              direction: dir('rtl', 'ltr'),
            }}
            className=" max-h-64 border   divide-y overflow-y-scroll"
          >
            {list
              .filter((e) =>
                e?.frmQoptionLngs
                  .filter((item) => item.languageId === languageId)[0]
                  .title?.toLowerCase()
                  .includes(searchParam?.toLowerCase())
              )
              .map((e) => {
                return (
                  <button
                    key={e.id}
                    onClick={(x) => {
                      onClick(e);
                    }}
                    className={`block p-2 w-full hover:bg-gray-50 ${dir('text-left', 'text-right')} `}
                    type="button"
                  >
                    {e.frmQoptionLngs.filter((item) => item.languageId === languageId)[0].title}
                  </button>
                );
              })}
          </ul>
        </div>
      </OutsideClickHandler>
    </label>
  );
}
