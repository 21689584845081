export const complaintStatus = [
  {
    id: 0,
    name: 'قيد المراجعة',
  },
  {
    id: 1,
    name: 'تم حل المشكلة',
  },
  {
    id: 2,
    name: 'مغلقة',
  },
];

// {complaintStatus.filter((e) => e.id === data?.data?.complaintStatus)[0].name}
