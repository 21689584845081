// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'AccountMangment.Register.policy': 'إدارة الحساب سياسه تسجيل الدخول',
  'AccountMangment.CreateRole.policy': 'إدارة الحساب سياسه إنشاء قواعد',
  'AccountMangment.DeleteRole.policy': 'إدارة الحساب سياسه حذف قواعد',
  'Language.Add.policy': 'اللغه سياسه الإضافه',
  'Language.Edit.policy': 'اللغه سياسه التعديل',
  'Language.Delete.policy': 'اللغه سياسه الحذف',
  'UserAccount.AddUser.policy': 'حساب المستخدم سياسه اضافه مستخدم',
  'UserAccount.EditUser.policy': 'حساب المستخدم سياسه تعديل مستخدم',
  'UserAccount.EditStatus.policy': 'حساب المستخدم سياسه تعديل حاله المستخدم',
  'UserAccount.EditRole.policy': 'حساب المستخدم سياسه تعديل قاعده المستخدم',
  'UserAccount.GetUser.policy': 'حساب المستخدم سياسه عرض المستخدم',
  'UserAccount.GetNotMappedProviders.policy': 'حساب المستخدم سياسه الحصول على مقدمي الخدمات غير المعينين',
  'UserAccount.CurrentUser.policy': 'حساب المستخدم سياسه المستخدم الحالي',
  'UserAccount.GetUserByRole.policy': 'حساب المستخدم سياسه عرض المستخدم حسب وظيفته',
  'UserAccount.ChangePassword': 'حساب المستخدم سياسه تغير كلمه السر',
  'UserAccount.SearchInUsers.policy': ' "الشهادة السلبية",',
  'UserAccount.GetUserStatus.policy': '    "UserAccount.GetUserStatus.policy",',
  'UserAccount.GetAllUserStatus.policy': '    "UserAccount.GetAllUserStatus.policy",',
  'UserAccount.AddUserStatus.policy': '    "UserAccount.AddUserStatus.policy",',
  'UserAccount.EditUserStatus.policy': '    "UserAccount.EditUserStatus.policy",',
  'UserAccount.DeleteUserStatus.policy': '    "UserAccount.DeleteUserStatus.policy",',
  'UserRole.List': 'قواعد المستخدم القائمه',
  'UserRole.Add': 'قواعد المستخدم اضافه',
  'UserRole.Edit': 'قواعد المستخدم التعديل',
  'UserRole.Delete': 'قواعد المستخدم حذف',
  'RolePerm.List': 'قواعد خاصه القائمه',
  'RolePerm.GetForUser': 'قواعد خاصه عرض من اجل المستخدم',
  'RolePerm.SetForUser': 'قواعد خاصه اضافه من اجل المستخدم',
  'RolePerm.GetForRole': 'قواعد خاصه عرض من اجل القاعده',
  'RolePerm.SetForRole': 'قواعد خاصه اضافه من اجل القاعده',
  'MuMenuItem.Add.policy': 'عناصر القائمه سياسه الإضافه',
  'MuMenuItem.Edit.policy': 'عناصر القائمه سياسه التعديل',
  'MuMenuItem.Delete.policy': 'عناصر القائمه سياسه الحذف',
  'MuMenuType.Add.policy': 'انواع القائمه سياسه الإضافه',
  'MuMenuType.Edit.policy': 'انواع القائمه سياسه التعديل',
  'MuMenuType.Delete.policy': 'انواع القائمه سياسه الحذف',
  'PgComment.Add.policy': 'صفحه التعليقات سياسه الإضافه',
  'PgComment.Edit.policy': 'صفحه التعليقات سياسه التعديل',
  'PgComment.Delete.policy': 'صفحه التعليقات سياسه الحذف',
  'PgEvent.Add.policy': 'صفحه الاحداث سياسه القائمه',
  'PgEvent.Edit.policy': 'صفحه الاحداث سياسه التعديل',
  'PgEvent.Delete.policy': 'صفحه الاحداث سياسه الحذف',
  'PgKpi.Add.policy': 'صفحه التقيم سياسه الإضافه',
  'PgKpi.Edit.policy': 'صفحه التقيم سياسه التعديل',
  'PgKpi.Delete.policy': 'صفحه التقيم سياسه الحذف',
  'PgPage.Add.policy': 'الصفح سياسه الإضافه',
  'PgPage.Edit.policy': 'الصفح سياسه التعديل',
  'PgPage.Delete.policy': 'الصفح سياسه الحذف',
  'PgPageRatingCountView.Add.policy': 'عرض عدد تقيم الصفح سياسه الإضافه',
  'PgPageRatingCountView.Edit.policy': 'عرض عدد تقيم الصفح سياسه التعديل',
  'PgPageRatingCountView.Delete.policy': 'عرض عدد تقيم الصفح سياسه الحذف',
  'PgPageRatingView.Add.policy': 'تقيم الصفح سياسه الإضافه',
  'PgPageRatingView.Edit.policy': 'تقيم الصفح سياسه التعديل',
  'PgPageRatingView.Delete.policy': 'تقيم الصفح سياسه الحذف',
  'PgPageType.Add.policy': 'انواع الصفح سياسه الإضافه',
  'PgPageType.Edit.policy': 'انواع الصفح سياسه التعديل',
  'PgPageType.Delete.policy': 'انواع الصفح سياسه الحذف',
  'PgRatingResult.Add.policy': 'صفحه التقيمات سياسه الإضافه',
  'PgRatingResult.Edit.policy': 'صفحه التقيمات سياسه التعديل',
  'PgRatingResult.Delete.policy': 'صفحه التقيمات سياسه الحذف',
  'PgSection.Add.policy': 'صفحه الاقسام سياسه الإضافه',
  'PgSection.Edit.policy': 'صفحه الاقسام سياسه التعديل',
  'PgSection.Delete.policy': 'صفحه الاقسام سياسه الحذف',
  'AstAsset.Add.policy': 'الملفات سياسه الإضافه',
  'AstAsset.Edit.policy': 'الملفات سياسه التعديل',
  'AstAsset.Delete.policy': 'الملفات سياسه الحذف',
  'AstAssetType.Add.policy': 'انواع الملفات سياسه الإضافه',
  'AstAssetType.Edit.policy': 'انواع الملفات سياسه التعديل',
  'AstAssetType.Delete.policy': 'انواع الملفات سياسه الحذف',
  'AstFolder.Add.policy': 'مجلد الملفات سياسه الإضافه',
  'AstFolder.Edit.policy': 'مجلد الملفات سياسه التعديل',
  'AstFolder.Delete.policy': 'مجلد الملفات سياسه الحذف',
  'GlAlbum.Add.policy': 'البومات الصور سياسه الإضافه',
  'GlAlbum.Edit.policy': 'البومات الصور سياسه التعديل',
  'GlAlbum.Delete.policy': 'البومات الصور سياسه الحذف',
  'GlAlbumItem.Add.policy': 'عناصر البومات الصور سياسه الإضافه',
  'GlAlbumItem.Edit.policy': 'عناصر البومات الصور سياسه التعديل',
  'GlAlbumItem.Delete.policy': 'عناصر البومات الصور سياسه الحذف',
  'GlType.Add.policy': 'انواع الالبومات سياسه الإضافه',
  'GlType.Edit.policy': 'انواع الالبومات سياسه التعديل',
  'GlType.Delete.policy': 'انواع الالبومات سياسه الحذف',
  'FrmAnswer.Add.policy': 'اجابه الإستمارة سياسه الإضافه',
  'FrmAnswer.Edit.policy': 'اجابه الإستمارة سياسه التعديل',
  'FrmAnswer.Delete.policy': 'اجابه الإستمارة سياسه الحذف',
  'FrmEmail.Add.policy': 'الاميل استماره سياسه الإضافه',
};
