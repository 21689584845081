// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Visitors: 'الزائرين',
  Registeration: 'العائدين',
  Resources: 'الموارد',
  Reports: 'التقارير',
  Calender: 'النتيجة',
  'New Visitors': 'زائرون جدد',
  'Review Request': 'مراجعة الطلب',
  'Users Role': 'صلاحيات المستخدمين',
  Governorates: 'المحافظات',
  Areas: 'المناطق',
  Languages: 'اللغات',
  'Occupation Category': 'تصنيف المهنة',
  'Employment Status': 'حالة الوظيفة',
  'Levels Education': 'مستويات التعليم',
  Actions: 'Actions',
  'Martial Status': 'الحالة الاجتماعية',
  'Registation Method': 'طريقة التسجيل',
  'Visit Reasons': 'أسباب الزيارة',
};
