import React from 'react';
export const getAllPermissions = [
  'AccountMangment.Register.policy',
  'AccountMangment.CreateRole.policy',
  'AccountMangment.DeleteRole.policy',
  'Language.Add.policy',
  'Language.Edit.policy',
  'Language.Delete.policy',
  'UserAccount.AddUser.policy',
  'UserAccount.EditUser.policy',
  'UserAccount.EditStatus.policy',
  'UserAccount.EditRole.policy',
  'UserAccount.GetUser.policy',
  'UserAccount.GetNotMappedProviders.policy',
  'UserAccount.CurrentUser.policy',
  'UserAccount.GetUserByRole.policy',
  'UserAccount.ChangePassword.policy',
  'UserAccount.SearchInUsers.policy',
  'UserAccount.GetUserStatus.policy',
  'UserAccount.GetAllUserStatus.policy',
  'UserAccount.AddUserStatus.policy',
  'UserAccount.EditUserStatus.policy',
  'UserAccount.DeleteUserStatus.policy',
  'UserRole.List',
  'UserRole.Add',
  'UserRole.Edit',
  'UserRole.Delete',
  'RolePerm.List',
  'RolePerm.GetForUser',
  'RolePerm.SetForUser',
  'RolePerm.GetForRole',
  'RolePerm.SetForRole',
  'Notifications.Add.policy',
  'Notifications.Edit.policy',
  'Notifications.Delete.policy',
  'Email.Add.policy',
  'Email.Edit.policy',
  'Email.Delete.policy',
  'MuMenuItem.Add.policy',
  'MuMenuItem.Edit.policy',
  'MuMenuItem.Delete.policy',
  'MuMenuType.Add.policy',
  'MuMenuType.Edit.policy',
  'MuMenuType.Delete.policy',
  'PgComment.Add.policy',
  'PgComment.Edit.policy',
  'PgComment.Delete.policy',
  'PgEvent.Add.policy',
  'PgEvent.Edit.policy',
  'PgEvent.Delete.policy',
  'PgKpi.Add.policy',
  'PgKpi.Edit.policy',
  'PgKpi.Delete.policy',
  'PgPage.Add.policy',
  'PgPage.Edit.policy',
  'PgPage.Delete.policy',
  'PgPageRatingCountView.Add.policy',
  'PgPageRatingCountView.Edit.policy',
  'PgPageRatingCountView.Delete.policy',
  'PgPageRatingView.Add.policy',
  'PgPageRatingView.Edit.policy',
  'PgPageRatingView.Delete.policy',
  'PgPageType.Add.policy',
  'PgPageType.Edit.policy',
  'PgPageType.Delete.policy',
  'PgRatingResult.Add.policy',
  'PgRatingResult.Edit.policy',
  'PgRatingResult.Delete.policy',
  'PgSection.Add.policy',
  'PgSection.Edit.policy',
  'PgSection.Delete.policy',
  'Setting.Add.policy',
  'Setting.Edit.policy',
  'Setting.Delete.policy',
  'AstAsset.Add.policy',
  'AstAsset.Edit.policy',
  'AstAsset.Delete.policy',
  'AstAssetType.Add.policy',
  'AstAssetType.Edit.policy',
  'AstAssetType.Delete.policy',
  'AstFolder.Add.policy',
  'AstFolder.Edit.policy',
  'AstFolder.Delete.policy',
  'GlAlbum.Add.policy',
  'GlAlbum.Edit.policy',
  'GlAlbum.Delete.policy',
  'GlAlbumItem.Add.policy',
  'GlAlbumItem.Edit.policy',
  'GlAlbumItem.Delete.policy',
  'GlType.Add.policy',
  'GlType.Edit.policy',
  'GlType.Delete.policy',
  'ComplaintFollowUp.Add.policy',
  'ComplaintFollowUp.Edit.policy',
  'ComplaintFollowUp.Delete.policy',
  'Agenda.Add.policy',
  'Agenda.Get.policy',
  'Agenda.Edit.policy',
  'Agenda.Delete.policy',
  'AgendaTime.Get.policy',
  'AgendaTime.Add.policy',
  'AgendaTime.Edit.policy',
  'AgendaTime.Delete.policy',
  'Complain.Add.policy',
  'Complain.Edit.policy',
  'Complain.Delete.policy',
  'ComplainDepartment.Get.policy',
  'ComplainDepartment.Add.policy',
  'ComplainDepartment.Edit.policy',
  'ComplainDepartment.Delete.policy',
  'ComplainDepartment.GetDepartmentByRole.policy',
  'ComplainReply.Add.policy',
  'ComplainReply.Edit.policy',
  'ComplainReply.Delete.policy',
  'Consulting.Add.policy',
  'Consulting.Edit.policy',
  'Consulting.Delete.policy',
  'ConsultingReply.Add.policy',
  'ConsultingReply.Edit.policy',
  'ConsultingReply.Delete.policy',
  'FrmAnswer.Add.policy',
  'FrmAnswer.Edit.policy',
  'FrmAnswer.Delete.policy',
  'FrmEmail.Add.policy',
  'FrmEmail.Edit.policy',
  'FrmEmail.Delete.policy',
  'FrmForm.Add.policy',
  'FrmForm.Edit.policy',
  'FrmForm.Delete.policy',
  'FrmQoption.Add.policy',
  'FrmQoption.Edit.policy',
  'FrmQoption.Delete.policy',
  'FrmQoptionType.Add.policy',
  'FrmQoptionType.Edit.policy',
  'FrmQoptionType.Delete.policy',
  'FrmQuestion.Add.policy',
  'FrmQuestion.Edit.policy',
  'FrmQuestion.Delete.policy',
  'FrmQuestionType.Add.policy',
  'FrmQuestionType.Edit.policy',
  'FrmQuestionType.Delete.policy',
  'FrmType.Add.policy',
  'FrmType.Edit.policy',
  'FrmType.Delete.policy',
  'InterViewEmptyTimes.Add.policy',
  'InterViewEmptyTimes.Edit.policy',
  'InterViewEmptyTimes.Delete.policy',
  'Submittion.Add.policy',
  'Submittion.Edit.policy',
  'Submittion.Delete.policy',
  'Suggestions.Add.policy',
  'Suggestions.Edit.policy',
  'Suggestions.Delete.policy',
  'FAQ.Add.policy',
  'FAQ.Edit.policy',
  'FAQ.Delete.policy',
  'NewsLetter.Add.policy',
  'NewsLetter.Edit.policy',
  'NewsLetter.Delete.policy',
  'Poll.Get.policy',
  'Poll.GetAll.policy',
  'Poll.Add.policy',
  'Poll.Edit.policy',
  'Poll.Delete.policy',
  'PollQuestion.Get.policy',
  'PollQuestion.GetAll.policy',
  'PollQuestion.Add.policy',
  'PollQuestion.Edit.policy',
  'PollQuestion.Delete.policy',
  'Certifications.Add.policy',
  'Certifications.Edit.policy',
  'Certifications.Delete.policy',
  'Instractors.Add.policy',
  'Instractors.Edit.policy',
  'Instractors.Delete.policy',
  'Session.Add.policy',
  'Session.Edit.policy',
  'Session.Delete.policy',
  'SessionInstractors.Add.policy',
  'SessionInstractors.Edit.policy',
  'SessionInstractors.Delete.policy',
  // custom permissions
  'cms.managment',
  'tasheed.userStatuses.nav',
  'tasheed.complaintDepartments.nav',
  'tasheed.complaints.nav',
  'tasheed.information.nav',
  'tasheed.agenda.nav',
  'tasheed.consultation.nav',
  'tasheed.suggestions.nav',
  'tasheed.joinRequests.nav',
  'tasheed.specialRequests.nav',
  'tasheed.statistics.nav',
];
