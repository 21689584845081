import axios from 'axios';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import AddComplaintReply from './components/AddComplaintReply';
import ComplaintReplies from './components/ComplaintReplies';
import { complaintStatus } from '../../utils/enums';
import toast from 'react-hot-toast';
import Notification from '../../SharedComponents/Notification';
import ChangeComplaintDepartment from './components/ChangeComplaintDepartment';
export default function SingleComplaintNew(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const fetchsingleComplaint = async (key) => {
    const { data } = await axios.get(`/Complain/Get?id=${id}&&langId=1`);
    return data;
  };

  const { data, isLoading, isError } = useQuery([`singleComplaint-${id}`], fetchsingleComplaint);

  if (isLoading) return '';
  if (isError) return '';
  const parsedArray = JSON.parse(data?.data?.file);

  return (
    <>
      <section dir="rtl">
        <div className="container mx-auto px-4  ">
          <header className="flex justify-between items-center mb-8">
            <BreadCrumbs to={`/complaints`} name={t('complaints')} />
            <div className="flex items-center space-x-2 space-x-reverse">
              {data?.data?.complaintStatus === 0 && (
                <>
                  <SolvedComplaint />
                  <CloseComplaint />
                </>
              )}
            </div>
            {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
          </header>
          <div className="flex items-center space-x-2 space-x-reverse my-6 justify-between">
            <h2 className="text-2xl font-bold">بيانات الشكوى</h2>
            <div>
              <ChangeComplaintDepartment complaint={data.data} />
            </div>
          </div>
          <div>
            <div className="border-[#f2f2f2]  h-[645px] grid grid-cols-1 border lg:grid-cols-6 bg-white">
              <div className="border-[#f2f2f2]  border-l overflow-x-hidden overflow-y-auto lg:col-span-2">
                <div className="px-5 py-5 text-white bg-[#a39161]">
                  <div className="mb-2">
                    <h3 className="text-sm font-regular">رقم الشكوي</h3>
                    <h2 className="text-xl font-bold">{data?.data?.id}</h2>
                  </div>
                  <div className="flex items-center justify-between">
                    <h3 className="text-sm font-regular">
                      {complaintStatus.filter((e) => e.id === data?.data?.complaintStatus)[0].name}
                    </h3>
                    <button className="flex items-center text-sm space-x-2 space-x-reverse ">
                      <svg
                        className="w-4 h-4 text-white "
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        ></path>
                      </svg>
                      <span>تحديث</span>
                    </button>
                  </div>
                </div>
                <div className="my-3 px-6">
                  <div className="relative pb-6">
                    <div className="w-3 h-3 bg-[#202b3d] block rounded-full absolute right-0  top-0 mt-1 z-20"></div>
                    <div className="h-full w-[1px] bg-[#202b3d] absolute top-0 mt-2 z-10  right-0  mr-[6px] "></div>
                    <div className="flex items-center   pr-5">
                      <h3 className="text-[#666666] text-sm font-regular">تاريخ تقديم الشكوي</h3>
                    </div>
                    <h4 className="mt-2  text-sm font-bold pr-5">
                      {DateTime.fromISO(data?.data?.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
                    </h4>
                  </div>
                  <div className="relative pb-6">
                    <div className="w-3 h-3 bg-[#202b3d] block rounded-full absolute right-0  top-0 mt-1 z-20"></div>
                    <div className="h-full w-[1px] bg-[#202b3d] absolute top-0 mt-2 z-10  right-0  mr-[6px] "></div>
                    <div className="flex  items-center  pr-5">
                      <h3 className="text-[#666666] text-sm font-regular">عنوان الشكوى</h3>
                    </div>
                    <h4 className="mt-2 pr-5 text-sm font-regular">{data?.data?.title}</h4>
                  </div>
                  <div className="relative pb-6">
                    <div className="w-3 h-3 bg-[#202b3d] block rounded-full absolute right-0  top-0 mt-1 z-20"></div>
                    <div className="h-full w-[1px] bg-[#202b3d] absolute top-0 mt-2 z-10  right-0  mr-[6px] "></div>

                    <div className="flex  items-center  pr-5">
                      <h3 className="text-[#666666] text-sm font-regular">موضوع الشكوى</h3>
                    </div>
                    <h4 className="mt-2 pr-5 text-sm font-regular">{data?.data?.subject}</h4>
                  </div>
                  <div className="relative pb-6">
                    <div className="w-3 h-3 bg-[#202b3d] block rounded-full absolute right-0  top-0 mt-1 z-20"></div>
                    <div className="h-full w-[1px] bg-[#202b3d] absolute top-0 mt-2 z-10  right-0  mr-[6px] "></div>
                    <div className="flex items-center   pr-5">
                      <h3 className="text-[#666666] text-sm font-regular">قسم الشكوي</h3>
                    </div>
                    <h4 className="mt-2  text-sm font-bold pr-5">{data.data.complainDepartment.name}</h4>
                  </div>
                  <div className="relative pb-6">
                    <div className="w-3 h-3 bg-[#202b3d] block rounded-full absolute right-0  top-0 mt-1 z-20"></div>
                    <div className="flex  items-center  pr-5">
                      <h3 className="text-[#666666] text-sm font-regular">مرفقات الشكوى</h3>
                    </div>
                    <div className="flex space-x-2 space-x-reverse mt-8">
                      {parsedArray?.map((r, index) => {
                        return (
                          <>
                            <div className="flex">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="bg-green-500 hover:bg-green-600 font-normal  text-xs text-white py-2 px-4 rounded  flex space-x-2 space-x-reverse items-center"
                                href={`${window.env.ImagesAPI}${r.cdnPath}`}
                              >
                                <span>ملف - {index + 1}</span>
                                {/* <CloudDownloadIcon className="h-4" /> */}
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:col-span-4">
                <div className="pr-5 py-5 w-full bg-white">
                  <div className="flex items-center space-x-3 space-x-reverse">
                    <div className="w-12 h-12 rounded-full overflow-hidden">
                      <img
                        alt="image"
                        src="https://rate2020cdn.key-frame.cf/images/622c222d-2c52-42ff-80c2-b7dc4671bf12.png"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h2 className="text-xl font-bold">فريق الدعم الفنى</h2>
                  </div>
                </div>
                <ComplaintReplies />
                {data?.data?.complaintStatus === 0 && (
                  <div>
                    <AddComplaintReply />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const SolvedComplaint = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const changeComplaintStatusFunction = async () => {
    const { data } = await axios.post(`Complain/ChangeComplainStatus`, {
      id: parseInt(id),
      complaintStatus: 1,
    });

    return data;
  };
  const mutation = useMutation(changeComplaintStatusFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['singleComplaint']);
      toast.custom(<Notification message={`تم تغيير حالة الشكوى`} />);
    },
  });

  return (
    <button
      onClick={() => mutation.mutate()}
      disabled={mutation.isLoading}
      className={` ${
        mutation.isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-500'
      } flex items-center justify-center mb-1  px-4 py-2 text-base  text-center text-white hover:bg-gray-500  font-semibold  `}
      type="submit"
    >
      <span>تم الحل</span>
      {mutation.isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
    </button>
  );
};

const CloseComplaint = (props) => {
  const { id } = useParams();

  const changeComplaintStatusFunction = async () => {
    const { data } = await axios.post(`Complain/ChangeComplainStatus`, {
      id: parseInt(id),
      complaintStatus: 2,
    });

    return data;
  };
  const mutation = useMutation(changeComplaintStatusFunction);

  return (
    <button
      onClick={() => mutation.mutate()}
      disabled={mutation.isLoading}
      className={` ${
        mutation.isLoading ? 'bg-gray-500 cursor-not-allowed' : 'bg-red-500'
      } flex items-center justify-center mb-1  px-4 py-2 text-base  text-center text-white hover:bg-gray-500  font-semibold  `}
      type="submit"
    >
      <span>اغلاق</span>
      {mutation.isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
    </button>
  );
};
