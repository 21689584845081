import axios from 'axios';

export const getComplaintBySubmitId = async (e) => {
  console.log('e', e);
  // const { id } = e.queryKey[1];
  const { data } = await axios.post(`/ComplaintFollowUp/GetComplaintFollowUpBySubmitId`, e.queryKey[1]);
  return data;
};

export const AddComplaintFollowUp = async (body) => {
  const { data } = await axios.post('ComplaintFollowUp/AddComplaintFollowUp', body);
  return data;
};
export const AddComplaintStatus = async (body) => {
  const { data } = await axios.post('ComplaintFollowUp/AddComplaintStatus', body);
  return data;
};
