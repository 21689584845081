import { useEffect, useState } from 'react';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import card from '../../../assets/img/card.svg';
import tabs from '../../../assets/img/tabs.svg';
import collapse from '../../../assets/img/collapse.svg';
import banner from '../../../assets/img/banner.svg';
import slider from '../../../assets/img/slider.svg';
import statisticsIcon from '../../../assets/img/statisticsIcon.svg';

import list from '../../../assets/img/list.svg';
import { Field, FieldArray, Form, Formik } from 'formik';
import { addSection, getLanguages } from './ApiSections';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../SharedComponents/Svg/LoadingButton';
import LanguageList from '../../../SharedComponents/LanguageList';

export default function AddSection(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { orderNumber } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      {isOpen && <Section orderNumber={orderNumber} setIsOpen={setIsOpen} />}

      {isOpen === false && (
        <button
          onClick={() => {
            setIsOpen(true);
          }}
          type="button"
          className={`bg-darkblue space-x-1 ${
            i18n.language === 'en' ? '' : 'space-x-reverse'
          } text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
        >
          <PlusCircle className="w-5 h-5" /> <span>{t('Add Section')}</span>
        </button>
      )}
    </>
  );
}

const Section = (props) => {
  const { setIsOpen, orderNumber } = props;
  const [typeId, setTypeId] = useState(0);
  const params = useParams();
  const pageId = params.id;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);
  const sectionType = [
    {
      icon: card,
      name: 'Card',
      typeId: 0,
    },
    {
      icon: tabs,
      name: 'tabs',
      typeId: 1,
    },
    {
      icon: collapse,
      name: 'collapse',
      typeId: 2,
    },
    {
      icon: slider,
      name: 'Slider',
      typeId: 4,
    },
    {
      icon: statisticsIcon,
      name: 'Statistics',
      typeId: 5,
    },
    {
      icon: list,
      name: 'List',
      typeId: 6,
    },
    {
      icon: list,
      name: 'Modal',
      typeId: 7,
    },
    {
      icon: banner,
      name: 'Form',
      typeId: 8,
    },
    {
      icon: banner,
      name: 'Gallery Album',
      typeId: 9,
    },
  ];

  // Handle Multiple Language Content in the Page
  const pgSectionLngs = languages?.data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
  }));
  const initialValues = {
    isActive: true,
    orderNum: orderNumber,
    sectionTypeId: typeId,
    pgPageId: parseInt(pageId),
    pgSectionLngs,
    sectionStyle: 'default',
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(addSection, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    const newData = { ...e, sectionTypeId: typeId };
    try {
      await mutation.mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue, setValues }) => {
          return (
            <Form>
              <div className="block w-full shadow border">
                <div className="p-4">
                  <div className="flex justify-between relative items-center">
                    <div className="flex transform -translate-y-1 relative z-10">
                      {values &&
                        values.pgSectionLngs &&
                        values?.pgSectionLngs.map((pgSectionLng, index) => {
                          return (
                            <>
                              {languageId === pgSectionLng.languageId && (
                                <>{pgSectionLng.title ? pgSectionLng.title : t('Untitled Section')}</>
                              )}
                            </>
                          );
                        })}
                    </div>
                    <div>
                      <div>
                        {languages && languages.data && (
                          <LanguageList
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                            languages={languages?.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                          />
                        )}
                      </div>
                    </div>
                    <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                  </div>
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-4 gap-6">
                    {sectionType.map((e) => {
                      return (
                        <div
                          onClick={() => {
                            setTypeId(e.typeId);
                          }}
                          className={`${
                            typeId === e.typeId ? 'bg-green-100 border-green-200 ' : 'bg-gray-100 border-gray-200 '
                          }  flex flex-col items-center p-8 border-2 rounded`}
                        >
                          <div className="flex justify-center h-24 items-center w-24  bg-white rounded-full">
                            <img className="inline-block filter grayscale h-10" src={e.icon} alt="Your avatar" />
                          </div>
                          <span className="text-center mt-4">{t(e.name)}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="grid grid-cols-4 gap-6 mt-4">
                    <div className="col-span-3">
                      {values &&
                        values.pgSectionLngs &&
                        values?.pgSectionLngs.map((pgSectionLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Add Section Title')}
                                        name={`pgSectionLngs[${index}].title`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                    </div>
                    <Field className="hidden" name="sectionStyle" />
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          onSubmit(values);
                        }}
                        className="bg-lightgreen flex justify-center text-center h-full text-sm font-bold text-white w-full items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                      >
                        {t('Save')}
                        {mutation.isLoading && <LoadingButton />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
