import axios from 'axios';
import { useQuery } from 'react-query';
import { NavLink, useParams } from 'react-router-dom';
import { BeakerIcon, CloudDownloadIcon } from '@heroicons/react/solid';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Eye from '../../SharedComponents/Svg/Eye';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import TablePagination from '../../SharedComponents/TablePagination';
import DynamicPrinting from '../../SharedComponents/DynamicPrinting';
import { PrintInformationList } from './PrintInformationList';

export default function InformationList() {
  const params = useParams();
  const componentRef = useRef(null);
  const [dateFrom, setDateFrom] = useState(DateTime.now().startOf('month').toISODate());
  const [dateTo, setDateTo] = useState(DateTime.now().endOf('month').toISODate());
  const [paginationCount, setPaginationCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  const queryInitialState = {
    pageNumber: pageNumber,
    pageSize: 10,
    isPaging: true,
    langId: 1,
    isFilter: true,

    filter: [
      {
        filterBy: 'datefromto',
        filterType: 'string',
        dateFilteration: {
          from: dateFrom,
          to: dateTo,
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery([`getInformationList`, queryInitialState], getInformationList, {
    onSuccess: (data) => {
      pagination(data);
    },
  });
  if (isLoading) return '';
  if (isError) return '';

  const dataColumns = [
    {
      Header: 'رقم الطلب',
      accessor: 'id',
    },
    {
      Header: 'الاسم',
      accessor: 'fullName',
      Cell: (cell) => {
        return <>{cell.value ? cell.value : cell.row.original.name}</>;
      },
    },
    {
      Header: 'الحالة',
      accessor: 'status.name',
      Cell: (cell) => {
        return <>{cell.value ? cell.value : 'غير مسجل'}</>;
      },
    },
    {
      Header: 'تاريخ الطباعة',
      accessor: 'createDate',
      Cell: (cell) => {
        return <>{DateTime.fromISO(cell.value).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</>;
      },
    },
    // {
    //   Header: 'تاريخ الشكوى',
    //   accessor: 'createDate',
    //   Cell: (cell) => {
    //     return <>{DateTime.fromISO(cell.value).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</>;
    //   },
    // },

    {
      Header: 'اسم المستخدم',
      accessor: 'createdByName',
    },
  ];

  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('informationList')} />

          <div className={`flex items-center space-x-3 space-x-reverse`}>
            {/* <DownloadPdf languageId={languageId} /> */}
            {/* <DownloadExcel languageId={languageId} /> */}
            <PrintInformationList dateFrom={dateFrom} dateTo={dateTo} />
          </div>
        </header>
        <div ref={componentRef}>
          <div className="my-6 flex justify-between">
            <h2 className="text-2xl font-bold">{t('informationList')}</h2>
            <div className="bg-white px-4 py-2 flex items-center space-x-4 divide-x divide-x-reverse space-x-reverse rounded-md">
              <span>العدد</span>
              <span className="px-4">{data?.paginationResult?.dataCount}</span>
            </div>
            <div className="bg-white px-4 py-2 flex items-center space-x-4 divide-x divide-x-reverse space-x-reverse rounded-md">
              <div className="flex items-center space-x-2 space-x-reverse px-2">
                <span>من</span>
                <input
                  type="date"
                  value={dateFrom}
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                  }}
                />
              </div>
              <div className="flex items-center space-x-2 space-x-reverse px-2">
                <span>الى</span>
                <input
                  type="date"
                  value={dateTo}
                  onChange={(e) => {
                    console.log('e', e);
                    setDateTo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
          <TablePagination
            data={data}
            paginationCount={paginationCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </>
  );
}

export const getInformationList = async ({ queryKey }) => {
  const { data } = await axios.post(`/UserAccount/GetAllPrintingStatusAduit`, queryKey[1]);
  return data;
};
