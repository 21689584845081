import axios from 'axios';
import { useQuery } from 'react-query';
import { NavLink, useParams } from 'react-router-dom';
import { BeakerIcon, CloudDownloadIcon } from '@heroicons/react/solid';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Eye from '../../SharedComponents/Svg/Eye';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useState } from 'react';
import TablePagination from '../../SharedComponents/TablePagination';

export default function ComplaintsNew() {
  const params = useParams();
  const Done = () => {
    return (
      <div className="text-[#28a745] w-28 text-center text-xs border rounded inline-block border-[#28a745] px-4 py-1 ">
        تم قبول الشكوى
      </div>
    );
  };
  const Declined = () => {
    return (
      <div className="text-[#dd0000] w-28 text-center text-xs border rounded inline-block border-[#dd0000] px-4 py-1 ">
        تم رفض الشكوى
      </div>
    );
  };
  const UnderReview = () => {
    return (
      <div className="text-[#0067da] w-28 text-center text-xs border rounded inline-block border-[#0067da] px-4 py-1 ">
        قيد المراجعة
      </div>
    );
  };

  const [paginationCount, setPaginationCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  const status = [
    {
      status: 0,
      component: <UnderReview />,
    },
    {
      status: 1,

      component: <Done />,
    },
    {
      status: 2,

      component: <Declined />,
    },
  ];
  const queryInitialState = {
    pageNumber: pageNumber,
    pageSize: 10,
    isPaging: true,
    langId: 1,
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery([`getComplaints`, queryInitialState], getComplaints, {
    onSuccess: (data) => {
      pagination(data);
    },
  });
  if (isLoading) return '';
  if (isError) return '';

  const dataColumns = [
    {
      Header: 'رقم الطلب',
      accessor: 'id',
    },
    {
      Header: 'اسم الشكوى',
      accessor: 'title',
    },
    {
      Header: 'قسم الشكوى',
      accessor: 'complainDepartment.name',
    },
    {
      Header: 'تاريخ الشكوى',
      accessor: 'createDate',
      Cell: (cell) => {
        return <>{DateTime.fromISO(cell.value).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</>;
      },
    },
    {
      Header: 'حالة الشكوى',
      accessor: 'complaintStatus',
      Cell: (cell) => {
        return <>{status.filter((element) => element.status === cell.value)[0].component}</>;
      },
    },
    {
      Header: 'اسم المستخدم',
      accessor: 'createdByName',
    },
    {
      Header: 'خيارات',
      accessor: 'options',
      Cell: ({ cell }) => {
        return (
          <div className="flex space-x-2">
            <NavLink to={`/complaints/${cell.row.original.id}`} className="bg-green-500 text-white p-1 rounded">
              <Eye className="h-4" />
            </NavLink>
            {/* <DeleteSuggestion id={cell.row.original.id} /> */}
          </div>
        );
      },
      width: 0,
      maxWidth: 10,
      sortDescFirst: true,
    },
  ];
  console.log('data', data);

  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('complaints')} />

          {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('complaints')}</h2>
        </div>

        <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
        <TablePagination
          data={data}
          paginationCount={paginationCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
}

export const getComplaints = async ({ queryKey }) => {
  const { data } = await axios.post(`/Complain/GetAll`, queryKey[1]);
  return data;
};
