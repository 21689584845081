import axios from 'axios';
import { DateTime } from 'luxon';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

export default function GetAllRequestComments(props) {
  const params = useParams();
  const id = parseInt(params.id);
  const { data, isLoading, isError } = useQuery(
    [
      `signleTalabRquestComments-${id}`,
      {
        pageNumber: 0,
        pageSize: 0,
        numberOfPages: 0,
        isPaging: true,
        newRequestId: id,
      },
    ],
    query
  );
  if (isLoading) return '';
  if (isError) return '';

  return (
    <>
      {data.data.map((e) => {
        return <OldInfo data={e} />;
      })}
    </>
  );
}

const OldInfo = (props) => {
  const params = useParams();
  const id = parseInt(params.id);
  const queryClient = useQueryClient();

  const generalData = queryClient.getQueryData([`singleTalab-${id}`]);

  const complainsData = [
    { id: 0, name: 'شكوى' },
    { id: 1, name: 'اقتراح' },
  ];

  const { data } = props;
  console.log('data.commentStatus === 0 && data.createdBy', data.commentStatus === 0, data.createdBy !== 0);
  return (
    <div
      className={`bg-[#f3f3f3] border-r-4  border-[#4f46e5] p-4 mt-4 shadow-md ${
        data.commentStatus === 0 && data.createdBy !== 0 ? 'bg-red-200 border-red-600' : ''
      }`}
    >
      <div className="grid grid-cols-3 gap-6">
        <div className="font-bold">
          <span>تاريخ : </span>
          <span>{DateTime.fromISO(data.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
        </div>
        <div className="font-bold">
          <span>النوع : </span>
          <span>
            {' '}
            {data.createdBy !== 0 ? 'رد على' : ''}{' '}
            {complainsData.filter((e) => e.id === parseInt(data.commentStatus))[0].name}
          </span>
        </div>
        <div className="font-bold">
          <span>الاسم : </span>
          <span>{data.createdBy !== 0 ? data.createdByFullName : generalData.data.companyName}</span>
        </div>
        <div className="col-span-3 whitespace-pre">{data.text}</div>
      </div>
    </div>
  );
};

export const query = async ({ queryKey }) => {
  const { data } = await axios.post('UserAccount/GetAllNewRequestCommentsByRequestId', queryKey[1]);
  return data;
};
