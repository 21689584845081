import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Notification from '../../SharedComponents/Notification';
import { dir } from '../../utils/localisation';
import { addUser } from '../Users/Users/ApiUser';
import { ChangeRequestStatus, ConnectFormToOwner, getEnglishName } from './ApRequests';
import Eye from '../../SharedComponents/Svg/Eye';
import { useTranslation } from 'react-i18next';
import Loading from '../../SharedComponents/Loading';
export default function GenerateUserForm(props) {
  const [userData, setUserData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const { questionType, answerId, purposeOfRequest, requestId } = useParams();
  const numbers = '0123456789';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const specialCharacters = "!'^+%&/()=?_#$½§{[]}|;:>÷`<.*-@é";
  const characterList = numbers + upperCaseLetters + lowerCaseLetters + specialCharacters;
  const createPassword = (characterList) => {
    let password = '';
    const characterListLength = characterList.length;

    for (let i = 0; i < 10; i++) {
      const characterIndex = Math.round(Math.random() * characterListLength);
      password = password + characterList.charAt(characterIndex);
    }
    return password;
  };

  const ChangeRequestStatusMutation = useMutation(ChangeRequestStatus, {
    onSuccess: async (data) => {
      if (data.isDone) {
        history.push(`/request/${questionType}/${answerId}/${3}/${requestId}/${purposeOfRequest}`);
      }
    },
  });
  const connectFormToOwnerMutation = useMutation([`GetEnglishName`], ConnectFormToOwner, {
    onError: (data) => {
      console.log('error', data);
      toast(<Notification error message={'error while adding'} />);
    },
    onSuccess: (data) => {
      ChangeRequestStatusMutation.mutate({
        id: requestId,
        requestStatus: 3,
      });
    },
  });
  const getEnglishNameQuery = useQuery([`GetEnglishName`, { answerId }], getEnglishName, {
    onError: (data) => {
      toast(<Notification error message={'error Added'} />);
    },
    onSuccess: (data) => {
      setUserData(data.data);
    },
  });
  const addUserMutation = useMutation(addUser, {
    onError: (data) => {
      toast(<Notification error message={'error While adding User'} />);
    },
    onSuccess: (data) => {
      if (data.isDone) {
        toast(<Notification message={'user Added'} />);

        connectFormToOwnerMutation.mutate({
          formOwner: data.outId,
          submitId: userData.submitId,
        });
      } else if (data.isDone === false) {
        toast(<Notification error message={data.isMessage} />);
      }
    },
  });
  const { t } = useTranslation();

  // if (getEnglishNameQuery.isLoading) return 'loading';
  if (getEnglishNameQuery.isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (getEnglishNameQuery.isError) return 'error';
  const initialValues = {
    userName: getEnglishNameQuery?.data?.data?.englishName.replace(/\s+/g, '-'),
    password: '',
    email: getEnglishNameQuery?.data?.data?.englishName.replace(/\s+/g, '-') + '@gmaieeeeel.com',
    role: 1026,
    isActive: true,
  };

  const onSubmit = (e) => {
    addUserMutation.mutate(e);
  };
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="p-4 max-w-xl">
                <label className={`flex  flex-col  justify-start space-y-2 mb-4`}>
                  <span>UserName</span>
                  <Field name="userName" className="border rounded py-2 px-3" type="text" />
                </label>
                <label className={`flex  flex-col  justify-start space-y-2 relative`}>
                  <span>Password</span>
                  <Field
                    name="password"
                    className="border rounded py-2 px-3"
                    type={showPassword ? 'text' : 'password'}
                  />
                  <button
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    type="button"
                    className={` absolute  top-0  ${dir('mr-8 right-0', 'ml-8 left-0')} pt-8    text-gray-600`}
                  >
                    <Eye className="w-5 h-5" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue('password', createPassword(characterList));
                      toast(<Notification message={t('Password Generated, Successfully')} />);
                    }}
                    className="bg-green-500 hover:bg-green-600 text-white py-2 rounded"
                  >
                    Generate Password
                  </button>
                </label>
                <div className="mt-4">
                  <button className="bg-move text-white rounded-md py-2 px-8    " type="submit">
                    Next
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
