import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { NavLink } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Loading from '../../SharedComponents/Loading';
import TablePagination from '../../SharedComponents/TablePagination';
import { NegativePrintCompponent } from './NegativePrintCompponent';
import PrintConfirmation from './PrintConfirmation';
import { PrintInformation } from './PrintInformation';

export default function Information(props) {
  const { t } = useTranslation();
  const [haveData, setHaveData] = useState([]);
  const [searchName, setSearchName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationCount, setPaginationCount] = useState([]);
  const [dayData, setDayData] = useState([]);
  const [datax, setDatax] = useState({});
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {}, [haveData]);
  useEffect(() => {
    searchInUsersMutation.mutate({
      pageNumber: pageNumber,
      pageSize: 10,
      isPaging: true,
      fullName: searchName,
    });
  }, [pageNumber]);
  const onSubmit = (e) => {
    setIsLoading(true);
    setHaveData([]);

    // const newData = fakeData.filter((x) => x.name.includes(e.name));
    // setHaveData(newData);
    setSearchName(e.name);
    searchInUsersMutation.mutate({
      pageNumber: pageNumber,
      pageSize: 10,
      isPaging: true,
      fullName: e.name,
    });
  };

  console.log('====================================');
  console.log('haveData', haveData);
  console.log('====================================');
  const dataColumns = [
    {
      Header: 'الاسم',
      accessor: 'fullName',
    },

    {
      Header: '',
      accessor: 'ssss',
      Cell: (cell) => {
        console.log('cell.row.original', cell.row.original);
        return (
          <>
            <PrintConfirmation dayData={dayData} mutationData={cell.row.original} />
            {/* <button
              className="bg-blue-500 text-white px-4 mx-4 py-2 rounded-md"
              onClick={() => {
                setDatax(cell.row.original);
                mutation.mutate(cell.row.original);
              }}
            >
              {' '}
              طباعة
            </button> */}
          </>
        );
      },
    },
  ];

  const searchInMutationFunction = async (e) => {
    const queryInitialState = {
      pageNumber: 1,
      pageSize: 10,
      isPaging: false,
      langId: 1,
      isFilter: true,
      filter: [
        {
          filterBy: 'datefromto',
          filterType: 'string',
          dateFilteration: {
            from: DateTime.now().toISODate(),
            to: DateTime.now().toISODate(),
          },
          searchTerm: [
            {
              langId: 0,
              term: 'string',
            },
          ],
        },
      ],
    };
    const { data } = await axios.post(`/UserAccount/SearchInUsers`, e);
    const informationList = await axios.post(`/UserAccount/GetAllPrintingStatusAduit`, queryInitialState);

    return { ...data, informationList };
  };
  const searchInUsersMutation = useMutation(searchInMutationFunction, {
    onSuccess: (data) => {
      setHaveData(data.data);
      setDayData(data?.informationList?.data?.data);

      console.log('====================================');
      console.log('data', data.informationList.data.data);
      console.log('====================================');
      pagination(data);

      setIsLoading(false);
    },
  });

  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('information')} />

          {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('information')}</h2>
        </div>
        <div className="bg-white p-4 shadow-lg rounded-md border">
          <h3 className="text-2xl font-bold pb-4 relative text-[#1a3175]">
            الاستعلام عن أعضاء الاتحاد <hr className="h-1 w-16 bg-[#1a3175] absolute bottom-0 right-0" />
          </h3>
          <hr />
          <Formik onSubmit={onSubmit} initialValues={{ name: '' }}>
            {({ values }) => {
              return (
                <Form>
                  <div className="grid grid-cols-2 py-8 items-center gap-6">
                    <div className="flex flex-col">
                      <label className="text-lg">الاسم</label>
                      <Field type="text" name="name" className="border rounded-md p-2" />
                    </div>
                    <div className="">
                      <button
                        className="bg-green-600 hover:bg-green-700 text-white inline-block px-6 font-bold py-2 mt-7 rounded-md"
                        type="submit"
                      >
                        بحث
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {isLoading ? (
          <div className="  bg-white p-4 py-8 shadow-lg rounded-md border mt-4">
            <div className="flex space-x-2 space-x-reverse items-center text-2xl font-bold  relative text-[#1a3175]">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>جاري البحث عن البيانات في قاعدة بيانات الاتحاد</span>
            </div>
          </div>
        ) : (
          <>
            {searchName && (
              <>
                {searchInUsersMutation.isLoading !== true && haveData.length > 0 ? (
                  <div className="  bg-white p-4 py-8 shadow-lg rounded-md border mt-4">
                    <div className="flex space-x-4 space-x-reverse px-4 text-xl font-bold">
                      <h3>عدد الاسماء المطابقة</h3>
                      <span className="text-red-500">
                        [ {searchInUsersMutation?.data?.paginationResult?.dataCount} ]
                      </span>
                    </div>
                    <CustomTableAr dataColumns={dataColumns} queryData={haveData} />
                    <TablePagination
                      data={searchInUsersMutation.data}
                      paginationCount={paginationCount}
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                    />
                  </div>
                ) : (
                  <div className="  bg-white p-4 py-8 shadow-lg rounded-md border mt-4">
                    <CustomTableAr dataColumns={dataColumns} queryData={[{ fullName: searchName, statusId: null }]} />
                  </div>
                  // <div className="flex items-center space-x-1 space-x-reverse bg-white p-4 py-8 shadow-lg rounded-md border mt-4">
                  //   <span>المهندس / </span>
                  //   <span>{searchName}</span>
                  //   <span>غير مسجل بالاتحاد</span>
                  //   <NegativePrintCompponent name={searchName} />
                  // </div>
                )}
              </>
            )}
          </>
        )}

        {/* <CustomTableAr dataColumns={dataColumns} queryData={data.data} /> */}
      </div>
    </>
  );
}
