import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import Button from '../../SharedComponents/Button/Button';
import Text from '../../SharedComponents/Inputs/components/Text';
import Modal from '../../SharedComponents/Modal/Modal';
import { PlusIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import Notification from '../../SharedComponents/Notification';
import { Field, Form, Formik } from 'formik';

import SelectV2 from '../../SharedComponents/Inputs/components/SelectV2';
export default function EditRequestPosition(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { name, infoId, mutationData } = props;
  const sharedProps = { isOpen, setIsOpen, name, infoId, mutationData };
  return (
    <>
      <div className="inline-block">
        <Button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          text="تعديل"
          className="bg-blue-500 text-white  mx-4 py-2 rounded-md"
        />
      </div>

      {isOpen && <ModalContainer {...sharedProps} />}
    </>
  );
}

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { isOpen, setIsOpen, infoId, mutationData } = props;
  const sharedProps = { isOpen, setIsOpen };
  const followupData = queryClient.getQueryData(`followup`);
  const requestStatusData = queryClient.getQueryData(`requestStatus`);
  const initialValues = mutationData;
  const mutationFunction = async (e) => {
    const { data } = await axios.post(`/UserAccount/EditRequestPosition`, e);

    return data;
  };
  const params = useParams();
  const id = parseInt(params.id);

  const mutation = useMutation(mutationFunction, {
    onSuccess: async (data, values) => {
      await queryClient.invalidateQueries([`singleTalab-${id}`]);
      await queryClient.invalidateQueries([`signleTalabRquestPositions-${id}`]);
      toast(<Notification message={data.message} />);

      setIsOpen(false);
    },
  });
  const onSubmit = (e) => {
    mutation.mutate(e);
  };
  return (
    <>
      {isOpen && (
        <Modal modalTitle="تعديل الموقف" {...sharedProps}>
          <div className="bg-[#fff] border border-gray-100 border-r-4 print:hidden  border-r-[#84cc16] p-4 mt-4 shadow-md">
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
              {({ values, errors, resetForm }) => {
                console.log('values', values);
                return (
                  <Form>
                    <div className="grid grid-cols-3 gap-6 mt-4">
                      <div className="">
                        <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                          <span className={`font-bold text-base text-right rtl:text-right `}> تاريخ الموقف</span>
                          <Field type="date" name="positionDateString" className="border rounded py-2 px-3" />
                        </label>
                      </div>

                      <div className="">
                        <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                          <span className={`font-bold text-base text-right rtl:text-right  `}> حالة الطلب *</span>
                          <SelectV2
                            selectList={requestStatusData?.data}
                            loading={requestStatusData === undefined}
                            wrapperClass="mt-0"
                            errors={errors}
                            name="requestStatusId"
                          />
                        </label>
                      </div>
                      <div className="">
                        <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                          <span className={`font-bold text-base text-right rtl:text-right `}> جهة المتابعة *</span>
                          <SelectV2
                            selectList={followupData?.data}
                            loading={followupData === undefined}
                            wrapperClass="mt-0"
                            errors={errors}
                            name="requestFollowupId"
                          />
                        </label>
                      </div>
                      <div className="col-span-3">
                        <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                          <span className={`font-bold text-base text-right rtl:text-right `}> ملاحظات</span>
                          <Field name="notes" className="border rounded py-2 px-3" as="textarea" />
                        </label>
                      </div>
                      <div className="col-span-3">
                        <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                          <span className={`font-bold text-base text-right rtl:text-right `}>
                            ملاحظات خاصة بالاتحاد
                          </span>
                          <Field name="internalNotes" className="border rounded py-2 px-3" as="textarea" />
                        </label>
                      </div>
                      <div className="flex space-x-2 rtl:space-x-reverse">
                        <div>
                          <Button
                            type="submit"
                            loading={mutation.isLoading}
                            disabled={mutation.isLoading}
                            className="px-10"
                          />
                        </div>
                        <div>
                          <Button
                            text="الغاء"
                            onClick={() => {
                              resetForm();
                              setIsOpen(false);
                            }}
                            className="bg-red-500 hover:bg-red-400 px-10"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

const mutationFunction = async (e) => {
  const { data } = await axios.post(`ComplainDepartment/Delete`, {
    id: e.id,
  });
  return data;
};
