import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { dir } from '../utils/localisation';
import Excel from '../SharedComponents/Svg/Excel';

export default function DynamicExportExcel(props) {
  const { fileHeaders, fileName, fileType, title, dataQuery } = props;
  const { t } = useTranslation();

  const csvReport = {
    data: dataQuery,
    headers: fileHeaders,
    filename: `${fileName}.${fileType}`,
  };
  return (
    <>
      {dataQuery && (
        <CSVLink {...csvReport}>
          <span
            className={`py-2 rounded px-5 flex text-white  bg-lightgreen space-x-2 items-center text-sm font-bold ${dir(
              '',
              'space-x-reverse'
            )}`}
          >
            {' '}
            <Excel />
            <span className={dir('ml-2', 'mr-2')}>{t('Export')}</span>
          </span>
        </CSVLink>
      )}
    </>
  );
}
