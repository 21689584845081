import toast from 'react-hot-toast';
import LoadingSpinner from '../Loading/LoadingSpinner';
/**
 *
 *
 * @export
 * @param {*} { type , text , loading , className, onClick,noText }
 * @returns
 */
export default function Button(props) {
  const type = props.type ? props.type : 'button';
  const text = props.text ? props.text : 'حفظ';
  const loading = props.loading ? props.loading : false;
  const className = props.className ? props.className : '';
  const disabled = props.disabled ? props.disabled : false;
  const icon = props.icon;
  const noText = props.noText;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={` flex items-center w-full justify-center text-white py-2 mt-4 space-x-2 space-x-reverse relative rounded bg-blue-500 hover:bg-blue-600 transition-all ease-in-out duration-300 ${className}`}
    >
      {icon}
      {!noText && <span>{text}</span>}
      {loading && <LoadingSpinner className={'left-4'} />}
    </button>
  );
}
