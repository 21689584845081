import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import Trash from '../../SharedComponents/Svg/Trash';
import { deleteJob } from './api/api';

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { id, isOpen, setIsOpen } = props;
  const { t, i18n } = useTranslation();
  const { mutate, data, isLoading } = useMutation(deleteJob, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['GetJobs']);
      }
      setIsOpen(false);
    },
  });
  const onSubmit = async () => {
    mutate({ id });
  };

  return (
    <Formik onSubmit={onSubmit}>
      <Form>
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={onSubmit}
          confirmTitle={t('Delete')}
          title={t('Delete Job')}
          isLoading={isLoading}
          isDone={data?.isDone}
          submitType="button"
        >
          <div className="bg-white ">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                {/* <!-- Heroicon name: outline/exclamation --> */}
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div
                className={`mt-3 text-center sm:mt-0  ${
                  i18n.language === 'en' ? 'sm:text-left sm:ml-4' : 'sm:text-right sm:mr-4'
                }`}
              >
                <div className="">
                  <p className="text-base font-normal text-gray-400 whitespace-pre-line">
                    {t(
                      'Are you sure you want to delete this Job? All the data will be permanently removed from our servers forever. This action cannot be undone'
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Form>
    </Formik>
  );
};

export default function DeleteJob(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props;

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
        className="bg-[#fde8e7] text-[#a93c3c] hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <Trash className="w-5 h-5" />
      </button>
      {isOpen && <ModalContainer id={id} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
