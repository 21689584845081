import { Route, Switch } from 'react-router';
import Answers from '../../Views/Answers/Answers';
import ViewAnswer from '../../Views/Answers/ViewAnswer';
import Assets from '../../Views/Assets/Assets';
import AssetsCategory from '../../Views/Assets/AssetsCategory';
import Calendar from '../../Views/Calendar/Calendar';
import Categories from '../../Views/Categories/Categories';
import Complaints from '../../Views/Complaints/Complaints';
import Dashboard from '../../Views/Dashboard/Dashboard';
import Faqs from '../../Views/Faq/Faqs';
import FormRoles from '../../Views/FormRoles/FormRoles';
import FormAnswer from '../../Views/Forms/FormAnswer/FormAnswer';
import SingleFormAnswer from '../../Views/Forms/FormAnswer/SingleFormAnswer';
import FormQuestionOptions from '../../Views/Forms/FormQuestionOption/FormQuestionOptions';
import FormQuestionOptionsTypes from '../../Views/Forms/FormQuestionOption/Type/FormQuestionOptionsTypes';
import Forms from '../../Views/Forms/Forms';
import Gallery from '../../Views/Gallery/Gallery';
import GalleryAlbums from '../../Views/Gallery/GalleryAlbums/GalleryAlbums';
import Language from '../../Views/Language/Language';
import Menus from '../../Views/Menus/Menus';
import NewsLetters from '../../Views/NewsLetter/NewsLetters';
import CreatePage from '../../Views/Page/Pages/CreatePage';
import Page from '../../Views/Page/Pages/Page';
import Pages from '../../Views/Page/Pages/Pages';
import CreatePoll from '../../Views/Polls/Poll/CreatePoll';
import Poll from '../../Views/Polls/Poll/Poll';
import Polls from '../../Views/Polls/Polls';
import Reports from '../../Views/Reports/Reports';
import VisitorsReports from '../../Views/Reports/VisitorsReports';
// import CreateComplaintsRequest from '../../Views/Requests/CreateComplaintsRequest';
import CreateRequest from '../../Views/Requests/CreateRequest';
import Requests from '../../Views/Requests/Requests';
import Settings from '../../Views/Settings/Settings';
import Roles from '../../Views/Users/Roles/Roles';
import CreateUsers from '../../Views/Users/Users/CreateUsers';
import EditUser from '../../Views/Users/Users/EditUser';
import Users from '../../Views/Users/Users/Users';
import Request from '../../Views/Requests/Request';
import Form from '../../Views/Forms/Form/Form';
import Employees from '../../Views/Employees/Employees';
import CreateComplaintRequest from '../../Views/Complaints/CreateComplaintRequest';
import SingleComplaint from '../../Views/Complaints/SingleComplaint';
import CreateEmployee from '../../Views/Employees/CreateEmployee';
import MyCalender from '../../Views/MyCalender/MyCalender';
import ViewNotifications from '../../Views/ViewNotifications/ViewNotifications';
import NotificationsSettings from '../../Views/NotificationsSettings/NotificationsSettings';
import MyNotificationsSettings from '../../Views/NotificationsSettings/MyNotificationsSettings';
import JobEmployment from '../../Views/JobEmployment/JobEmployment';
import AddJobEmployment from '../../Views/JobEmployment/AddJobEmployment';
import EditJobEmployment from '../../Views/JobEmployment/EditJobEmployment';
import Subscribers from '../../Views/subscribers/subscribers';
import SubscriptionDataById from '../../Views/subscribers/SubscriptionDataById';
import SubscribersDataById from '../../Views/subscribers/SubscribersDataById';
import Suggestions from '../../Views/Suggestions/Suggestions';
import Consulting from '../../Views/Consulting/Consulting';
import AgendaList from '../../Views/Agenda/AgendaList';
import Information from '../../Views/Information/Information';
import ComplaintDepartments from '../../Views/ComplaintDepartments/ComplaintDepartments';
import ComplaintsNew from '../../Views/ComplaintsNew/ComplaintsNew';
import SingleComplaintNew from '../../Views/ComplaintsNew/SingleComplaintNew';
import UserStatuses from '../../Views/UserStatuses/UserStatuses';
import Statistcs from '../../Views/Statistics/Statistcs';
import InformationList from '../../Views/Information/InformationList';
import PermissionHolder from '../PermissionHolder';
import FullInfo from '../../Views/Information/FullInfo';
import NewTalab from '../../Views/talabat/NewTalab';
import Talabat from '../../Views/talabat/Talabat';
import SingleTalab from '../../Views/talabat/SingleTalab';

export default function Routes(props) {
  const routes = [
    {
      path: '/',
      component: (
        <PermissionHolder permission="tasheed.statistics.nav">
          <Statistcs />
        </PermissionHolder>
      ),
    },
    { path: '/dashboard', component: <Dashboard /> },
    { path: '/pages', component: <Pages /> },
    { path: '/pages/create', component: <CreatePage /> },
    { path: '/pages/:id', component: <Page /> },
    { path: '/roles', component: <Roles /> },
    { path: '/users', component: <Users /> },
    { path: '/createUsers', component: <CreateUsers /> },
    { path: '/createEmployee', component: <CreateEmployee /> },
    { path: '/editUser/:id', component: <EditUser /> },
    { path: '/assets', component: <Assets /> },
    { path: '/assets/:activeTab', component: <Assets /> },
    { path: '/assets/:activeTab/:folderId', component: <AssetsCategory /> },
    { path: '/requests', component: <Requests /> },
    { path: '/requests/:questionType/:status/:purposeOfRequest', component: <CreateRequest /> },
    { path: '/notifications', component: <ViewNotifications /> },

    // { path: '/complaintsRequest/:questionType/:status', component: <CreateComplaintsRequest /> },
    { path: '/request/:questionType/:answerId/:status/:requestId/:purposeOfRequest', component: <Request /> },
    { path: '/forms', component: <Forms /> },
    { path: '/formAnswer/:formId', component: <FormAnswer /> },
    { path: '/formAnswer/:formId/:submitId', component: <SingleFormAnswer /> },
    { path: '/forms/:id', component: <Form /> },
    { path: '/formQuestionOptionsTypes', component: <FormQuestionOptionsTypes /> },
    { path: '/formQuestionOptionsTypes/:typeId/:name', component: <FormQuestionOptions /> },
    { path: '/formRoles', component: <FormRoles /> },
    { path: '/galleryalbums', component: <GalleryAlbums /> },
    { path: '/galleryalbums/:albumId', component: <Gallery /> },
    { path: '/menus', component: <Menus /> },
    { path: '/faq', component: <Faqs /> },
    { path: '/categories', component: <Categories /> },
    { path: '/language', component: <Language /> },
    { path: '/newsletters', component: <NewsLetters /> },
    { path: '/settings', component: <Settings /> },
    { path: '/polls', component: <Polls /> },
    { path: '/polls/create', component: <CreatePoll /> },
    { path: '/polls/:id', component: <Poll /> },
    { path: '/reports', component: <Reports /> },
    { path: '/reports/visitors', component: <VisitorsReports /> },
    { path: '/answers/:qOptionId/:qOptionName/:purposeOfRequest', component: <Answers /> },
    { path: '/:qOptionName/:formID/:requestId/:requestStatus', component: <ViewAnswer /> },
    { path: '/calendar', component: <Calendar /> },
    { path: '/MyCalender', component: <MyCalender /> },
    { path: '/employees', component: <Employees /> },
    { path: '/complaints/:qOptionId/:qOptionName', component: <Complaints /> },
    { path: '/singleComplaint/:submitId', component: <SingleComplaint /> },
    // { path: '/complaintRequest/:questionType/:answerId/:status/:requestId', component: <ComplainRequest /> },
    { path: '/complaintsRequest/:questionType/:status', component: <CreateComplaintRequest /> },
    { path: '/NotificationsSettings', component: <NotificationsSettings /> },
    { path: '/MyNotificationsSettings', component: <MyNotificationsSettings /> },
    { path: '/jobEmployment', component: <JobEmployment /> },
    { path: '/addJobEmployment', component: <AddJobEmployment /> },
    { path: '/editJobEmployment/:id', component: <EditJobEmployment /> },
    { path: '/Subscribers', component: <Subscribers /> },
    { path: '/Subscribers/:id', component: <SubscribersDataById /> },
    { path: '/Subscriber/:id', component: <SubscriptionDataById /> },
    { path: '/Suggestions', component: <Suggestions /> },
    { path: '/consultations', component: <Consulting /> },
    { path: '/agenda', component: <AgendaList /> },
    { path: '/information', component: <Information /> },
    { path: '/informationList', component: <InformationList /> },
    { path: '/complaintDepartments', component: <ComplaintDepartments /> },
    { path: '/complaints', component: <ComplaintsNew /> },
    { path: '/complaints/:id', component: <SingleComplaintNew /> },
    { path: '/userStatuses', component: <UserStatuses /> },
    { path: '/information/info', component: <FullInfo /> },
    { path: '/talabat/new', component: <NewTalab /> },
    { path: '/talabat', component: <Talabat /> },
    { path: '/talabat/:id', component: <SingleTalab /> },
  ];

  return (
    <>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route key={index} exact path={route.path}>
              {route.component}
            </Route>
          );
        })}
      </Switch>
    </>
  );
}
