import axios from 'axios';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../../SharedComponents/Notification';

export default function MuteToggle(props) {
  console.log('data', props.data);
  const { id, userId, notificationTypeId, mute } = props.data;
  const isActive = mute;
  const [activeToggle, setActiveToggle] = useState(isActive);
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(EditNotificationsTypeUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['GetNotificationsTypeAllUserQuery']);
    },
  });
  const handleClick = async (e) => {
    setActiveToggle(!activeToggle);
    const newData = {
      id: id,
      userId: userId,
      mute: !activeToggle,
      notificationTypeId: notificationTypeId,
    };
    // const newData = { menuItemId: id, isMainMenu: !activeToggle };
    // setNewUserRole(parseInt(e.role));

    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <button onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
        {/* <!-- toggle --> */}
        <div className="relative">
          {/* <!-- line --> */}
          <div
            className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}
          ></div>
          {/* <!-- dot --> */}
          <div
            className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
              activeToggle ? 'right-0' : 'left-0'
            } `}
          ></div>
        </div>
        {/* <!-- label --> */}
      </button>
      {data && data.isDone === true && <Notification message="Menu Item Status Have Been Changed Successfully" />}
      {data && data.isDone === false && <Notification error={true} message="Network Error" />}
    </>
  );
}

// this to set main menu
export const EditNotificationsTypeUser = async (e) => {
  const { data } = await axios.post('/Notifications/EditNotificationsTypeUser', e);
  return data;
};
