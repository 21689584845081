import { CheckIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Notification from '../../../SharedComponents/Notification';

export default function AgendaFlagAttended(props) {
  const { data } = props;
  const agendaFlagAttendedFunction = async (e) => {
    const { data } = await axios.post(`/Agenda/EditAgendaAttend`, e);

    return data;
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(agendaFlagAttendedFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries('getAgenda');
      toast(<Notification message={'تم التعديل بنجاح'} />);
    },
  });

  const onClick = (props) => {
    mutation.mutate({
      id: data.id,
      attended: true,
    });
  };

  return (
    <>
      <button onClick={onClick} type="button" className="bg-green-100 text-green-500 hover:bg-green-300 rounded-md p-1">
        {mutation.isLoading ? (
          <svg
            className="animate-spin  h-5 w-5 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <CheckIcon className="h-4" />
        )}
      </button>
    </>
  );
}
