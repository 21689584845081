import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import DropZone from '../Assets/DropZone';

export default function UploadAttachment(props) {
  const { t, i18n } = useTranslation();

  const { files, setFiles } = props;
  console.log(files);
  const [uploadSucess, setUploadSucess] = useState(false);

  // localState for image uploaded
  const [images, setImages] = useState([]);
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);

  const uploadUsingAxios = async (formData) => {
    await axios.post(window.env.REACT_APP_CDN, formData).then((res) => {
      if (res.data.success) {
        onSubmit(res.data.path);
        setUploadSucess(true);
        setUploading(false);
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setUploading(true);
    // loop through the accepted Files
    acceptedFiles.map((file) => {
      // Initialize FileReader browser API
      const reader = new FileReader();
      // onload callback gets called after the reader reads the file data
      reader.onload = function (e) {
        setImages((prevState) => [{ id: 1, src: e.target.result }]);
      };
      // Read the file as Data URL (since we accept only images)
      reader.readAsDataURL(file);

      return file;
    });
    setFile(acceptedFiles);

    const handleServer = async () => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      try {
        await uploadUsingAxios(formData);
      } catch (error) {
        console.log('error', error);
      }
    };
    handleServer();
  }, []);

  const onSubmit = async (filePath) => {
    setFiles((e) => [
      ...e,
      {
        file: filePath,
      },
    ]);
  };

  useEffect(() => {
    console.log(files);
  }, [files]);
  return (
    <>
      <div className="bg-[#e6e6e6] border-[#d8d8d8] mb-4 border-4 border-dashed rounded focus:outline-none">
        {/* Drag amd Drop Component */}
        <DropZone
          className="focus:outline-none"
          onDrop={onDrop}
          accept={`image/jpeg, image/png,image/jpg, image/ico , audio/*,'video/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/docx,application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`}
          images={images}
          multiple={false}
        />
      </div>
      {uploading && (
        <div className="relative pt-1">
          <div className="bg-gray-200 flex mb-4 text-xs rounded overflow-hidden">
            <div
              className={`shadow-none flex py-4 ${
                uploading ? 'w-full' : 'w-0 '
              } flex-col  transition-all ease-in-out animate-pulse duration-3000 text-center whitespace-nowrap text-white justify-center bg-main`}
            >
              <smal className="w-full text-center">{t('Uploading ...')} </smal>
            </div>
          </div>
        </div>
      )}

      {uploadSucess && (
        <>
          <small className="bg-[#43d396] block mb-4 p-1 text-center text-white">
            {t('File Uploaded Successfully')}
          </small>
        </>
      )}
    </>
  );
}
