import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import Loading from '../../SharedComponents/Loading';
import { dir } from '../../utils/localisation';
import { GetRequestMessageByRequestId } from './ApiAnswers';

export default function MessagesLog(props) {
  const { requestId } = useParams();
  const { t, i18n } = useTranslation();

  const body = {
    requestId: requestId,
  };

  const { data, isLoading, isError } = useQuery(['GetRequestMessages', body], GetRequestMessageByRequestId);
  if (isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError) return 'error';
  return (
    <>
      <div className="bg-gray-50 mt-8 ">
        <div className="bg-white border overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Notes Log</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {data.data.map((message, index) => {
                console.log('message', message);
                return (
                  <div
                    className={`${
                      index % 2 ? 'bg-white' : 'bg-gray-50'
                    }  px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      <span className="block">
                        {message.isApproved ? (
                          <span className="text-green-500">{t('Approved By')}</span>
                        ) : (
                          <span className="text-red-500">{t('Declined By')}</span>
                        )}
                        <b className="px-2">{message.createdByName}</b>
                      </span>
                      <span className="block text-xs">
                        {/* {message.createDate} */}
                        {dir(
                          `${DateTime.fromISO(message.createDate).setLocale('').toFormat('dd LLL yyyy')}`,
                          `${DateTime.fromISO(message.createDate).setLocale('ar-EG').toFormat('dd LLL yyyy')}`
                        )}
                      </span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {i18n.language === 'en' ? message.enMessage : message.arMessage}
                    </dd>
                  </div>
                );
              })}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
