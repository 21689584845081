import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import Notification from '../SharedComponents/Notification';
const NotificationContext = createContext(null);

export function NotificationProvider({ children }) {
  const [connection, setConnection] = useState(null);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl('https://egcapi.key-frame.cf/notify')
      .withAutomaticReconnect()
      .build();
    // .start();

    setConnection(connect);
    // console.log('connect', connect);
  }, []);

  useEffect(() => {
    if (connection) {
      console.log('connected');

      connection
        .start()

        .then((data) => {
          console.log('data', data);
          connection.on('ReceiveMessage', (user, message) => {
            console.log('xx', user, message);
            // toast('message');
            toast(<Notification message={message} />);
          });
        })
        .catch((error) => console.log(error));
      // console.log('connectionx', connection);
    }
  }, [connection]);

  // const sendMessage = async (data) => {
  //   console.log('datainSendMessage');
  //   if (connection) {
  //     console.log('connection', connection);
  //     try {
  //       await connection.invoke('SendMessage', ['1', '2'], 'Bola');
  //     } catch (error) {
  //       console.log('sss', error);
  //     }
  //   }
  //   // if (connection) await connection.recieve('RecieveMessage', 'Kenz');
  // };

  return <NotificationContext.Provider value={connection}>{children}</NotificationContext.Provider>;
}

export function useNotifications() {
  const context = useContext(NotificationContext);
  return context;
}
