import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { dir } from '../../../utils/localisation';
import axios from 'axios';
import { PencilAltIcon } from '@heroicons/react/outline';
// import { sendMessage } from '../../utils/sendMessage';

export default function EditUserStatus(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { item } = props;
  console.log('item', item);
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <PencilAltIcon className="w-5 h-5" />
      </button>

      {isOpen && <ModalContainer item={item} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, item } = props;
  const { t, i18n } = useTranslation();
  const userData = localStorage.getItem('token');
  const userId = JSON.parse(userData).userId;

  const initialValues = item;
  const history = useHistory();
  const queryClient = useQueryClient();

  // Mutate Data to Create New Page
  const mutation = useMutation(addUserStatus, {
    onSuccess: async (data, variables) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['UserStatuses']);
        setIsOpen(false);
      }
    },
  });

  const onSubmit = (e) => {
    try {
      mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // onSubmit={onSubmit}
                title={`تعديل حالة المستخدم`}
                isLoading={mutation.isLoading}
                add={true}
              >
                {/* Submit Form */}

                <label className={`flex  flex-col ${dir('justify-start', 'justify-end')}  space-y-2`}>
                  <span className={`font-bold text-base ${dir('text-right', 'text-right')}`}>الاسم</span>
                  <Field name="name" className="border rounded py-2 px-3" as="textarea" />
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const addUserStatus = async (e) => {
  console.log('e', e);
  const { data } = await axios.post(`/UserAccount/EditUserStatus`, e);
  return data;
};
