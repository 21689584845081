/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { Field, Form, Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import Notification from '../../../SharedComponents/Notification';

export default function ChangeComplaintDepartment(props) {
  const { submitionUserId, complaint } = props;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const initialQuery = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: false,
  };
  const cancelButtonRef = useRef(null);
  const fetchcomplaintDepartments = async (e) => {
    const { data } = await axios.post(`/ComplainDepartment/GetAll`, e.queryKey[1]);
    return data;
  };

  const mutationFunction = async (e) => {
    const { data } = await axios.post(`/Complain/Edit`, e);

    return data;
  };
  const queryClient = useQueryClient();
  const mutation = useMutation(mutationFunction, {
    onSuccess: (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(`singleComplaint-${complaint.id}`);
        toast(<Notification message={'تم تغيير القسم'} />);

        setOpen(false);
      } else {
        setOpen(false);
      }
    },
  });

  const { data, isLoading, isError } = useQuery(['complaintDepartments', initialQuery], fetchcomplaintDepartments, {
    onSuccess: (data) => {},
  });

  if (isLoading) return '';
  if (isError) return '';

  const onSubmit = (e) => {
    mutation.mutate({
      ...complaint,
      complainDepartmentId: e.complainDepartmentId,
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpen(!open);
        }}
        className={`bg-blue-500 space-x-1 space-x-reverse  text-sm font-bold text-white flex items-center hover:bg-blue-600 shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        {' '}
        تغيير قسم الشكوى
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <Formik onSubmit={onSubmit} initialValues={{ complainDepartmentId: complaint.complainDepartmentId }}>
                  {({ values }) => {
                    return (
                      <Form>
                        <div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                              تغيير قسم الشكوى{' '}
                            </Dialog.Title>
                            <div className="mt-2">
                              <div className="flex flex-col space-y-2">
                                <label className="mb-2 text-[#666666]">برجاء اختيار قسم</label>
                                <Field
                                  required
                                  className="border-[#f9c7e1] px-3 py-4 w-full bg-white border rounded-md outline-none"
                                  as="select"
                                  name="complainDepartmentId"
                                >
                                  <>
                                    {data?.data?.map((item) => (
                                      <option value={item.id} key={item.id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </>
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                          <button
                            type="submit"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                            // onClick={() => setOpen(false)}
                          >
                            تأكيد
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => {
                              setOpen(false);
                              setError();
                              setSuccess();
                            }}
                            ref={cancelButtonRef}
                          >
                            الغاء
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
