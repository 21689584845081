import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import * as yup from 'yup';
import { addLanguage, getLanguages } from './ApiLanguage';
import Notification from '../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import { dir } from '../../utils/localisation';

export default function AddLanguage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const id = props.id ? props.id + 1 : 1;
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  // Mutate Data to Create New Category
  const addLanguageMutation = useMutation(addLanguage, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['getlanguages']);
        setIsOpen(false);
      }
    },
  });
  return (
    <>
      {addLanguageMutation.isSuccess && <Notification message={t('Language Created Successfully')} />}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${dir(
          '',
          'space-x-reverse'
        )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New')}</span>
      </button>

      {isOpen && (
        <ModalContainer id={id} isOpen={isOpen} setIsOpen={setIsOpen} addLanguageMutation={addLanguageMutation} />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, id, setIsOpen, addLanguageMutation } = props;
  const initialValues = {
    code: '',
    name: '',
    orderNum: id,
    orderId: id,
    defaultLang: false,
  };
  const { t, i18n } = useTranslation();
  const onSubmit = async (e) => {
    try {
      addLanguageMutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSubmit={onSubmit}
                title={t('Create Language')}
                isLoading={addLanguageMutation.isLoading}
                add={true}
              >
                <div className="flex transform translate-y-1 relative z-10 mb-4"></div>

                {/* Submit Form */}
                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Name')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-1" type="text" name={'name'} />
                </label>
                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Code')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-1" type="text" name={'code'} />
                </label>
                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('Order Number')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-1" type="text" name={'orderNum'} />
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
