import { ErrorMessage, Field, useFormikContext } from 'formik';
/**
 *
 *
 * @export
 * @param {*} { name, icon, placeHolder, errors, required , label, type }
 * @returns
 */
export default function Select(props) {
  const { name, icon, placeHolder, required, label, wrapperClass, selectList, classNames } = props;

  const { errors } = useFormikContext();
  const isError = errors[name] ? true : false;
  const inputType = props.type ? props.type : 'text';
  return (
    <div className={`   mt-4 ${wrapperClass}`}>
      {label && (
        <label className="mb-1 block rtl:text-right">
          {' '}
          <span>{label}</span> {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className={`${isError ? 'border-red-500 text-red' : ''} ${icon ? 'flex' : ''} border`}>
        {icon && <div className="inline-flex items-center px-2 border-l">{icon}</div>}
        <Field
          component="select"
          name={name}
          placeholder={placeHolder}
          autoComplete="username"
          className={`flex-1 block w-full py-2 px-2 outline-none ${classNames}`}
        >
          {selectList?.map((e, index) => {
            return (
              <option key={index} value={e.id}>
                {e.name}
              </option>
            );
          })}
        </Field>
      </div>
      <ErrorMessage render={(msg) => <div className="text-red-500 text-xs  mt-2">{msg}</div>} name={name} />
    </div>
  );
}
