import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllPermissions } from '../../../utils/permissions';
import { arrayRemove } from '../../../utils/arrayRemove';
import { FieldArray, Form, Formik } from 'formik';
import Modal from '../../../SharedComponents/Modal';
import { ChevronLeftIcon, ChevronRightIcon, CogIcon, SearchIcon } from '@heroicons/react/solid';
import { dir } from '../../../utils/localisation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import axios from 'axios';

const ModalContainer = (props) => {
  const { role, editRoleMutation, isOpen, setIsOpen, loading } = props;
  const oldPermissions = role?.permission?.split(',');
  const [searchPermissions, setSearchPermissions] = useState('');
  const [searchAllPermissions, setSearchAllPermissions] = useState('');

  const [currentPermissions, setCurrentPermissions] = useState(oldPermissions ? oldPermissions : []);
  const { t, i18n } = useTranslation();

  let newCurrentPermissions = [];
  let newAllPermissions = [];
  const [allPermissions, setAllPermissions] = useState(getAllPermissions);

  // onSearchPermissions
  const handleAllPermissions = (e) => {
    // TODO
    // check if target is checked
    // make sure it's not duplicated
    // add it to fake array
    if (e.target.checked) {
      // !currentPermissions.includes(e)
      if (!newAllPermissions.includes(e.target.dataset.id)) newAllPermissions.push(e.target.dataset.id);
    } else {
      const newFilter = arrayRemove(newAllPermissions, e.target.dataset.id);
      newAllPermissions = [...newFilter];
    }
  };
  const handleCurrentPermissions = (e) => {
    // TODO
    // check if target is checked
    // make sure it's not duplicated
    // add it to fake array
    if (e.target.checked) {
      // !currentPermissions.includes(e)
      if (!newCurrentPermissions.includes(e.target.dataset.id)) newCurrentPermissions.push(e.target.dataset.id);
    } else {
      let newFilter = arrayRemove(newCurrentPermissions, e.target.dataset.id);
      newCurrentPermissions = [...newFilter];
    }
  };

  const handleLeftToRight = () => {
    const x = currentPermissions.filter((e) => !newCurrentPermissions.includes(e));
    setCurrentPermissions(x);
  };
  const handleRightToLeft = () => {
    setCurrentPermissions([...currentPermissions, ...newAllPermissions]);
  };
  const onSearchPermissions = (e) => {
    setSearchPermissions(e.target.value);
  };
  console.log('role', role);

  const onSubmit = async (e) => {
    try {
      editRoleMutation.mutate({
        roleName: role?.name,
        claims: currentPermissions,
      });
      // editRoleMutation({
      //   variables: {
      //     role: {
      //       id: role?.id,
      //       name: role?.name,
      //       permission: currentPermissions.join(','),
      //     },
      //   },
      // });
      // setIsOpen(false)

      // editRoleMutation({
      //   variables:{

      //   }
      // })
    } catch (error) {
      console.log('error');
    }
    // try {
    //   await mutate(newData)
    //   if (data?.isDone) {
    //     setIsOpen(false)
    //   }
    // } catch (error) {
    //   console.log('error', error)
    // }
  };
  return (
    <Formik
      initialValues={{
        currentPermissions,
        allPermissions,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <Modal
              isLoading={loading}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={`${t('Edit Role Permissions')}`}
              add={true}
              onSubmit={onSubmit}
              className="sm:max-w-3xl"
            >
              <div className="flex justify-between">
                <div className="w-80 border rounded">
                  {/* <div className="px-5 py-4 border-b">
                    <label
                      className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${dir(
                        '',
                        'space-x-reverse'
                      )}`}
                    >
                      <input className="text-gray-500 border-gray-500 rounded-none" key={222} type="checkbox" />
                      <span>
                        {allPermissions.length} {t('items')}
                      </span>
                    </label>
                  </div>
                  */}
                  <div className="flex items-center mx-4 my-4 p-1 px-2 text-black border rounded">
                    <input
                      className="w-full focus:outline-none"
                      type="text"
                      placeholder={t('Search here')}
                      onChange={(e) => setSearchAllPermissions(e.target.value)}
                    />
                    <SearchIcon className="w-4 h-4" />
                  </div>

                  <div className={`flex flex-col pl-5 max-h-56 overflow-y-scroll space-y-2 ${dir('pl-5', 'pr-5')} `}>
                    {allPermissions &&
                      allPermissions
                        .filter((e) => !currentPermissions.includes(e))
                        .filter((e) => t(e).toLowerCase().includes(t(searchAllPermissions).toLowerCase()))
                        .map((allPermission) => {
                          return (
                            <FieldArray
                              key={allPermission}
                              name="pgPageLngs"
                              render={(arrayHelpers) => (
                                <label
                                  key={allPermission}
                                  className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                                    i18n.language === 'en' ? '' : 'space-x-reverse'
                                  }`}
                                >
                                  <input
                                    className="text-gray-500 border-gray-500 rounded-none"
                                    key={allPermission}
                                    onChange={handleAllPermissions}
                                    data-id={allPermission}
                                    type="checkbox"
                                  />
                                  <span>{t(allPermission)}</span>
                                </label>
                              )}
                            />
                          );
                        })}
                  </div>
                  <div className="py-2 text-center text-gray-400 text-xs font-normal border-t">
                    {t('All Permissions')}
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex flex-col space-y-3">
                    <button
                      type="button"
                      className="p-1 text-gray-300 bg-gray-100 hover:bg-gray-200 rounded"
                      onClick={handleLeftToRight}
                    >
                      <ChevronRightIcon className={`w-5 h-5 transform ${dir('rotate-180', '')}`} />
                    </button>
                    <button
                      type="button"
                      className="p-1 text-gray-300 bg-gray-100 hover:bg-gray-200 rounded"
                      onClick={handleRightToLeft}
                    >
                      <ChevronLeftIcon className={`w-5 h-5 transform ${dir('rotate-180', '')}`} />
                    </button>
                  </div>
                </div>

                <div className="w-80 border rounded">
                  {/* <div className="px-5 py-4 border-b">
                    <label
                      className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                        i18n.language === 'en' ? '' : 'space-x-reverse'
                      }`}
                    >
                      <input className="text-gray-500 border-gray-500 rounded-none" key={222} type="checkbox" />
                      <span>
                        {currentPermissions.length} {t('items')}
                      </span>
                    </label>
                  </div> */}
                  <div className="flex items-center mx-4 my-4 p-1 px-2 border rounded">
                    <input
                      className="w-full text-gray-500 focus:outline-none"
                      type="text"
                      placeholder={t('Search here')}
                      onChange={onSearchPermissions}
                    />
                    <SearchIcon className="w-4 h-4" />
                  </div>

                  <div className={`flex flex-col pl-5 max-h-56 overflow-y-scroll space-y-2 ${dir('pl-5', 'pr-5')} `}>
                    {currentPermissions
                      .filter((e) => t(e).toLowerCase().includes(t(searchPermissions).toLowerCase()))
                      .map((currentPermission, index) => {
                        return (
                          <label
                            key={index}
                            className={`text-sm font-normal text-gray-500 flex items-center space-x-2 ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <input
                              className="text-gray-500 border-gray-500 rounded-none"
                              value={currentPermission}
                              onChange={handleCurrentPermissions}
                              data-id={currentPermission}
                              type="checkbox"
                            />
                            <span>{t(currentPermission)}</span>
                          </label>
                        );
                      })}
                  </div>
                  <div className="py-2 text-center text-gray-400 text-xs font-normal border-t">
                    {t('Current Permissions')}
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default function RolePermission(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const editRoleFunction = async (e) => {
    const { data } = await axios.post(`/Permission/Role`, e);

    return data;
  };
  const editRoleMutation = useMutation(editRoleFunction, {
    onSuccess: () => {
      setIsOpen(false);
      toast.success(t('Role Updated'));
    },
  });

  // const [editRoleMutation, { loading, data }] = useMutation(Edit_Role, {
  //   refetchQueries: [
  //     {
  //       query: getAllRoles,
  //     },
  //   ],
  //   onCompleted: () => {
  //     setIsOpen(false);
  //   },
  // });
  const { role } = props;
  console.log('role', role);
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`flex items-center p-1 text-white text-xs hover:bg-yellow-200 bg-yellow-500 rounded  px-2 ${dir(
          '',
          'space-x-reverse'
        )} transition duration-300 ease-in-out`}
      >
        <CogIcon className="w-5 h-5" />
      </button>
      {isOpen && (
        <ModalContainer
          isOpen={isOpen}
          loading={editRoleMutation.isLoading}
          setIsOpen={setIsOpen}
          role={role}
          editRoleMutation={editRoleMutation}
        />
      )}
    </>
  );
}
