import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../SharedComponents/Button/Button';
import SelectV2 from '../../SharedComponents/Inputs/components/SelectV2';
import Notification from '../../SharedComponents/Notification';

export default function SubmitComment(props) {
  const params = useParams();
  const id = parseInt(params.id);
  const queryClient = useQueryClient();
  const complainsData = [
    { id: 0, name: 'شكوى' },
    { id: 1, name: 'اقتراح' },
  ];

  const mutationFunction = async (e) => {
    const { data } = await axios.post(`UserAccount/AddNewRequestComments`, e);
    return data;
  };

  const mutation = useMutation(mutationFunction, {
    onSuccess: (data) => {
      toast(<Notification message={data.message} />);

      queryClient.invalidateQueries([`singleTalab-${id}`]);
      queryClient.invalidateQueries([`signleTalabRquestPositions-${id}`]);
      queryClient.invalidateQueries([`signleTalabRquestComments-${id}`]);
    },
  });

  const onSubmit = (e, { resetForm }) => {
    mutation.mutate(e);
    resetForm();
  };

  const initialValues = {
    text: '',
    commentStatus: complainsData[0].id,
    newRequestId: id,
  };
  return (
    <div className="bg-[#fff] print:hidden border border-gray-100 border-r-4  border-r-[#84cc16] p-4 mt-4 shadow-md">
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, errors, resetForm }) => {
          return (
            <Form>
              <div className="grid grid-cols-3 gap-6 mt-4">
                <div className="">
                  <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                    <span className={`font-bold text-base text-right rtl:text-right  `}> النوع</span>
                    <SelectV2 selectList={complainsData} wrapperClass="mt-0" errors={errors} name="commentStatus" />
                  </label>
                </div>

                <div className="col-span-3">
                  <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                    <span className={`font-bold text-base text-right rtl:text-right `}>الشكوى / المقترح</span>
                    <Field name="text" className="border rounded py-2 px-3" as="textarea" />
                  </label>
                </div>
                <div className="flex space-x-2 rtl:space-x-reverse">
                  <div>
                    <Button
                      type="submit"
                      loading={mutation.isLoading}
                      disabled={mutation.isLoading}
                      className="px-10"
                    />
                  </div>
                  <div>
                    <Button
                      text="الغاء"
                      onClick={() => {
                        resetForm();
                      }}
                      className="bg-red-500 hover:bg-red-400 px-10"
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
