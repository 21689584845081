import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

export default function AddComplaintReply(props) {
  const { id } = useParams();
  const userData = localStorage.getItem('token');
  const user = userData ? JSON.parse(userData) : 0;
  const initialValues = { message: '' };
  const addComplaintReplyFunction = async (e) => {
    const { data } = await axios.post(`/ComplainReply/Add`, e);
    return data;
  };
  const addComplaintReplyMutation = useMutation(addComplaintReplyFunction, {
    onSuccess: () => {
      console.log('sent');
    },
  });
  const onSubmit = (values, { resetForm }) => {
    const complaintData = {
      userID: parseInt(user?.userId),
      message: values.message,
      createdDatestring: DateTime.now().toISODate(),
      complainId: parseInt(id),
    };
    // sendMessage(
    // 	connection,
    // 	parseInt(user?.userId),
    // 	parseInt(id),
    // 	user?.fullName,
    // 	DateTime.now().toISODate(),
    // 	DateTime.now().toLocaleString(DateTime.TIME_SIMPLE),
    // 	e.message
    // );

    addComplaintReplyMutation.mutate(complaintData);
    resetForm();
  };
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, resetForm }) => {
          return (
            <Form className="flex items-end px-2 h-20 bg-white lg:px-6">
              <div className="flex flex-1 items-center pb-2">
                <Field name="message" type="text" placeholder="اكتب رسالتك" className="px-3 py-2 w-full outline-none" />
              </div>
              <div>
                <button
                  disabled={addComplaintReplyMutation.isLoading}
                  className={` ${
                    (addComplaintReplyMutation.isLoading || addComplaintReplyMutation?.data?.isDone) &&
                    'bg-gray-500 cursor-not-allowed'
                  }
									flex items-center justify-center mb-1  px-4 lg:px-8 py-2 lg:py-3  text-center text-white text-lg lg:text-xl font-semibold bg-[#18397c] rounded-lg`}
                  type="submit"
                >
                  <span>ارسال</span>
                  {addComplaintReplyMutation.isLoading && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

// int user,int complainId,string name,string Date,string time ,string message

export const sendMessage = async (connection, user, complainId, name, Date, time, message) => {
  console.log('hello', connection, user, complainId, name, Date, time, message);
  // user, complainId, name, Date, time, message
  if (connection) {
    try {
      await connection?.invoke('SendMessage', user, complainId, name, Date, time, message);
    } catch (error) {
      console.log('sendMessage', error);
    }
  }
};
