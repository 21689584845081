import React from 'react';
import { useTranslation } from 'react-i18next';
import Excel from '../../../SharedComponents/Svg/Excel';
import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getFormQuestionOption } from '../ApiForms';

export default function DownloadExcel(props) {
  const { languageId } = props;
  const { t, i18n } = useTranslation();

  const { typeId, name } = useParams();

  let initialState = {
    typeId: typeId,
    paginationFilter: {
      pageNumber: 0,
      pageSize: 0,
      isPaging: false,
      langId: 0,
    },
  };

  let govQueryinitialState = {
    typeId: 1011,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const govQuery = useQuery(['formQuestionOption', govQueryinitialState], getFormQuestionOption, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  let ocuppationCategoryQueryinitialState = {
    typeId: 1014,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const ocuppationCategoryQuery = useQuery(
    ['formQuestionOption', ocuppationCategoryQueryinitialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const { data, isLoading } = useQuery(['formQuestionOption', initialState], getFormQuestionOption, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  if (isLoading) return '';

  const oldTh = [
    { label: t(name), key: 'title' },
    name === 'Areas' ? { label: 'Governorate', key: 'governorate' } : null,
    name === 'Occupations' ? { label: 'Category', key: 'category' } : null,
  ];
  const tabltHeader = oldTh.filter((e) => e != null);

  const tabltdata = data.data.map((item) => {
    const obj = {
      governorate: govQuery.data?.data
        ?.filter((e) => item.isParent === e.id)
        ?.map((e) => {
          return e.frmQoptionLngs[0].title;
        }),
      category: ocuppationCategoryQuery.data?.data
        ?.filter((e) => item.isParent === e.id)
        ?.map((e) => {
          return e.frmQoptionLngs[0].title;
        }),
    };

    if (name !== 'Areas') delete obj.governorate;
    if (name !== 'Occupations') delete obj.category;

    return {
      title: item.frmQoptionLngs.filter((item) => languageId === item.languageId)[0].title,
      ...obj,
    };
  });
  const csvReport = {
    data: tabltdata,
    headers: tabltHeader,
    filename: 'new-visitors.csv',
  };
  return (
    <CSVLink {...csvReport}>
      <span
        className={`py-2 rounded px-5 flex text-white  bg-lightgreen space-x-2 items-center text-sm font-bold ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        }`}
      >
        <Excel />
        <span>{t('Excel')}</span>
      </span>
    </CSVLink>
  );
}
