import axios from 'axios';
import { useQuery } from 'react-query';
import { NavLink, useParams } from 'react-router-dom';
import { BeakerIcon, CloudDownloadIcon } from '@heroicons/react/solid';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Eye from '../../SharedComponents/Svg/Eye';
import { useTranslation } from 'react-i18next';
import AddConsultReply from './Components/AddConsultReply';
import ConsultingFAQ from './Components/ConsultingFAQ';
import SingleConsult from './Components/SingleConsult';
import TablePagination from '../../SharedComponents/TablePagination';
import { useState } from 'react';

export default function Consulting() {
  const params = useParams();
  const [paginationCount, setPaginationCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }

  const queryInitialState = {
    pageNumber: pageNumber,
    pageSize: 10,
    isPaging: true,
    langId: 1,
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(['getConsultations', queryInitialState], getConsultations, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  console.log('data', data);
  if (isLoading) return '';
  if (isError) return '';

  const dataColumns = [
    {
      Header: 'السؤال',
      accessor: 'message',
    },
    {
      Header: 'اسم المستخدم',
      accessor: 'createdByName',
    },
    {
      Header: 'عام / خاص',
      accessor: 'faq',
      Cell: ({ cell }) => {
        return (
          <>
            <ConsultingFAQ consult={cell.row.original} />
          </>
        );
      },
    },
    {
      Header: 'خيارات',
      accessor: 'options',
      Cell: ({ cell }) => {
        return (
          <div className="flex space-x-2 space-x-reverse">
            {/* <NavLink to={`/subscriber/${cell.row.original.id}`} className="bg-green-500 text-white p-1 rounded">
              <Eye className="h-4" />
            </NavLink> */}
            {/* <DeleteSuggestion id={cell.row.original.id} /> */}
            <SingleConsult consult={cell.row.original} />

            {cell.row.original.consultingReplies.length > 0 ? (
              <span className="bg-green-100  text-green-800 hover:bg-green-200  p-1 px-4 rounded transition duration-300 ease-in-out">
                تم الرد
              </span>
            ) : (
              <AddConsultReply id={cell.row.original.id} />
            )}
          </div>
        );
      },
      width: 0,
      maxWidth: 10,
      sortDescFirst: true,
    },
  ];
  console.log('data', data);

  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('Consultations')} />

          {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('Consultations')}</h2>
        </div>

        <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
        <TablePagination
          data={data}
          paginationCount={paginationCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
}

export const getConsultations = async ({ queryKey }) => {
  const { data } = await axios.post(`/Consulting/GetAll`, queryKey[1]);
  return data;
};
