import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import DownloadExcel from './DownloadExcel';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import Eye from '../../SharedComponents/Svg/Eye';
import Print from '../../SharedComponents/Svg/Print';
import Trash from '../../SharedComponents/Svg/Trash';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import { getLanguages } from '../Page/Pages/ApiPage';
import { GetAllAnswersByFrmQoptionTypeId } from './ApiAnswers';
import DownloadPdf from './DownloadPdf';
import DeleteBySubmitId from './DeleteBySubmitId';
import TablePaginationNew from '../../SharedComponents/TablePaginationNew';
import { dir } from '../../utils/localisation';
import { DateTime } from 'luxon';
import DynamicExportExcel from '../../SharedComponents/DynamicExportExcel';

export default function Complaints(props) {
  const { qOptionName, qOptionId } = useParams();
  const { t, i18n } = useTranslation();
  const [languageId, setLanguageId] = useState(2);
  const [entries, setEntries] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationCount, setPaginationCount] = useState([]);
  function pagination() {
    let paginationCount = [];
    for (var i = 1; i <= numberOfPages + 0.8; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {
    pagination();
  }, [numberOfPages]);

  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  const Approved = () => {
    return (
      <span className="text-[#28a745] border-[#28a745] border rounded-md text-sm py-2 px-4 font-normal">
        {t('Approved')}
      </span>
    );
  };
  const UnderRevision = () => {
    return (
      <span className="text-blue-500 border-blue-500 border rounded-md text-sm py-2 px-4 font-normal">
        {t('Under Revision')}
      </span>
    );
  };
  const Denied = () => {
    return (
      <span className="text-red-500 border-red-500 border rounded-md text-sm py-2 px-4 font-normal">{t('Denied')}</span>
    );
  };
  const Finished = () => {
    return (
      <span className="text-[#28a745] border-[#28a745] border rounded-md text-sm py-2 px-4 font-normal">
        {t('Finished')}
      </span>
    );
  };
  const complainStatus = {
    1: <Approved />,
    2: <Finished />,
    3: <Denied />,
    4: <UnderRevision />,
  };
  const complainStatusArray = {
    1: 'Approved',
    2: 'Finished',
    3: 'Denied',
    4: 'UnderRevision',
  };

  const initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    numberOfPages: 0,
    isPaging: false,
    isFilter: false,
    langId: languageId,
    isSearch: false,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: languageId,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: qOptionId,
  };
  const { data, isLoading, isError } = useQuery(['formAnswer', initialState], GetAllAnswersByFrmQoptionTypeId, {
    onSuccess: (data) => {
      setNumberOfPages(data.paging.totalPages);
    },
  });
  useEffect(() => {
    setNumberOfPages(data?.paging?.totalPages);
  }, [data]);
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  const tableHeaderen = [
    { title: t('Number') },
    { title: t('Complaint Title') },
    { title: t('Date') },
    { title: t('Details') },
    { title: t('OPTIONS'), className: 'w-64' },
  ];
  const tableHeaderar = [
    { title: t('ID') },
    { title: t('User name') },
    { title: t('Subject') },
    { title: t('Details') },
    { title: t('OPTIONS'), className: 'w-64' },
  ];

  const tableAnswers = [
    { questionId: 1050, field: 'answer' }, // title
    // { questionId: 1053, field: 'answer' }, // current residence
    // { questionId: 1054, field: 'answer' }, // file
    // { questionId: 1055, field: 'answer' }, // subject
    // { questionId: 1056, field: 'answer' }, // complaint hidden
    // { questionId: 1051, field: 'answer' },
  ];

  const fileHeaders = [
    {
      label: languageId === 2 ? 'Submit Id' : 'رقم التسجيل',
      key: 'submitId',
    },
    {
      label: languageId === 2 ? 'Complaint Title' : 'عنوان الشكوى',
      key: 'complaintTitle',
    },
    {
      label: languageId === 2 ? 'Status' : 'الحالة',
      key: 'status',
    },
    {
      label: languageId === 2 ? 'Date' : 'التاريخ',
      key: 'date',
    },
  ];

  const queryData = data.data.map((item) => {
    const x = {
      submitId: item.submitId,
      complaintTitle: item.answer.filter((e) => e.questionId === 1050)[0].answer,
      status: complainStatusArray[item.submitionStatus] ? complainStatusArray[item.submitionStatus] : 'under revision',
      date: DateTime.fromISO(item.createDate).toFormat('dd LLL yyyy'),
    };
    return x;
  });

  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name={t(qOptionName)} />

        <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          <DownloadPdf data={data} languageId={languageId} />
          {/* <DownloadExcel languageId={languageId} /> */}
          <DynamicExportExcel
            dataQuery={queryData}
            fileHeaders={fileHeaders}
            fileType="csv"
            fileName={`complaints report`}
          />
        </div>
      </header>

      {/* <div>
          <CreateForm languages={languages} />
        </div> */}

      {/* table */}
      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{qOptionName}</h2>
              <h4 className="bg-move text-white py-1 px-3 rounded-md">{data.data.length}</h4>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <div className=" px-5  mt-4">
          <div className=" flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              {/* <Link
                to={`/complaintsRequest/${qOptionId}/0`}
                className={`${dir(
                  '',
                  'space-x-reverse'
                )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
              >
                <PlusCircle className="w-5 h-5" /> <span>{t('Add New')}</span>
              </Link> */}
            </div>

            <div className="flex transform  relative z-10 ">
              <TableSearch
                entries={entries}
                // onChangeEntries={onChangeEntries}
                //   onSearch={onSearch}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {languageId === 1 &&
                        tableHeaderar.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }    uppercase font-bold text-sm ${e.className}`}
                            >
                              {e.title}
                            </th>
                          );
                        })}
                      {languageId === 2 &&
                        tableHeaderen.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }    uppercase font-bold text-sm ${e.className}`}
                            >
                              {e.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {data.data.map((item) => {
                      return (
                        <tr key={item.id} className="">
                          <td
                            className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                              'text-left',
                              'text-right'
                            )}`}
                          >
                            {item.submitId}
                          </td>
                          {tableAnswers.map((tableAnswer) => {
                            return (
                              <td
                                className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                                  'text-left',
                                  'text-right'
                                )}`}
                              >
                                {
                                  item.answer.filter((e) => e.questionId === tableAnswer.questionId)[0][
                                    tableAnswer.field
                                  ]
                                }
                              </td>
                            );
                          })}

                          <td
                            className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                              'text-left',
                              'text-right'
                            )}`}
                          >
                            {DateTime.fromISO(item.createDate).toFormat('dd LLL yyyy')}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                              'text-left',
                              'text-right'
                            )}`}
                          >
                            {complainStatus[item.submitionStatus] ? (
                              complainStatus[item.submitionStatus]
                            ) : (
                              <UnderRevision />
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            <div className={`flex space-x-2 ${dir('', 'space-x-reverse')}`}>
                              <Link
                                to={`/singleComplaint/${item.submitId}`}
                                className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
                              >
                                <Eye className="w-5 h-5" />
                              </Link>
                              {/* <DeleteBySubmitId id={item.submitId} /> */}
                            </div>
                            {/* {form.frmFormLngs.map((e) => {
                              return (
                                <Fragment key={e.id}>
                                  {languageId === e.languageId && (
                                    <>
                                      <div className="flex items-center space-x-2">
                                        <b>{e.title}</b>
                                      </div>
                                    </>
                                  )}
                                </Fragment>
                              );
                            })} */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <TablePaginationNew
                  entries={entries}
                  setEntries={setEntries}
                  data={data}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
