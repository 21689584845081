import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import { dir } from '../../utils/localisation';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import AddVisit from './AddVisit';
import { useQuery } from 'react-query';
import { GetAllCalenders } from './ApiCalender';
import EditVisit from './EditVisit';

export default function Calendar() {
  const { t } = useTranslation();
  const ref = useRef();
  function renderEventContent(eventInfo) {
    return <EditVisit eventInfo={eventInfo} />;
  }

  const { data, isLoading, isError } = useQuery(['GetAllCalenders'], GetAllCalenders);
  useEffect(() => {
    console.log('reloaded');
  }, [data]);
  if (isLoading) return 'loading';
  if (isError) return 'error';

  const INITIAL_EVENTS = data.data.map((event) => {
    return {
      id: event.id,
      title: JSON.stringify({
        time: event.visitTime,
        visitorArName: event.visitorArName,
        visitorEnName: event.visitorEnName,
        employeeName: 'employee',
        data: event,
      }),
      allDay: false,
      start: `${event.visitDate}T${event.visitTime}:00`,

      // start: event.visitDate,
    };
  });
  console.log('INITIAL_EVENTS', INITIAL_EVENTS);
  // const INITIAL_EVENTS = [
  //   {
  //     id: '122',
  //     title: 'event 1',
  //     start: new Date().toISOString().replace(/T.*$/, ''),
  //   },
  //   {
  //     id: '1',
  //     title: 'event 1',
  //     start: new Date().toISOString().replace(/T.*$/, '') + 'T12:00:00',
  //   },
  // ];

  const handleDateSelect = (selectInfo) => {
    // let calendarApi = selectInfo.view.calendar;
    // calendarApi.unselect();
    // calendarApi.addEvent({
    //   id: Math.random(),
    //   title: 'event',
    //   start: selectInfo.startStr,
    //   end: selectInfo.endStr,
    //   allDay: selectInfo.allDay,
    // });
  };

  const handleEventClick = (clickInfo) => {
    console.log(clickInfo, 'handleEventClick');
  };
  const handleEvents = (clickInfo) => {
    console.log(clickInfo, 'handleEvents');
  };
  console.log(ref);
  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/calender" name={t('Calendar')} />
      </header>
      <div className="bg-white rounded border shadow-md p-4 ">
        <div className="p-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Calendar')}</h2>
            </div>
          </div>
        </div>
        <AddVisit />
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          ref={ref}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay',
          }}
          initialEvents={INITIAL_EVENTS}
          eventContent={renderEventContent}
          eventOverlap={false}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: true,
          }}
          weekends={true}
          editable={false}
          selectable={false}
          selectMirror={false}
          dayMaxEvents={true}
          event
          views={{
            customWeek: {
              type: 'timeline',
              duration: { weeks: 1 },
              slotDuration: { days: 1 },
              buttonText: 'Custom Week',
            },
          }}
          // select={handleDateSelect}
          // eventClick={handleEventClick}
          // eventsSet={handleEvents}
        />
      </div>
    </>
  );
}
