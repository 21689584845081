import { Link } from 'react-router-dom';
import DotsVertical from '../../../SharedComponents/Svg/DotsVertical';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import DeleteFolder from './DeleteFolder';
import EditFolder from './EditFolder';
import folderIcon from '../../../assets/img/folder.svg';
import VolumeUp from '../../../SharedComponents/Svg/VolumeUp';
import DeleteFile from './DeleteFile';
import ViewVideo from './ViewVideo';
import VideoCamera from '../../../SharedComponents/Svg/VideoCamera';
import ViewYoutube from './ViewYoutube';
import { useQuery } from 'react-query';
import { getFolder } from '../ApiAssets';
import EditUploadAsset from '../EditUploadAsset';
import Eye from '../../../SharedComponents/Svg/Eye';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const LinkRow = (props) => {
  const { folderId, languageId, activeTab, assetCollection, setAssetCollection } = props;
  const { i18n } = useTranslation();
  const onChange = (id) => {
    if (assetCollection.includes(id) === false) setAssetCollection([...assetCollection, id]);
    if (assetCollection.includes(id) === true) setAssetCollection(assetCollection.filter((e) => e !== id));
  };
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';
  return (
    <>
      {data?.data?.astAssets.map((link) => {
        return (
          <tr key={link.id} className="">
            <td className="px-6 py-4 whitespace-nowrap">
              {link.astAssetLngs.map((e) => {
                return (
                  <Fragment key={e.id}>
                    {languageId === e.languageId && (
                      <>
                        <div
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <input
                            className=" top-0 left-0 border  h-4 w-4 "
                            type="checkbox"
                            checked={assetCollection.includes(link.id)}
                            onChange={() => onChange(link.id)}
                          ></input>
                          <VideoCamera className=" w-8 h-8" />
                          <b>{e.title}</b>
                        </div>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </td>

            <td
              className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                i18n.language === 'en' ? '' : 'space-x-reverse'
              }`}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={`${link.fileUrl}`}
                className="bg-orange hover:bg-orangehover transition duration-500 text-white rounded p-1"
              >
                <Eye className="w-5 h-5" />
              </a>
              <EditUploadAsset
                // folder={link}
                typeId={activeTab}
                asset={link}
                isLoading={isLoading}
              />
              <DeleteFile id={link.id} isLoading={isLoading} />
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default function LinkTypeContainer(props) {
  // Container for Image Type Style
  const { data, languageId, activeTab, isLoading, assetCollection, setAssetCollection } = props;
  const { t, i18n } = useTranslation();
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];
  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className=" overflow-hidden  sm:rounded-lg">
              <table className="min-w-full divide-y-2 divide-gray-200">
                <thead className=" text-sm font-bold text-black">
                  <tr>
                    {tabltHeader.map((e) => {
                      return (
                        <th
                          key={e.title}
                          scope="col"
                          className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase  ${
                            e.className
                          }`}
                        >
                          {t(e.title)}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                  {data.data.slice(1).map((folder) => {
                    return (
                      <tr key={folder.id} className="">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <Link
                            to={`/assets/${activeTab}/${folder.id}`}
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <img className="inline-block rounded w-8 h-8" alt={folder.name} src={folderIcon} />
                            <b>{folder.name}</b>
                          </Link>
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                            i18n.language === 'en' ? '' : 'space-x-reverse'
                          }`}
                        >
                          <EditFolder folder={folder} isLoading={isLoading} />
                          <DeleteFolder id={folder.id} isLoading={isLoading} />
                        </td>
                      </tr>
                    );
                  })}

                  <LinkRow
                    assetCollection={assetCollection}
                    setAssetCollection={setAssetCollection}
                    languageId={languageId}
                    activeTab={activeTab}
                    folderId={data.data[0]?.id}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
