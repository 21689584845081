import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Notification from '../../SharedComponents/Notification';
import { ConnectFormToOwner } from '../Requests/ApRequests';

export default function ConnectComplainToUser(props) {
  const connectFormToOwnerMutation = useMutation([`GetEnglishName`], ConnectFormToOwner, {
    onError: (data) => {
      console.log('error', data);
      toast(<Notification error message={'error while adding'} />);
    },
    onSuccess: (data) => {
      //   ChangeRequestStatusMutation.mutate({
      //     id: requestId,
      //     requestStatus: 3,
      //   });
    },
  });
  //   connectFormToOwnerMutation.mutate({
  //     formOwner: data.outId,
  //     submitId: userData.submitId,
  //   });

  return 'ssss';
}
