import { ChartBarIcon } from '@heroicons/react/solid';
import { Helmet } from 'react-helmet';
import ConsultingCounts from './components/ConsultingCounts';
import CountByFormId from './components/CountByFormId';
import CountByFormIdChart from './components/CountByFormIdChart';
import CountForComplaints from './components/CountForComplaints';
import SuggestionsCount from './components/SuggestionsCount';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';

export default function Statistcs(props) {
  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name="احصائيات" />

        {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
      </header>

      <h1 className="text-2xl font-bold mb-4">طلبات الانضمام</h1>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-6">
        <CountByFormIdChart
          icon={<ChartBarIcon className="w-4 h-4" />}
          name="مقاولي الأفراد - سجل تجاري فردي"
          formId={2}
        />
        <CountByFormIdChart
          icon={<ChartBarIcon className="w-4 h-4" />}
          name="مقاولي الأفراد - سجل تجاري تضامن"
          formId={3}
        />
        <CountByFormIdChart
          icon={<ChartBarIcon className="w-4 h-4" />}
          name="مقاولي الأفراد - سجل تجاري توصية"
          formId={4}
        />
        <CountByFormIdChart
          icon={<ChartBarIcon className="w-4 h-4" />}
          name="مقاولي شركات الأموال - سجل تجاري مساهمة"
          formId={5}
        />
        <CountByFormIdChart
          icon={<ChartBarIcon className="w-4 h-4" />}
          name="مقاولي شركات الأموال - سجل تجاري مسئولية محدودة"
          formId={6}
        />
        <CountByFormIdChart
          icon={<ChartBarIcon className="w-4 h-4" />}
          name="مقاولي شركات الأموال - سجل تجاري توصية بالأسهم"
          formId={6}
        />
      </div>
      <h1 className="text-2xl font-bold mb-4 mt-4">طلبات خاصة</h1>
      <div className="grid grid-cols-2 xl:grid-cols-3 gap-6">
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="توثيق الخارجية" formId={7} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="شهادة بيانات" formId={8} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="الغاء تخصص" formId={9} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="تغيير تخصص" formId={10} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="تجديد عضوية" formId={11} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="سداد تمغة" formId={12} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="رفع فئة" formId={13} />
        <CountByFormId icon={<ChartBarIcon className="w-4 h-4" />} name="اضافة تخصص" formId={14} />
      </div>

      {/* <h1 className="text-2xl font-bold mb-4 mt-4">احصائيات الشكاوي</h1> */}
      <div className="grid grid-cols-1 xl:grid-cols-1 gap-6 mt-4">
        <CountForComplaints icon={<ChartBarIcon className="w-4 h-4" />} name="احصائيات الشكاوي" departmentId={0} />
      </div>
      <h1 className="text-2xl font-bold mb-4 mt-4">احصائيات خاصة</h1>
      <div className="grid grid-cols-2 xl:grid-cols-2 gap-6">
        <ConsultingCounts />
        <SuggestionsCount />
      </div>
    </>
  );
}
