import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Loading from '../../SharedComponents/Loading';
import Notification from '../../SharedComponents/Notification';
import LoadingCircle from '../../SharedComponents/Svg/LoadingCircle';
import { AddAnswerList, ChangeRequestStatus, GetAllForms, GetRequestId } from '../Requests/ApRequests';
import RadioButton from './FormInputs/RadioButton';

import * as Yup from 'yup';
import Select from './FormInputs/Select';
import FormError from '../Requests/FormError';
import { dir } from '../../utils/localisation';

export default function ComplainFormContainer(props) {
  const [val, setVal] = useState({});
  const { t } = useTranslation();
  const { formID, requestStatus, languageId } = props;
  const history = useHistory();
  const [req, setReq] = useState();
  const params = useParams();
  const { requestId, questionType } = params;
  const ChangeRequestStatusMutation = useMutation(ChangeRequestStatus, {
    onSuccess: (data) => {
      history.push(`/request/${requestStatus + 1}/${requestId ? requestId : req}`);
    },
  });
  const getRequestIdMutation = useMutation(GetRequestId, {
    onSuccess: (data) => {
      setReq(data.data.outId);
      addAnswerList(data.data.outId);
      toast(<Notification message={t('Created Successfully')} />);
    },
  });
  const addAnswerListMutation = useMutation(AddAnswerList, {
    onSuccess: (data) => {
      toast(<Notification message={t('Data Saved, Successfully')} />);
      const answerId = data.data[0];
      // console.log('en', answerId);
      // ChangeRequestStatusMutation
      history.push(
        `/complaintsRequest/${questionType}/${answerId}/${requestStatus + 1}/${requestId ? requestId : req}`
      );

      //
      // ChangeRequestStatusMutation.mutate({
      //   id: requestId ? requestId : req,
      //   requestStatus: 1,
      // });
    },
  });

  const inputs = {
    1: 'text',
    2: 'radio',
    3: 'checkbox',
    4: 'file',
    5: 'tel',
    6: 'url',
    7: 'number',
    8: 'select',
    9: 'textarea',
    10: 'date',
  };

  const body = {
    formId: formID,
  };

  const { data, isLoading, isError } = useQuery(['GetAllForms', body], GetAllForms);
  // useEffect(() => {}, [isEdit]);
  if (isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError) return 'error';

  const getInitialValues = () => {
    const arrayOfKeys = data.data.map((item) => item.inputName);
    const object = {};

    for (let index = 0; index < arrayOfKeys.length; index++) {
      const key = arrayOfKeys[index];
      object[key] = '';
    }
    return object;
  };
  const getDateHandler = (date, time) => {
    const day = date ? new Date(date) : new Date();
    return `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
  };

  const initialValues = getInitialValues();
  // const initialValuesv2 = getInitialValuesWithData();
  console.log('initialValuesv2', initialValues);
  const addAnswerList = (outId) => {
    const userData = localStorage.getItem('token');
    const userId = userData ? JSON.parse(userData).userId : 0;
    const entries = Object.entries(val);
    const newArr = entries?.map((e) => e);
    const newData = newArr.map((x, index) => {
      const questionId = data.data.find((e) => e.inputName === `${x[0]}`).id;
      let l = {
        questionId: questionId,
        answer: x[1],
        userId: userId,
        formId: formID,
        creationDateString: getDateHandler(),
        createDate: new Date(),
        RequestDataId: outId,
        requestData: {
          requestStatus: 1,
        },
      };
      return l;
    });
    console.log(newData, 'newData');
    addAnswerListMutation.mutate(newData);
  };

  const SubmitHandler = (values) => {
    setVal(values);
    console.log('requestId', requestId);
    if (requestId) {
      console.log('we have request ID ');
      addAnswerList(requestId);
    } else {
      setTimeout(() => {
        console.log('NO  request ID ');
        getRequestIdMutation.mutate();
      }, 100);
    }
    // setTimeout(() => {
    //   if (requestId) {
    //   } else {
    //     console.log('xx', requestId);
    //   }
    // }, 100);
  };

  // to loop over data and add Yup validation
  let initSchema = {};
  const keys = Object.keys(initialValues);
  keys.forEach((key, index) => {
    initSchema = {
      ...initSchema,
      [key]: Yup.string()
        .min(2, '')
        .max(500, '')
        .required(JSON.stringify({ en: 'This Field is Required', ar: 'هذا الحقل مطلوب' })),
    };
    delete initSchema.visitors;
  });

  return (
    <>
      <Formik
        validationSchema={Yup.object().shape(initSchema)}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        initialValues={initialValues}
        onSubmit={SubmitHandler}
      >
        {({ values, setFieldValue, errors }) => (
          <Form className="grid grid-cols-2  relative gap-6  px-6 py-6 text-gray-600 font-bold  ">
            {data.data.map((item, index) => {
              if (inputs[item.qtypeId] === 'textarea') {
                return (
                  <label key={index} className={`flex  flex-col  justify-start space-y-2  ${item.cssClass}`}>
                    <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                    <Field
                      name={item?.inputName}
                      value={values[item?.inputName]}
                      className="border rounded py-2 px-3"
                      type={inputs[item.qtypeId]}
                      as="textarea"
                      onChange={(e) => {
                        setFieldValue(item?.inputName, e.target.value);
                      }}
                    />
                    <FormError name={item?.inputName} errors={errors} />
                  </label>
                );
              } else if (inputs[item.qtypeId] === 'select') {
                return (
                  <Select
                    languageId={languageId}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    item={item}
                  />
                );
              } else if (inputs[item.qtypeId] === 'radio') {
                return <RadioButton errors={errors} item={item} languageId={languageId} />;
              } else if (inputs[item.qtypeId] === 'radio') {
                return <RadioButton errors={errors} item={item} languageId={languageId} />;
              } else if (inputs[item.qtypeId] === 'checkbox') {
                return (
                  <label key={index} className={`flex  flex-col justify-start space-y-2`}>
                    <div className={`flex items-center space-x-3 ${dir('', 'space-x-reverse')} `}>
                      <Field
                        name={item?.inputName}
                        value={values[item?.inputName]}
                        className="border rounded py-2 px-3"
                        type={inputs[item.qtypeId]}
                        checked={values[item?.inputName]}
                        onChange={(e) => {
                          if (inputs[item.qtypeId] === 'checkbox') {
                            setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                          } else {
                            setFieldValue(item?.inputName, e.target.value);
                          }
                        }}
                      />
                      <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                    </div>
                  </label>
                );
              }
              return (
                <label key={index} className={`flex  flex-col justify-start space-y-2 ${item.cssClass} `}>
                  <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                  <Field
                    name={item?.inputName}
                    value={values[item?.inputName]}
                    className="border rounded py-2 px-3"
                    type={inputs[item.qtypeId]}
                    checked={values[item?.inputName]}
                    onChange={(e) => {
                      if (inputs[item.qtypeId] === 'checkbox') {
                        setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                      } else {
                        setFieldValue(item?.inputName, e.target.value);
                      }
                    }}
                  />
                  <FormError name={item?.inputName} errors={errors} />
                </label>
              );
            })}
            <div className="col-span-2">
              <button
                type="submit"
                className="bg-darkblue w-24 flex justify-center items-center hover:bg-darkbluehover text-white px-4 py-2 rounded"
              >
                {t('Next')}
                {addAnswerListMutation.isLoading || (getRequestIdMutation.isLoading && <LoadingCircle />)}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
