import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import Print from '../../SharedComponents/Svg/Print';
import { dir } from '../../utils/localisation';
import { GetAllAnswersByFrmQoptionTypeId } from './ApiAnswers';
import { DateTime } from 'luxon';
import Eye from '../../SharedComponents/Svg/Eye';
import { Link } from 'react-router-dom';

const TableComponentToPrint = React.forwardRef((props, ref) => {
  const { languageId, data } = props;
  const { t, i18n } = useTranslation();

  const Approved = () => {
    return (
      <span className="text-[#28a745] border-[#28a745] border rounded-md text-sm py-2 px-4 font-normal">
        {t('Approved')}
      </span>
    );
  };
  const UnderRevision = () => {
    return (
      <span className="text-blue-500 border-blue-500 border rounded-md text-sm py-2 px-4 font-normal">
        {t('Under Revision')}
      </span>
    );
  };
  const Denied = () => {
    return (
      <span className="text-red-500 border-red-500 border rounded-md text-sm py-2 px-4 font-normal">{t('Denied')}</span>
    );
  };
  const Finished = () => {
    return (
      <span className="text-[#28a745] border-[#28a745] border rounded-md text-sm py-2 px-4 font-normal">
        {t('Finished')}
      </span>
    );
  };
  const complainStatus = {
    1: <Approved />,
    2: <Finished />,
    3: <Denied />,
    4: <UnderRevision />,
  };
  const tableHeaderen = [
    { title: t('Number') },
    { title: t('Complaint Title') },
    { title: t('Date') },
    { title: t('Details') },
  ];
  const tableHeaderar = [
    { title: t('ID') },
    { title: t('User name') },
    { title: t('Subject') },
    { title: t('Details') },
  ];

  const tableAnswers = [
    { questionId: 1050, field: 'answer' }, // title
    // { questionId: 1053, field: 'answer' }, // current residence
    // { questionId: 1054, field: 'answer' }, // file
    // { questionId: 1055, field: 'answer' }, // subject
    // { questionId: 1056, field: 'answer' }, // complaint hidden
    // { questionId: 1051, field: 'answer' },
  ];

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" ref={ref}>
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y-2 divide-gray-200">
            <thead className=" text-sm font-bold text-black">
              <tr>
                {languageId === 1 &&
                  tableHeaderar.map((e) => {
                    return (
                      <th
                        key={e.title}
                        scope="col"
                        className={`px-6 py-3 ${
                          i18n.language === 'en' ? 'text-left' : 'text-right'
                        }    uppercase font-bold text-sm ${e.className}`}
                      >
                        {e.title}
                      </th>
                    );
                  })}
                {languageId === 2 &&
                  tableHeaderen.map((e) => {
                    return (
                      <th
                        key={e.title}
                        scope="col"
                        className={`px-6 py-3 ${
                          i18n.language === 'en' ? 'text-left' : 'text-right'
                        }    uppercase font-bold text-sm ${e.className}`}
                      >
                        {e.title}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
              {data.data.map((item) => {
                return (
                  <tr key={item.id} className="">
                    <td className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir('text-left', 'text-right')}`}>
                      {item.submitId}
                    </td>
                    {tableAnswers.map((tableAnswer) => {
                      return (
                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          {item.answer.filter((e) => e.questionId === tableAnswer.questionId)[0][tableAnswer.field]}
                        </td>
                      );
                    })}

                    <td className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir('text-left', 'text-right')}`}>
                      {DateTime.fromISO(item.createDate).toFormat('dd LLL yyyy')}
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir('text-left', 'text-right')}`}>
                      {complainStatus[item.submitionStatus] ? complainStatus[item.submitionStatus] : <UnderRevision />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    // <table className="min-w-full divide-y-2 divide-gray-200" ref={ref}>
    //   <thead className=" text-sm font-bold text-black">
    //     <tr>
    //       {tableHeader.map((e) => {
    //         return (
    //           <th
    //             key={e.title}
    //             scope="col"
    //             className={`px-6 py-3 ${
    //               i18n.language === 'en' ? 'text-left' : 'text-right'
    //             }    uppercase font-bold text-sm ${e.className}`}
    //           >
    //             {e.title}
    //           </th>
    //         );
    //       })}
    //     </tr>
    //   </thead>
    //   <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
    //     {data.data.map((item) => {
    //       return (
    //         <tr key={item.id} className="">
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1046)[0].formOwner}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1029)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1030)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1035)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1031)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1032)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1033)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1034)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1036)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1037)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1038)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1040)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1041)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1042)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1043)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1044)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1046)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1048)[0]?.answer}
    //           </td>
    //           <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
    //             {item.answer.filter((e) => e.questionId === 1045)[0]?.answer}
    //           </td>
    //         </tr>
    //       );
    //     })}
    //   </tbody>
    // </table>
  );
});
export default function DownloadPdf(props) {
  const { languageId, data } = props;
  const { i18n, t } = useTranslation();
  const componentRef = useRef();
  const pageStyle = `
    @page {
      size: 980mm 350mm;
    }
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  return (
    <button
      onClick={handlePrint}
      className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${
        i18n.language === 'en' ? '' : 'space-x-reverse'
      }`}
    >
      <div className="hidden">
        <TableComponentToPrint data={data} ref={componentRef} languageId={languageId} />
      </div>

      <Print />
      <span>{t('Print')}</span>
    </button>
  );
}
