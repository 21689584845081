import React, { useEffect, useState } from 'react';
import usePermission from './usePermission';

export default function PermissionHolder(props) {
  const { permission } = props;

  const userPermissions = usePermission();
  let userPermissionsArray = [];
  if (typeof userPermissions === 'string') {
    userPermissionsArray = [userPermissions.toLowerCase()];
  } else {
    userPermissionsArray = userPermissions?.map((e) => e.toLowerCase());
  }

  return <>{userPermissionsArray.includes(permission?.toLowerCase()) ? props.children : null}</>;
  // return <>{props.children}</>;
}

export const permissionFunction = (permission, isTrue, isFalse) => {
  const userData = localStorage?.getItem('token');
  const user = JSON?.parse(userData);

  return user?.role?.permission?.toLowerCase().includes(permission?.toLowerCase()) ? isTrue : isFalse;
};
