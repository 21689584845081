import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DeleteFolder from './DeleteFolder';
import EditFolder from './EditFolder';
import folderIcon from '../../../assets/img/folder.svg';
import excel from '../../../assets/img/excel.svg';
import pdf from '../../../assets/img/pdf.svg';
import word from '../../../assets/img/word.svg';
import powerPoint from '../../../assets/img/powerPoint.svg';
import DeleteFile from './DeleteFile';
import DownloadDocument from '../../../SharedComponents/Svg/DownloadDocument';
import EditUploadAsset from '../EditUploadAsset';
import { Fragment } from 'react';
import Loading from '../../../SharedComponents/Loading';
import { getFolder } from '../ApiAssets';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const FileRow = (props) => {
  const { folderId, languageId, activeTab, assetCollection, setAssetCollection } = props;
  const { i18n } = useTranslation();
  const onChange = (id) => {
    if (assetCollection.includes(id) === false) setAssetCollection([...assetCollection, id]);
    if (assetCollection.includes(id) === true) setAssetCollection(assetCollection.filter((e) => e !== id));
  };
  const { data, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading)
    return (
      <tr>
        <td></td>
      </tr>
    );
  if (isError) return '';

  return (
    <>
      {data?.data?.astAssets.map((document) => {
        return (
          <tr key={document.id} className="">
            <td className="px-6 py-4 whitespace-nowrap">
              {document.astAssetLngs.map((e) => {
                return (
                  <Fragment key={e.id}>
                    {languageId === e.languageId && (
                      <>
                        <div
                          key={e.id}
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <input
                            className=" top-0 left-0 border  h-4 w-4 "
                            type="checkbox"
                            checked={assetCollection.includes(document.id)}
                            onChange={() => onChange(document.id)}
                          ></input>
                          {document.fileUrl.split('.').pop() === 'pdf' && <img src={pdf} alt="pdf" />}
                          {document.fileUrl.split('.').pop() === 'xlsx' && <img src={excel} alt="pdf" />}
                          {document.fileUrl.split('.').pop() === 'doc' && <img src={word} alt="pdf" />}
                          {document.fileUrl.split('.').pop() === 'ppt' && <img src={powerPoint} alt="pdf" />}

                          <b>{e.title}</b>
                        </div>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </td>

            <td
              className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                i18n.language === 'en' ? '' : 'space-x-reverse'
              }`}
            >
              <EditUploadAsset
                // folder={document}
                typeId={activeTab}
                asset={document}
                isLoading={isLoading}
              />

              <a
                target="_blank"
                rel="noreferrer"
                href={`${window.env.REACT_APP_IMAGE_PATH}${document.fileUrl}`}
                className="bg-indigo-100 text-indigo-800 hover:bg-indigo-200  p-1 rounded transition duration-300 ease-in-out"
              >
                <DownloadDocument className="w-5 h-5 " />
              </a>
              <DeleteFile id={document.id} isLoading={isLoading} />
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default function DocumentTypeContainer(props) {
  // Container for Image Type Style
  const { data, languageId, activeTab, isLoading, assetCollection, setAssetCollection } = props;
  const { t, i18n } = useTranslation();
  const tabltHeader = [{ title: 'Title' }, { title: 'Options', className: 'w-64' }];

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className=" overflow-hidden  border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y-2 divide-gray-200">
                <thead className=" text-sm font-bold text-black">
                  <tr>
                    {tabltHeader.map((e, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase  ${
                            e.className
                          }`}
                        >
                          {t(e.title)}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                  {data?.data?.slice(1).map((folder) => {
                    return (
                      <tr key={folder.id} className="">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <Link
                            to={`/assets/${activeTab}/${folder.id}`}
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <img className="inline-block rounded w-8 h-8" alt={folder.name} src={folderIcon} />
                            <b>{folder.name}</b>
                          </Link>
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap flex items-center space-x-2 ${
                            i18n.language === 'en' ? '' : 'space-x-reverse'
                          }`}
                        >
                          <EditFolder folder={folder} isLoading={isLoading} />
                          <DeleteFolder id={folder.id} isLoading={isLoading} />
                        </td>
                      </tr>
                    );
                  })}
                  <FileRow
                    assetCollection={assetCollection}
                    setAssetCollection={setAssetCollection}
                    languageId={languageId}
                    activeTab={activeTab}
                    folderId={data.data[0].id}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
