import React from 'react';
import { useTranslation } from 'react-i18next';
import Excel from '../../SharedComponents/Svg/Excel';
import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import { GetAllAnswersByFrmQoptionTypeId } from './ApiAnswers';
import { useParams } from 'react-router';

export default function DownloadExcel(props) {
  const { fileHeaders, fileName, fileType, title, languageId } = props;
  const { t, i18n } = useTranslation();
  const { qOptionName, qOptionId } = useParams();

  const initialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    isFilter: false,
    langId: 1,
    isSearch: false,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: languageId,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: parseInt(qOptionId),
  };

  const { data, isLoading, isError } = useQuery(['formAnswer', initialState], GetAllAnswersByFrmQoptionTypeId, {
    onSuccess: ({ data }) => {
      //   setNumberOfPages(uniqBySubmitId(data, (it) => it.submitId).length / entries);
    },
  });
  if (isLoading) return '';

  const tabltHeader = [
    { label: t('ID'), key: 'id' },
    { label: t('En Name'), key: 'enName' },
    { label: t('Ar Name'), key: 'arName' },
    { label: t('Mobile No.'), key: 'mobile' },
    { label: t('ID NO.'), key: 'idNo' },
    { label: t('Reson Of Visit'), key: 'reson' },
    { label: t('Date of Birth'), key: 'dateOfBirth' },
    { label: t('Age Group'), key: 'ageGroup' },
    { label: t('Gender'), key: 'gender' },
    { label: t('Alternative Phone'), key: 'alternativePhone' },
    { label: t('Email'), key: 'email' },
    { label: t('Date of Visit'), key: 'dateOfVisit' },
    { label: t('Reason of Visit'), key: 'reasonOfVisit' },
    { label: t('Registration Method'), key: 'registrationMethod' },
    { label: t('Location'), key: 'location' },
    { label: t('General information session'), key: 'generalInformationSession' },
    { label: t('General information date'), key: 'generalinfodate' },
    { label: t('Group advisory session'), key: 'groupAdvisorySession' },
    { label: t('Visitors'), key: 'visitors' },
    { label: t('Governorate'), key: 'governorate' },
    { label: t('Date'), key: 'date' },
  ];

  console.log(data.data);
  const tabltdata = data.data.map((item) => {
    return {
      id: item.answer.filter((e) => e.questionId === 1046)[0]?.formOwner,
      enName: item.answer.filter((e) => e.questionId === 1029)[0]?.answer,
      arName: item.answer.filter((e) => e.questionId === 1030)[0]?.answer,
      mobile: item.answer.filter((e) => e.questionId === 1035)[0]?.answer,
      idNo: item.answer.filter((e) => e.questionId === 1031)[0]?.answer,
      reson: item.answer.filter((e) => e.questionId === 1039)[0]?.answer,
      dateOfBirth: item.answer.filter((e) => e.questionId === 1032)[0]?.answer,
      ageGroup: item.answer.filter((e) => e.questionId === 1033)[0]?.answer,
      gender: item.answer.filter((e) => e.questionId === 1034)[0]?.answer,
      alternativePhone: item.answer.filter((e) => e.questionId === 1036)[0]?.answer,
      email: item.answer.filter((e) => e.questionId === 1037)[0]?.answer,
      dateOfVisit: item.answer.filter((e) => e.questionId === 1038)[0]?.answer,
      reasonOfVisit: item.answer.filter((e) => e.questionId === 1039)[0]?.answer,
      registrationMethod: item.answer.filter((e) => e.questionId === 1040)[0]?.answer,
      location: item.answer.filter((e) => e.questionId === 1041)[0]?.answer,
      generalInformationSession: item.answer.filter((e) => e.questionId === 1042)[0]?.answer,
      generalinfodate: item.answer.filter((e) => e.questionId === 1043)[0]?.answer,
      groupAdvisorySession: item.answer.filter((e) => e.questionId === 1044)[0]?.answer,
      visitors: item.answer.filter((e) => e.questionId === 1046)[0]?.answer,
      governorate: item.answer.filter((e) => e.questionId === 1048)[0]?.answer,
      date: item.answer.filter((e) => e.questionId === 1045)[0]?.answer,
    };
  });
  const csvReport = {
    data: tabltdata,
    headers: tabltHeader,
    filename: 'visitors.csv',
  };
  return (
    <CSVLink {...csvReport}>
      <span
        className={`py-2 rounded px-5 flex text-white  bg-lightgreen space-x-2 items-center text-sm font-bold ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        }`}
      >
        <Excel />
        <span>{t('Excel')}</span>
      </span>
    </CSVLink>
  );
}
