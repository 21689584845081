import axios from 'axios';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import LanguageList from '../../../SharedComponents/LanguageList';
import Modal from '../../../SharedComponents/Modal';
import Notification from '../../../SharedComponents/Notification';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { dir } from '../../../utils/localisation';
import UsersList from './UsersList';

export default function AddNotificationToaUser(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${dir(
          '',
          'space-x-reverse'
        )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New')}</span>
      </button>

      {isOpen && <ModalContainer isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();

  let getUsersinitState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: false,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
  };

  const getUsersQuery = useQuery(['getUsers', getUsersinitState], getUsers, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // Query Data To Fetch Languages

  const initialValues = {
    userId: null,
    notificationTypeId: null,
    mute: false,
  };
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(AddNotificationsTypeUser, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['GetNotificationsTypeAllUser']);
        toast(<Notification message={t('Added, Successfully')} />);
        setIsOpen(false);
      }
    },
  });

  const onSubmit = async (e) => {
    try {
      await mutation.mutate(e);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        // validationSchema={Yup.object().shape({
        //   // frmQoptionLngs: Yup.array(Yup.object())
        //   //   .length(1)
        //   //   .required(JSON.stringify({ en: 'This Field Is Required', ar: 'هذا الحقل مطلوب' }))
        //   //   .max(500, ''),
        //   // frmQoptionLngs: Yup.string().required().max(500, '')
        //   frmQoptionLngs: Yup.array().of(
        //     Yup.object().shape({
        //       title: Yup.string().required().max(500, ''),
        //     })
        //   ),
        // })}
        initialValues={initialValues}
      >
        {({ values, setFieldValue, errors, touched }) => {
          console.log('x', values);
          return (
            <Form>
              <Modal isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={onSubmit} title={t('Add Notification')} add={true}>
                <LanguageList
                  className="flex transform translate-y-1 relative z-10 mb-4"
                  languageId={languageId}
                  setLanguageId={setLanguageId}
                >
                  <hr className="border w-full absolute bottom-0 left-0 z-0" />
                </LanguageList>

                {/* Submit Form */}

                {errors && touched.frmQoptionLngs && (
                  <span className={`${dir('text-left', 'text-right')} pt-1 text-xs font-base text-red-500`}>
                    {dir('All Languages are Required', 'برجاء إضافة بيانات جميع اللغات')}
                  </span>
                )}
                {getUsersQuery.data && (
                  <UsersList
                    // setBranchId={setBranchId}
                    loading={getUsersQuery.isLoading}
                    error={getUsersQuery.isError}
                    list={getUsersQuery?.data?.data}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    FieldValue={values.userId}
                    FieldName="userId"
                  />
                )}
                <UsersList
                  // setBranchId={setBranchId}
                  loading={getUsersQuery.isLoading}
                  error={getUsersQuery.isError}
                  label={t('Notification Type')}
                  list={[
                    {
                      id: 1,
                      userName: t('Add Reply'),
                    },
                    {
                      id: 2,
                      userName: t('Change Request'),
                    },
                  ]}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  FieldValue={values.notificationTypeId}
                  FieldName="notificationTypeId"
                />
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const getUsers = async (e) => {
  const query = await axios.post('/UserAccount/GetAllUsers', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const AddNotificationsTypeUser = async (e) => {
  const { data } = await axios.post('/Notifications/AddNotificationsTypeUser', e);
  return data;
};
