import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import AddComplaintDepartment from './components/AddComlaintDepartment';
import DeleteComplaintDepartment from './components/DeleteComplaintDepartment';
import EditComplaintDepartment from './components/EditComplaintDepartment';

export default function ComplaintDepartments(props) {
  const { t } = useTranslation();
  const initialQuery = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
  };
  const fetchcomplaintDepartments = async (e) => {
    const { data } = await axios.post(`/ComplainDepartment/GetAll`, e.queryKey[1]);
    return data;
  };

  const { status, data, error, refetch, isLoading, isError } = useQuery(
    ['complaintDepartments', initialQuery],
    fetchcomplaintDepartments
  );

  if (isLoading) return '';
  if (isError) return '';

  const dataColumns = [
    {
      Header: 'الاسم',
      accessor: 'name',
    },
    {
      Header: 'خيارات',
      accessor: '#',
      Cell: ({ cell }) => {
        return (
          <div className="flex space-x-2 rtl:space-x-reverse">
            <div>
              <EditComplaintDepartment info={cell.row.original} />
            </div>
            <div>
              <DeleteComplaintDepartment infoId={cell.row.original.id} />
            </div>
          </div>
        );
      },
    },
  ];
  console.log('data', data);
  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('complaintDepartments')} />

          <div
            className={`flex items-center space-x-3 
          `}
          >
            <AddComplaintDepartment />
          </div>
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('complaintDepartments')}</h2>
        </div>

        <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
      </div>
    </>
  );
}
