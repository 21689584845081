import Home from '../../SharedComponents/Svg/Home';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import Photograph from '../../SharedComponents/Svg/Photograph';
import key from '../../assets/img/key.svg';
import DotsVertical from '../../SharedComponents/Svg/DotsVertical';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import Trash from '../../SharedComponents/Svg/Trash';
import Eye from '../../SharedComponents/Svg/Eye';
import { useEffect, useState } from 'react';
import ViewGrid from '../../SharedComponents/Svg/ViewGrid';
import block from '../../../src/assets/img/block.svg';
import list from '../../../src/assets/img/list.svg';
import testimg from '../../../src/assets/img/testimg.jpg';
import Calender from '../../SharedComponents/Svg/Calender';
import UploadAsset from './UploadAsset';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../SharedComponents/Loading';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteAsset, getFolder, getLanguages } from './ApiAssets';
import AssetsStyleList from './Components/AssetsStyleList';
import AssetStyleBlock from './Components/AssetsStyleBlock';
import VideoCamera from '../../SharedComponents/Svg/VideoCamera';
import Microphone from '../../SharedComponents/Svg/Microphone';
import LinkIcon from '../../SharedComponents/Svg/LinkIcon';
import Document from '../../SharedComponents/Svg/Document';
import youtube from '../../assets/img/youtube.svg';

import vimeo from '../../assets/img/vimeo.svg';
import EditUploadAsset from './EditUploadAsset';
import DeleteFile from './Components/DeleteFile';
import VolumeUp from '../../SharedComponents/Svg/VolumeUp';
import { AudioRow, FileRow, LinkRow, VideoRow, VimeoRow, YoutubeRow } from './AssetsCategoryBlocks';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../SharedComponents/LanguageList';

export default function AssetsCategory() {
  const [languageId, setLanguageId] = useState(1);

  // Handle Multiple Language Content in the Page
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  // entries to control pageSize
  const [entries, setEntries] = useState(10);
  // pageNumber to Control pageNumber
  const [pageNumber, setPageNumber] = useState(1);
  // initial state for APi call
  const params = useParams();
  const { id, folderId } = params;
  const [activeTab, setActiveTab] = useState(parseInt(params.activeTab));
  // Query to load Data
  const { data, isDone, isLoading, isError } = useQuery(['assetFolder', folderId], getFolder, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const { t, i18n } = useTranslation();
  const [assetsStyle, setAssetsStyle] = useState(0);
  // query to Load Images

  const tabData = [
    {
      svg: <Photograph />,
      title: t('Images'),
    },
    {
      svg: <Microphone />,
      title: t('Audio'),
    },
    {
      svg: <VideoCamera />,
      title: t('Video'),
    },
    {
      svg: <img src={youtube} alt="youtube" />,
      title: t('Youtube'),
    },
    {
      svg: <img src={vimeo} alt="youtube" />,
      title: t('Vimeo'),
    },
    {
      svg: <LinkIcon />,
      title: t('Link'),
    },
    {
      svg: <Document />,
      title: t('Document'),
    },
  ];
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <div className="flex items-center bg-white rounded border shadow font-bold text-gray-700">
          <div className="pl-4 pr-12 py-3 relative overflow-hidden">
            <a href="/">
              <Home />
            </a>
            <div className={`text-gray-100 ${i18n.language === 'en' ? '' : 'hidden'}`}>
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-2" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-2" />
            </div>
          </div>
          <div className="pl-4 pr-12 py-3 relative overflow-hidden">
            <a href="/dashboard">{t('Dashboard')}</a>
            <div className="text-gray-100">
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? '-rotate-45 right-0' : 'rotate-45 -right-3'
                }    bottom-0 mb-2`}
              />
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? 'rotate-45 right-0' : '-rotate-45 -right-3'
                } top-0 mt-2`}
              />{' '}
            </div>
          </div>
          <div className="pl-4 pr-12 py-3 relative overflow-hidden">
            <Link to="/assets">{t('Assets')}</Link>
            <div className={`text-gray-100 ${i18n.language === 'en' ? 'hidden' : ''}`}>
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? '-rotate-45 right-0' : 'rotate-45 -right-3'
                }    bottom-0 mb-2`}
              />
              <hr
                className={`w-11 border text-gray-50 border-gray-50 absolute transform ${
                  i18n.language === 'en' ? 'rotate-45 right-0' : '-rotate-45 -right-3'
                } top-0 mt-2`}
              />
            </div>
          </div>
        </div>
        <div>
          {/* <AddUser /> */}
          <UploadAsset typeId={activeTab} id={folderId} />
        </div>
      </header>
      <div className="bg-white rounded border shadow-md p-4 ">
        <div className="flex justify-between relative items-center mb-4">
          <div className="flex transform  relative z-10 pb-6">
            {tabData.map((e, index) => {
              return (
                <Link
                  to={`/assets/${index + 1}`}
                  key={index}
                  className={` ${
                    activeTab === index + 1 ? 'text-pagetabhover border-pagetabhover' : ''
                  } flex justify-center items-center ${
                    i18n.language === 'en' ? '' : 'space-x-reverse'
                  } space-x-2 text-gray-500   hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                  onClick={(e) => setActiveTab(index + 1)}
                >
                  {e.svg}
                  <span>{e.title}</span>
                </Link>
              );
            })}
          </div>
          <LanguageList
            className="flex transform  relative z-10 "
            languages={languages.data?.data}
            languageId={languageId}
            setLanguageId={setLanguageId}
          />

          <hr className="border w-full absolute top-0 left-0 z-0 mt-10" />
        </div>
        {activeTab === 1 && (
          <>
            <div className="flex items-center justify-between">
              <div>
                <div className={`flex items-center space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                  <div
                    className={`flex items-center space-x-2 text-gray-600 ${
                      i18n.language === 'en' ? '' : 'space-x-reverse'
                    }`}
                  >
                    <span>{t('Show')} </span>
                    <select
                      className="border rounded w-20 py-2 px-1"
                      // onChange={onChangeEntries}
                    >
                      <option value="10">10 </option>
                      <option value="20">20 </option>
                      <option value="30">30 </option>
                    </select>{' '}
                    <span>{t('entries')}</span>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setAssetsStyle(0);
                      }}
                      className={`${
                        assetsStyle === 0 ? 'bg-gray-200 z-10 shadow' : 'bg-white'
                      } border-gray-400 relative focus:outline-none   border p-3 ${
                        i18n.language === 'en' ? 'rounded-tl rounded-bl' : 'rounded-tr rounded-br'
                      } `}
                    >
                      <img alt="test" style={{ minHeight: 18, maxHeight: 18 }} src={block} />
                    </button>
                    <button
                      onClick={() => {
                        setAssetsStyle(1);
                      }}
                      className={`${
                        assetsStyle === 1 ? 'bg-gray-200 z-10 shadow' : 'bg-white'
                      } border-gray-400 transform focus:outline-none -translate-x-1  bg-white relative z-0 ${
                        i18n.language === 'en' ? 'rounded-tr rounded-br' : 'rounded-tl rounded-bl'
                      } border p-3 `}
                    >
                      <img alt="test" style={{ minHeight: 18, maxHeight: 18 }} src={list} />
                    </button>
                  </div>
                  <div>
                    <div className="flex items-center space-x-4 text-gray-600">
                      {/* <span>Move To </span>
                      <select className='border rounded w-64 py-2 px-1'>
                        <option value='Select Folder'>Select Folder </option>
                        <option value='Movies'>Movies </option>
                        <option value='Pictures'>30 </option>
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center space-x-4 text-gray-600">
                  {/* <span>Search </span>
                  <input className='border rounded w-64 py-2 px-1' /> */}
                </div>
              </div>
            </div>
            <div className={`${assetsStyle === 0 ? 'grid grid-cols-3 gap-8' : 'flex flex-col  divide-y'}  my-8`}>
              {data.data.astAssets.map((e, index) => {
                return (
                  (assetsStyle === 0 && <AssetStyleBlock languageId={languageId} asset={e} key={e.name} />) || (
                    <AssetsStyleList languageId={languageId} key={e.id} asset={e} />
                  )
                );
              })}
            </div>
          </>
        )}
        {activeTab === 2 && <AudioRow languageId={languageId} activeTab={activeTab} folderId={folderId} />}
        {activeTab === 3 && <VideoRow languageId={languageId} activeTab={activeTab} folderId={folderId} />}
        {activeTab === 4 && <YoutubeRow languageId={languageId} activeTab={activeTab} folderId={folderId} />}
        {activeTab === 5 && <VimeoRow languageId={languageId} activeTab={activeTab} folderId={folderId} />}
        {activeTab === 6 && <LinkRow languageId={languageId} activeTab={activeTab} folderId={folderId} />}
        {activeTab === 7 && <FileRow languageId={languageId} activeTab={activeTab} folderId={folderId} />}
      </div>
    </>
  );
}
