import { ErrorMessage, Field } from 'formik';
/**
 *
 *
 * @export
 * @param {*} { name, icon, placeHolder, errors, required , label, type }
 * @returns
 */
export default function Text(props) {
  const { name, icon, placeHolder, errors, required, label, wrapperClass, className, inputClassName, disabled } = props;
  const isError = errors ? (errors[name] ? true : false) : false;
  const inputType = props.type ? props.type : 'text';
  return (
    <div className={`mt-4 ${wrapperClass}`}>
      {label && (
        <label className="mb-1 block rtl:text-right">
          {' '}
          <span>{label}</span> {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className={`${isError ? 'border-red-500 text-red' : ''} ${icon ? 'flex' : ''} border ${className}`}>
        {icon && <div className="inline-flex items-center px-2 border-l">{icon}</div>}
        <Field
          disabled={disabled}
          type={inputType}
          name={name}
          placeholder={placeHolder}
          autoComplete="username"
          className={`flex-1 block w-full py-2 px-2 rtl:text-right outline-none ${inputClassName}`}
        />
      </div>
      <ErrorMessage
        render={(msg) => <div className="text-red-500 text-xs rtl:text-right  mt-2">{msg}</div>}
        name={name}
      />
    </div>
  );
}
