// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Users: 'المستخدمين',
  'Add User': 'إضافة مستخدم',
  'Edit User': 'تعديل مستخدم',
  'Enter Email': 'برجاء إدخال البريد الإلكتروني',
  'Enter Phone': 'برجاء إدخال رقم التليفون',
  'Enter your new password': 'برجاء إدخال كلمة السر',
  name: 'اسم',
  email: 'البريد الإلكتروني',
  phone: 'هاتف',
  created: 'تاريخ الإنشاء',
  lastlogin: 'آخر تسجيل دخول',
  state: 'حالة',
  Role: 'الصلاحيات',
  options: 'الخيارات',
  'User Status, Updated': 'تم تحديث حالة المستخدم بنجاح',
  'User full name': 'اسم المستخدم الكامل',
  'User name': 'اسم المستخدم',
  'User role': 'المستخدم صلاحيات',
  'Add / Edit User': 'إضافة / تحرير المستخدم',
  'Add / Edit': 'إضافة / تحرير',
  'user Added': 'تمت إضافة المستخدم',
};
