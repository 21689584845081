import { CheckCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import Button from '../../SharedComponents/Button/Button';
import { fromselectList, serviceselectList } from './talabatlists';
import * as Yup from 'yup';
import PageLoading from '../../SharedComponents/Loading/PageLoading';
import SelectV2 from '../../SharedComponents/Inputs/components/SelectV2';
import { generatepassword } from '../../utils/generatepassword';
import toast from 'react-hot-toast';
import OldMemeberRequest from './OldMemberRequest';
import NewMemeberRequest from './NewMemeberRequest';
import DynamicPrinting from '../../SharedComponents/DynamicPrinting';

export default function NewTalab(props) {
  const componentRef = useRef(null);

  const initialValues = {
    memeberId: '',
  };
  const validationSchema = Yup.object().shape({
    memeberId: Yup.number().required('هذا الحقل مطلوب'),
  });
  const [newRequest, setNewRequest] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [valid, setValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const mutationFunction = async (e) => {
    const { data } = await axios.post(`UserAccount/GetUserByMemberShip?MemberShip=${e.memeberId}`, {});
    return data;
  };

  const generalDataQueries = useQueries([
    { queryKey: ['comingfrom'], queryFn: comingFromFunction },
    { queryKey: ['services'], queryFn: servicesunction },
  ]);
  console.log('generalDataQueries', generalDataQueries);
  const mutation = useMutation(mutationFunction, {
    onSuccess: (data) => {
      if (data.data.length > 0) {
        setErrorMessage();
        setValid(true);
      } else {
        setErrorMessage('لا يوجد عضو مطابق لرقم العضوية المطلوب');
        setValid(false);
      }
    },
  });

  if (generalDataQueries.isLoading) return <PageLoading />;
  if (generalDataQueries.isError) return '';

  const onSubmit = (e) => {
    console.log('ee', e);
    mutation.mutate(e);
  };
  const notificationMethods = [
    { id: 1, title: 'عضو مسجل' },
    { id: 2, title: 'عضو جديد' },
  ];
  return (
    <>
      <BreadCrumbs generalRoute="طلبات" generalRouteTo="/talabat" name="طلب جديد" />
      <div ref={componentRef} dir="rtl" className="p-4 print:mx-8 border shadow rounded-lg bg-white mt-8">
        <div className="border-b pb-4 flex justify-between items-center">
          <span className="font-bold text-xl text-[#003eef]">طلب جديد</span>
          <DynamicPrinting componentRef={componentRef} />
        </div>
        <div className="px-4">
          {!newRequest && (
            <>
              <div className="flex space-x-2 rtl:space-x-reverse mt-4  print:hidden font-bold">
                {notificationMethods.map((notificationMethod) => (
                  <div key={notificationMethod.id} className="flex items-center space-x-2 rtl:space-x-reverse ">
                    <input
                      id={notificationMethod.id}
                      name="notification-method"
                      onClick={() => {
                        setActiveTab(notificationMethod.id);
                      }}
                      type="radio"
                      defaultChecked={notificationMethod.id === 1}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={notificationMethod.id} className="block font-bold ">
                      {notificationMethod.title}
                    </label>
                  </div>
                ))}
              </div>
              {activeTab === 1 && (
                <Formik onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
                  {({ values }) => {
                    return (
                      <Form>
                        <div className="mt-4 flex items-end space-x-2 rtl:space-x-reverse">
                          <div className="w-[25%]">
                            <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                              <span className={`font-bold text-base text-right rtl:text-right `}>
                                ادخال رقم العضوية *
                              </span>
                              <Field name="memeberId" className="border rounded py-2 px-3" type="number" />
                            </label>
                          </div>
                          <div className="inline-block">
                            <Button
                              loading={mutation.isLoading}
                              type="submit"
                              text="بحث"
                              className="px-16 inline-block bg-green-500 hover:bg-green-400"
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          render={(msg) => <div className="text-red-500 text-xs rtl:text-right  mt-2">{msg}</div>}
                          name="memeberId"
                        />
                        {errorMessage && (
                          <div className="mt-4  flex space-x-2 rtl:space-x-reverse  ">
                            <span className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded">
                              {errorMessage}
                            </span>
                          </div>
                        )}
                        {valid && (
                          <div className="mt-4 flex space-x-2 rtl:space-x-reverse  font-bold">
                            <span>تم التعرف على العضوية</span>
                            <button
                              type="button"
                              onClick={() => {
                                setNewRequest(true);
                              }}
                              className="text-[#4f46e5] underline  font-bold"
                            >
                              ادخال بيانات الطلب
                            </button>
                          </div>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
          {activeTab === 2 && <NewMemeberRequest />}

          {newRequest && <OldMemeberRequest data={mutation.data} />}
        </div>
      </div>
    </>
  );
}

const comingFromFunction = async (e) => {
  const noPagination = {
    pageNumber: 1,
    pageSize: 100,
    isPaging: false,
  };
  const { data } = await axios.post(`UserAccount/GetAllCommingFrom`, noPagination);
  return data;
};
const servicesunction = async (e) => {
  const noPagination = {
    pageNumber: 1,
    pageSize: 100,
    isPaging: false,
  };
  const { data } = await axios.post(`UserAccount/GetAllService`, noPagination);
  return data;
};
