import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import * as yup from 'yup';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ClockIcon, EyeIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';

export default function SingleConsult(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { consult } = props;

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-100 text-blue-800 px-4 hover:bg-blue-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <EyeIcon className="h-4 w-4" />
      </button>

      {isOpen && <ModalContainer consult={consult} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, consult } = props;
  const { t, i18n } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // onSubmit={onSubmit}
        title={t('بيانات الاستشارة')}
        add={true}
        hideSubmit={true}
        cancelTitle={'إغلاق'}
      >
        {/* Submit Form */}

        <div>
          <span className="text font-extrabold text-[#26365e] block text-right whitespace-pre-wrap">
            {consult.message}
          </span>
          <div className="flex items-center text-[#62718d] space-x-2 text-sm space-x-reverse">
            <ClockIcon className="h-4 " />
            <span> {DateTime.fromISO(consult.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
          </div>
          {consult.consultingReplies.length > 0 ? (
            <>
              {consult.consultingReplies.map((x, i) => {
                return (
                  <div key={i}>
                    <p className="text mt-4 text-[#666666]  block text-right  whitespace-pre-wrap">{x.reply} </p>
                    <div className="flex items-center text-[#62718d] space-x-2 mt-2 text-sm space-x-reverse">
                      <ClockIcon className="h-4 " />
                      <span>
                        {/* {DateTime.fromISO(x.createDate).to} */}
                        {DateTime.fromISO(x.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
                      </span>
                      <span> | </span>
                      <span> تم الرد بواسطة </span>
                      <span className="text-[#123376] font-bold"> {x.createdByName} </span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <p className="text mt-4 text-[#666666]  block text-right  whitespace-pre-wrap">
                لم يقم احد بالرد على هذا السؤال
              </p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
