import React from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getUsers } from './ApiUser';
import Excel from '../../../SharedComponents/Svg/Excel';

export default function DownloadExcel(props) {
  const { languageId } = props;
  const { t, i18n } = useTranslation();
  let initialState = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
  };

  const { data, isLoading, isError } = useQuery(['users', initialState], getUsers, {
    staleTime: 2000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  if (isLoading) return '';

  const tabltHeader = [
    { label: t('User full name'), key: 'name' },
    { label: t('User name'), key: 'userName' },
    { label: t('email'), key: 'email' },
    { label: t('User role'), key: 'userRole' },
  ];
  const tabltdata = data.data.map((user) => {
    return {
      name: user.fullName,
      userName: user.userName,
      email: user.email,
      userRole: user.roles.length === 0 ? 'Not Assigned' : user.roles.map((role) => role).join(' , '),
    };
  });
  const csvReport = {
    data: tabltdata,
    headers: tabltHeader,
    filename: 'users.csv',
  };
  return (
    <CSVLink {...csvReport}>
      <span
        className={`py-2 rounded px-5 flex text-white  bg-lightgreen space-x-2 items-center text-sm font-bold ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        }`}
      >
        <Excel />
        <span>{t('Excel')}</span>
      </span>
    </CSVLink>
  );
}
