import { ErrorMessage, Field } from 'formik';
import LoadingSpinner from '../../Loading/LoadingSpinner';
/**
 *
 *
 * @export
 * @param {*} { name, icon, placeHolder, errors, required , label, type }
 * @returns
 */
export default function SelectV2(props) {
  const { name, icon, placeHolder, disabled, errors, required, label, wrapperClass, selectList, loading } = props;
  const selectListName = props.selectListName ? props.selectListName : 'name';
  const isError = errors[name] ? true : false;
  const inputType = props.type ? props.type : 'text';
  const valueHolder = props.valueHolder ? props.valueHolder : 'id';
  return (
    <div className={`   mt-4 ${wrapperClass}`}>
      {label && (
        <label className="mb-1 block rtl:text-right">
          {' '}
          <span>{label}</span> {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className={`${isError ? 'border-red-500 text-red' : ''} ${icon ? 'flex' : ''} border`}>
        {icon && <div className="inline-flex items-center px-2 border-l">{icon}</div>}
        {loading ? (
          <div className="flex cursor-not-allowed flex-row relative items-center justify-between  w-full rtl:text-right bg-gray-100 text-gray-400 py-2 px-2 outline-none ">
            <span>جاري تحميل البيانات</span>
            <span className="relative">
              <LoadingSpinner className="mt-0 left-0 relative" />
            </span>
          </div>
        ) : (
          <Field
            component="select"
            name={name}
            disabled={disabled}
            placeholder={placeHolder}
            autoComplete="username"
            className={`flex-1 block w-full py-2 px-2 outline-none ${disabled ? 'bg-gray-100' : ''} `}
          >
            {selectList?.map((e, index) => {
              return (
                <option key={index} value={e[valueHolder]}>
                  {e[selectListName]}
                </option>
              );
            })}
          </Field>
        )}
      </div>
      <ErrorMessage render={(msg) => <div className="text-red-500 text-xs  mt-2">{msg}</div>} name={name} />
    </div>
  );
}
