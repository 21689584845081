import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import Loading from '../../SharedComponents/Loading';
import Notification from '../../SharedComponents/Notification';
import {
  ChangeRequestStatus,
  EditAnswerList,
  GetAllForms,
  GetDataOfRequest,
  getLanguages,
} from '../Requests/ApRequests';
import RadioButton from './FormInputs/RadioButton';
import Select from './FormInputs/Select';
import { getDateHandler } from '../Requests/utils/getDateHandler';
import MessagesLog from './MessagesLog';
import AddMessage from './FormInputs/AddMessage';
import DeclineMessage from './FormInputs/DeclineMessage';
import LanguageList from '../../SharedComponents/LanguageList';
import { dir } from '../../utils/localisation';
import { useNotifications } from '../../context/useNotifications';
import { sendMessage } from '../../utils/sendMessage';

export default function ViewAnswer(props) {
  const { formID, requestId, qOptionName, requestStatus } = useParams();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  // const queryClient = useQueryClient();
  // const languages = queryClient.useQ('languages');
  const { t } = useTranslation();
  const inputs = {
    1: 'text',
    2: 'radio',
    3: 'checkbox',
    4: 'file',
    5: 'tel',
    6: 'url',
    7: 'number',
    8: 'select',
    9: 'textarea',
    10: 'date',
  };

  const connection = useNotifications();
  //   console.log('connection', connection);
  //   sendMessage(connection, 'ssss');
  const [languageId, setLanguageId] = useState(2);
  const steps = [
    { id: 3, title: 'Employee one' },
    { id: 4, title: 'Employee two' },
    { id: 5, title: 'Employee three' },
  ];
  const getSingleDataOfRequest = useQuery(['GetSingleDataOfRequest', { formID, requestId }], GetDataOfRequest, {
    retry: false,
  });
  const { data, isLoading, isError } = useQuery(['GetAllForms', { formId: formID }], GetAllForms, {
    retry: false,
  });
  const ChangeRequestStatusMutation = useMutation(ChangeRequestStatus, {
    onSuccess: (data) => {
      history.push(`/${qOptionName}/${formID}/${requestId}/${parseInt(requestStatus) + 1}`);
    },
  });
  const ChangeRequestStatusMutationDeny = useMutation(ChangeRequestStatus, {
    onSuccess: (data) => {
      history.push(`/${qOptionName}/${formID}/${requestId}/${parseInt(requestStatus) - 1}`);
    },
  });
  const history = useHistory();
  const editAnswerListMutation = useMutation(EditAnswerList, {
    onSuccess: async (data) => {
      await ChangeRequestStatusMutation.mutate({
        id: requestId,
        requestStatus: requestStatus + 1,
      });
      toast(<Notification message={t('Visitor changed, Successfully')} />);
      //   history.push(`/request/${requestStatus + 1}/${requestId ? requestId  req}`);
    },
    onError: () => {
      toast(<Notification message={t("We're sorry, you don't have permission")} error={true} />);
    },
  });

  if (isLoading || getSingleDataOfRequest.isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError || getSingleDataOfRequest.isError) return 'error';

  const getInitialValuesWithData = () => {
    const arrayOfKeys = getSingleDataOfRequest?.data?.data?.map((item) => item.question.inputName);
    const arrayOfAnswers = getSingleDataOfRequest?.data?.data?.map((item) => item.answer);
    const object = {};
    for (let index = 0; index < arrayOfKeys?.length; index++) {
      const key = arrayOfKeys[index];
      object[key] = arrayOfAnswers[index];
    }
    return object;
  };
  const initialValues = getInitialValuesWithData();

  const editAnswerList = (val) => {
    const userData = localStorage.getItem('token');
    const userId = userData ? JSON.parse(userData).userId : 0;
    const entries = Object.entries(val);
    const newArr = entries?.map((e) => e);
    const newData = newArr.map((x, index) => {
      const questionId = data.data.find((e) => e.inputName === `${x[0]}`).id;
      let l = {
        questionId: questionId,
        answer: x[1],
        userId: userId,
        formId: formID,
        creationDateString: getDateHandler(),
        createDate: new Date(),
        RequestDataId: parseInt(requestId),
        requestData: {
          requestStatus: 1,
        },
      };
      return l;
    });
    editAnswerListMutation.mutate(newData);
  };

  const onSubmit = (values) => {
    editAnswerList(values);
  };
  console.log(initialValues);

  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/requests/0" name={t('Review Request')} />
      </header>
      <div className="bg-white mt-4 pt-4 px-6 flex justify-between w-full border-b">
        <h2 className="text-xl font-bold px-4 pb-4">{t('Review Request')}</h2>
        <div className="flex transform  relative z-10 ">
          {languages && (
            <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
          )}
        </div>
      </div>
      <div className="bg-white py-4">
        <div className="flex space-x-12 mx-6 border-b">
          {steps.map((step, index) => {
            return (
              <div className={`flex  relative py-4 px-2`}>
                <span
                  className={` ${
                    step.id <= requestStatus ? 'bg-move text-white' : 'text-gray-400'
                  } text-xl rounded  w-8 h-8 flex items-center justify-center`}
                >
                  {step.id}
                </span>
                <span className={`text-lg px-2 ${step.id <= requestStatus ? 'text-move' : 'text-gray-400'} `}>
                  {step.title}
                </span>
                {}
                <hr
                  className={`absolute bottom-0 left-0 w-full  border ${
                    step.id <= requestStatus ? 'border-darkblue' : 'hidden'
                  } `}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-white ">
        <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue, errors }) => (
            <Form className="grid grid-cols-2  relative gap-6  px-6 py-6 text-gray-600 font-bold  ">
              {data.data.map((item, index) => {
                if (inputs[item.qtypeId] === 'textarea') {
                  return (
                    <label key={index} className={`flex  flex-col  justify-start space-y-2`}>
                      <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                      <Field
                        disabled
                        name={item?.inputName}
                        value={values[item?.inputName]}
                        className="border rounded py-2 px-3"
                        type={inputs[item.qtypeId]}
                        as="textarea"
                        onChange={(e) => {
                          setFieldValue(item?.inputName, e.target.value);
                        }}
                      />
                    </label>
                  );
                } else if (inputs[item.qtypeId] === 'select') {
                  return (
                    <Select
                      languageId={languageId}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      item={item}
                    />
                  );
                } else if (inputs[item.qtypeId] === 'radio') {
                  return <RadioButton item={item} />;
                } else if (inputs[item.qtypeId] === 'radio') {
                  return <RadioButton item={item} />;
                } else if (inputs[item.qtypeId] === 'checkbox') {
                  return (
                    <label key={index} className={`flex  flex-col justify-start space-y-2`}>
                      <div className={`flex items-center space-x-3 ${dir('', 'space-x-reverse')} `}>
                        <Field
                          name={item?.inputName}
                          value={values[item?.inputName]}
                          className="border rounded py-2 px-3"
                          type={inputs[item.qtypeId]}
                          checked={values[item?.inputName]}
                          onChange={(e) => {
                            if (inputs[item.qtypeId] === 'checkbox') {
                              setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                            } else {
                              setFieldValue(item?.inputName, e.target.value);
                            }
                          }}
                        />
                        <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                      </div>
                    </label>
                  );
                }
                return (
                  <label key={index} className={`flex  flex-col justify-start space-y-2`}>
                    <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                    <Field
                      disabled
                      name={item?.inputName}
                      value={values[item?.inputName]}
                      className="border rounded py-2 px-3"
                      type={inputs[item.qtypeId]}
                      checked={values[item?.inputName]}
                      onChange={(e) => {
                        if (inputs[item.qtypeId] === 'checkbox') {
                          setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                        } else {
                          setFieldValue(item?.inputName, e.target.value);
                        }
                      }}
                    />
                  </label>
                );
              })}
            </Form>
          )}
        </Formik>
        <hr />
        {5 > requestStatus ? (
          <div className="p-8">
            <div className={`col-span-2 flex space-x-4 ${dir('', 'space-x-reverse')}`}>
              <AddMessage />
              <DeclineMessage />
            </div>
            <MessagesLog />
          </div>
        ) : (
          <div className="p-8">
            <div className={`col-span-2 flex space-x-4 ${dir('', 'space-x-reverse')}`}>
              <button
                type="button"
                onClick={() => {
                  editAnswerList({
                    ...initialValues,
                    visitors: '1090',
                  });
                }}
                className={`bg-green-500 space-x-1 ${dir(
                  '',
                  'space-x-reverse'
                )} text-sm font-bold text-white flex items-center hover:bg-green-600 shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
              >
                <span>{t('Follo Up Visitor')}</span>
              </button>

              <button
                type="button"
                onClick={() => {
                  editAnswerList({
                    ...initialValues,
                    visitors: '1091',
                  });
                }}
                className={`bg-red-500 space-x-1 ${dir(
                  '',
                  'space-x-reverse'
                )} text-sm font-bold text-white flex items-center hover:bg-red-600 shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
              >
                <span>{t('Previous Visitors')}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
