import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import logo1 from '../../assets/img/logo1.png';
import logo2 from '../../assets/img/logo2.png';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import CustomTableSmall from '../../SharedComponents/CustomTableSmall';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data, printId, dateFrom, dateTo } = props;
  console.log('totalPages', data.data.length / 10);

  const totalPages = [...Array(Math.round(data.data.length / 10)).keys()];

  console.log('data', data.data.slice(0 * 10, 10));

  return (
    <div dir="rtl" className=" print hidden  " ref={ref}>
      {totalPages.map((e, index) => {
        const dataColumns = [
          {
            Header: 'م',
            accessor: 'idx',
            Cell: (cell) => {
              return (
                <>
                  {index > 0 && cell.row.index !== 9 && index}
                  {cell.row.index === 9 && `${(cell.row.index + 1) * (index + 1)}`}
                  {cell.row.index !== 9 && cell.row.index + 1}
                </>
              );
            },
          },
          {
            Header: 'رقم الطلب',
            accessor: 'id',
          },
          {
            Header: 'رقم العضوية',
            accessor: 'memberShipNumber',
          },
          {
            Header: 'الشركة',
            accessor: 'companyName',
          },
          {
            Header: 'الخدمة',
            accessor: 'service.name',
          },
          {
            Header: 'تاريخ الموقف',
            accessor: 'date',
            Cell: ({ cell }) => {
              const requestPositions = cell.row.original.requestPositions;
              return (
                <>
                  {requestPositions[0] !== undefined
                    ? DateTime.fromISO(requestPositions[0]?.positionDate)
                        .setLocale('ar-eg')
                        .toLocaleString(DateTime.DATE_MED)
                    : DateTime.fromISO(cell.row.original?.receivedDate)
                        .setLocale('ar-eg')
                        .toLocaleString(DateTime.DATE_MED)}
                </>
              );
            },
          },
          {
            Header: 'جهة المتابعة',
            accessor: 'x1',
            Cell: ({ cell }) => {
              const requestPositions = cell.row.original.requestPositions;
              return (
                <>
                  {requestPositions[0] !== undefined
                    ? requestPositions[0]?.requestFollowup.name
                    : cell.row.original.commingFrom?.name}
                </>
              );
            },
          },
          {
            Header: 'حالة الطلب',
            accessor: 'requestPositions',
            Cell: ({ cell }) => {
              return <>{cell.value[0] !== undefined ? cell.value[0]?.requestStatus.name : 'جاري'}</>;
            },
          },
          // {
          //   Header: 'كلمة المرور',
          //   accessor: 'password',
          // },
        ];
        return (
          <div style={{ height: 780, margin: 10 }} className=" bg-white overflow-hidden ">
            <div className="px-8">
              <div className="text-center">
                <h3 className="text-xl font-bold  pb-4 ">تقرير الطلبات</h3>
              </div>
              <div className="flex items-center justify-between my-4 font-bold">
                <div>
                  <span>العدد : </span>
                  <span>{data.data.length}</span>
                </div>
                <div className="flex flex-items space-x-4 rtl:space-x-reverse ">
                  <div>
                    <span>الفترة من : </span>
                    <span>{DateTime.fromISO(dateFrom).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
                  </div>
                  <div>
                    <span>إلى </span>
                    <span>{DateTime.fromISO(dateTo).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-xs">
              {/* <CustomTableAr small dataColumns={dataColumns} queryData={data.data.slice(index * 10, (index + 1) * 10)} /> */}
              <CustomTableSmall dataColumns={dataColumns} queryData={data.data.slice(index * 10, (index + 1) * 10)} />
            </div>
            <div className="flex justify-center items-center">
              <div className="flex space-x-2 space-x-reverse ">
                <span>صفحة</span>
                <span>( {index + 1} )</span>
                <span>من</span>
                <span>( {totalPages.length} )</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export const PrintTalabatList = (props) => {
  const { dateFrom, dateTo, filter } = props;
  console.log('filter', filter);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printId, setPrintId] = useState();

  const queryInitialState = {
    pageNumber: 0,
    pageSize: 10,
    isPaging: false,
    langId: 1,
    isFilter: true,

    filter: filter,
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery([`getInformationListx`, queryInitialState], query, {
    onSuccess: (data) => {},
  });
  if (isLoading) return '';
  if (isError) return '';
  return (
    <div>
      <ComponentToPrint
        filter={filter}
        dateFrom={dateFrom}
        dateTo={dateTo}
        data={data}
        printId={printId}
        ref={componentRef}
      />
      <button className="bg-blue-500 text-white px-4 mx-4 py-2 rounded-md" onClick={handlePrint}>
        طباعة
      </button>
    </div>
  );
};

export const query = async ({ queryKey }) => {
  const { data } = await axios.post('UserAccount/GetAllNewRequest', queryKey[1]);
  return data;
};
