import { Fragment, useState } from 'react';
import { BrowserRouter, Link, NavLink, Route, Switch, useLocation } from 'react-router-dom';
import logokeywhite from './assets/img/logokeywhite.png';
import user from './assets/img/user.svg';
import key from './assets/img/key.svg';
import Pages from './Views/Page/Pages/Pages';
import Page from './Views/Page/Pages/Page';
import Roles from './Views/Users/Roles/Roles';
import Users from './Views/Users/Users/Users';
import CreatePage from './Views/Page/Pages/CreatePage';
import Assets from './Views/Assets/Assets';
import AssetsCategory from './Views/Assets/AssetsCategory';
import ClipBoard from './SharedComponents/Svg/ClipBoard';
import Forms from './Views/Forms/Forms';
import Form from './Views/Forms/Form/Form';
import FormQuestionOptionsTypes from './Views/Forms/FormQuestionOption/Type/FormQuestionOptionsTypes';
import FormQuestionOptions from './Views/Forms/FormQuestionOption/FormQuestionOptions';
import GalleryAlbums from './Views/Gallery/GalleryAlbums/GalleryAlbums';
import Gallery from './Views/Gallery/Gallery';
import Menus from './Views/Menus/Menus';
import Faqs from './Views/Faq/Faqs';
import NewsLetters from './Views/NewsLetter/NewsLetters';
import Settings from './Views/Settings/Settings';
import Polls from './Views/Polls/Polls';
import CreatePoll from './Views/Polls/Poll/CreatePoll';
import Poll from './Views/Polls/Poll/Poll';
import Dashboard from './Views/Dashboard/Dashboard';
import Categories from './Views/Categories/Categories';
import Language from './Views/Language/Language';
import LanguageSwitcher from './SharedComponents/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import ViewGrid from './SharedComponents/Svg/ViewGrid';
import Photograph from './SharedComponents/Svg/Photograph';
import AssetsIcon from './SharedComponents/Svg/AssetsIcon';
import Archive from './SharedComponents/Svg/Archive';
import MenuIcon from './SharedComponents/Svg/MenuIcon';
import QuestionMarkCircle from './SharedComponents/Svg/QuestionMarkCircle';
import UserGroupIcon from './SharedComponents/Svg/UserGroupIcon';
import Globe from './SharedComponents/Svg/Globe';
import Collection from './SharedComponents/Svg/Collection';
import InboxIn from './SharedComponents/Svg/InboxIn';
import LockClosed from './SharedComponents/Svg/LockClosed';
import User from './SharedComponents/Svg/User';
import Cog from './SharedComponents/Svg/Cog';
import calIIcon from './assets/img/calIIcon.svg';

import FormAnswer from './Views/Forms/FormAnswer/FormAnswer';
import SingleFormAnswer from './Views/Forms/FormAnswer/SingleFormAnswer';
import { Helmet } from 'react-helmet';
import Requests from './Views/Requests/Requests';
import CreateRequest from './Views/Requests/CreateRequest';
import Request from './Views/Requests/Request';
import FormRoles from './Views/FormRoles/FormRoles';
import { getFormQuestionOption, getFormQuestionOptionType } from './Views/Forms/ApiForms';
import { useQuery } from 'react-query';
import ChevronRight from './SharedComponents/Svg/ChevronRight';
import Reports from './Views/Reports/Reports';
import Answers from './Views/Answers/Answers';
import CreateUsers from './Views/Users/Users/CreateUsers';
import EditUser from './Views/Users/Users/EditUser';
import VisitorsReports from './Views/Reports/VisitorsReports';
import ViewAnswer from './Views/Answers/ViewAnswer';
import { dir } from './utils/localisation';
import Complaints from './Views/Complaints/Complaints';
import CreateComplaintsRequest from './Views/Requests/CreateComplaintsRequest';
import Calendar from './Views/Calendar/Calendar';
import CmsRoutes from './SharedComponents/Layout/CmsRoutes';
import SubNav from './SharedComponents/Layout/SubNav';
import CustomMain from './SharedComponents/Layout/CustomMain';
import MainNav from './SharedComponents/Layout/MainNav';
import Routes from './SharedComponents/Layout/Routes';
import ImmigrationRoutes from './SharedComponents/Layout/ImmigrationRoutes';
import resourcesIcon from './assets/img/resourcesIcon.svg';
import ReIntegrationRoutes from './SharedComponents/Layout/ReIntegrationRoutes';
import NotificationsCount from './SharedComponents/Layout/NotificationsCount';
import { sendMessage, useNotifications } from './context/useNotifications';
import Briefcase from './SharedComponents/Svg/Briefcase';
import SubmissionsRoutes from './SharedComponents/Layout/SubmissionsRoutes';
import { DocumentSearchIcon } from '@heroicons/react/outline';
import PermissionHolder from './SharedComponents/PermissionHolder';

export default function Layout({ children }) {
  // Control UserDropDown

  const token = localStorage.getItem('token');
  const JSONToken = JSON.parse(token);
  const [userToggle, setUserToggle] = useState(false);
  const userName = JSONToken.name;
  console.log('====================================');
  console.log('userName', userName);
  console.log('====================================');
  const [sidebarToggle, setSidebarToggle] = useState(true);
  let location = useLocation();
  const { i18n, t } = useTranslation();
  const calenderRoutes = [
    { name: 'Calendar', to: '/Calendar' },
    { name: 'My Calendar', to: '/mycalender' },
  ];
  const systemManagmentRoutes = [
    { name: 'users', to: '/users' },
    { name: 'employees', to: '/employees' },
    { name: 'FormsRoles LIST', to: '/formroles' },
    { name: 'Roles', to: '/roles' },
  ];
  const layoutMenu = [
    {
      id: 1,
      name: `${t('Dashboard')}`,
      icon: <ViewGrid />,
      to: '/dashboard',
    },
    {
      id: 2,
      name: `${t('Assets')}`,
      icon: <AssetsIcon />,
      to: '/assets',
    },
    {
      id: 3,
      name: `${t('galleryalbums')}`,
      icon: <Photograph />,
      to: '/galleryalbums',
    },
    {
      id: 4,
      name: `${t('pages')}`,
      icon: <Archive />,
      to: '/pages',
    },
    {
      id: 5,
      name: `${t('Categories')}`,
      icon: <Collection />,
      to: '/categories',
    },
    {
      id: 6,
      name: `${t('Language')}`,
      icon: <Globe />,
      to: '/language',
    },
    {
      id: 7,
      name: `${t('Menus')}`,
      icon: <MenuIcon />,
      to: '/menus',
    },
    {
      id: 8,
      name: `${t('form QOptionsTypes')}`,
      icon: <QuestionMarkCircle />,
      to: '/formQuestionOptionsTypes',
    },
    {
      id: 16,
      name: `${t('Requests')}`,
      icon: <ClipBoard />,
      to: '/requests',
    },
    {
      id: 16,
      name: `${t('FormsRoles LIST')}`,
      icon: <ClipBoard />,
      to: '/formroles',
    },
    {
      id: 9,
      name: `${t('Forms')}`,
      icon: <ClipBoard />,
      to: '/forms',
    },
    {
      id: 10,
      name: `${t('FAQ')}`,
      icon: <QuestionMarkCircle />,
      to: '/faq',
    },
    {
      id: 11,
      name: `${t('Polls')}`,
      icon: <UserGroupIcon />,
      to: '/polls',
    },
    {
      id: 12,
      name: `${t('News Letters')}`,
      icon: <InboxIn />,
      to: '/newsletters',
    },
    {
      id: 13,
      name: `${t('Roles')}`,
      icon: <LockClosed />,
      to: '/roles',
    },
    {
      id: 14,
      name: `${t('Users')}`,
      icon: <User />,
      to: '/users',
    },
    {
      id: 15,
      name: `${t('Settings')}`,
      icon: <Cog />,
      to: '/settings',
    },
  ];

  const resourcesRoutes = [
    { name: 'الشركات الفردى', to: '/subscribers/2' },
    { name: ' شركات التضامن', to: '/subscribers/3' },
    { name: ' شركات التوصية البسيطة', to: '/subscribers/4' },
    { name: ' الشركات المساهمة', to: '/subscribers/16' },
    { name: 'الشركات ذات المسؤلية المحدودة', to: '/subscribers/25' },
    { name: ' مراسل اجنبى', to: '/subscribers/6' },
    { name: ' طالبي القيد طبقا لاحكام البند 8', to: '/subscribers/5' },
  ];

  const talabatRoutes = [
    { name: 'انشاء طلب', to: '/talabat/new' },
    { name: 'استعلام و متابعة', to: '/talabat' },
  ];
  const singleRequestsRoutes = [
    { name: 'tawseek', to: '/subscribers/7' },
    { name: 'bayanat', to: '/subscribers/8' },
    { name: 'declinespeciality', to: '/subscribers/9' },
    { name: 'changespeciality', to: '/subscribers/10' },
    { name: 'renewmembership', to: '/subscribers/11' },
    { name: 'damga', to: '/subscribers/12' },
    { name: 'rafefeaa', to: '/subscribers/13' },
    { name: 'addspeciality', to: '/subscribers/14' },
  ];

  // const sendMessage = useNotifications();

  return (
    <>
      <div className="flex h-screen bg-gray-200 font-roboto">
        <BrowserRouter>
          <div className="flex">
            <div className="hidden fixed z-20 inset-0 bg-[#1a3175] opacity-50 transition-opacity lg:hidden"></div>
            <div
              className={`-translate-x-full ease-in fixed z-40 inset-y-0 left-0 ${
                sidebarToggle ? 'w-64 ' : 'w-20'
              }  transition duration-300 transform bg-[#1e3a8a] overflow-y-auto lg:translate-x-0 lg:static lg:inset-0`}
            >
              <div className="flex items-center justify-left px-4 py-3 bg-[#1a3175]">
                <NavLink to="/">
                  <img className="max-h-10 px-2 my-1" src={sidebarToggle ? logokeywhite : key} alt="logo" />
                </NavLink>
              </div>
              <nav className="mt-10 text-white  bg-[#1e3a8a]">
                {/* <SubmissionsRoutes title={t('subscribers')} sidebarToggle={sidebarToggle} /> */}
                {userName !== 'samy' && userName !== 'hesham' && (
                  <PermissionHolder permission="tasheed.joinRequests.nav">
                    <CustomMain
                      routes={resourcesRoutes}
                      title={t('new subscribers')}
                      icon={resourcesIcon}
                      sidebarToggle={sidebarToggle}
                    />
                  </PermissionHolder>
                )}
                {/* TODO ADD PERMISSION FOR TALABAT */}
                <PermissionHolder permission="cms.lastrequest.policy">
                  <CustomMain
                    routes={talabatRoutes}
                    title={t('talabat')}
                    icon={resourcesIcon}
                    sidebarToggle={sidebarToggle}
                  />
                </PermissionHolder>
                {userName !== 'samy' && userName !== 'hesham' && (
                  <PermissionHolder permission="tasheed.specialRequests.nav">
                    <CustomMain
                      routes={singleRequestsRoutes}
                      title={t('singlerequests')}
                      icon={resourcesIcon}
                      sidebarToggle={sidebarToggle}
                    />
                  </PermissionHolder>
                )}
                {userName !== 'samy' && userName !== 'hesham' && (
                  <>
                    <PermissionHolder permission="tasheed.suggestions.nav">
                      <NavLink
                        to={`/suggestions`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <Cog />
                        <span className="mx-4">{t('suggestions')}</span>
                      </NavLink>
                    </PermissionHolder>
                    <PermissionHolder permission="tasheed.consultation.nav">
                      <NavLink
                        to={`/consultations`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <Cog />
                        <span className="mx-4">{t('consultations')}</span>
                      </NavLink>
                    </PermissionHolder>
                    <PermissionHolder permission="tasheed.agenda.nav">
                      <NavLink
                        to={`/agenda`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <Cog />
                        <span className="mx-4">{t('agenda')}</span>
                      </NavLink>
                    </PermissionHolder>
                    <PermissionHolder permission="tasheed.information.nav">
                      <NavLink
                        to={`/information`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <DocumentSearchIcon className="h-6" />
                        <span className="mx-4">{t('information')}</span>
                      </NavLink>
                    </PermissionHolder>
                  </>
                )}

                <PermissionHolder permission="tasheed.information.nav">
                  <NavLink
                    to={`/informationList`}
                    activeClassName="bg-[#354e96] text-white border-white"
                    activeStyle={{
                      borderColor: '#fff',
                    }}
                    className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                  >
                    <DocumentSearchIcon className="h-6" />
                    <span className="mx-4">{t('informationList')}</span>
                  </NavLink>
                </PermissionHolder>
                {userName !== 'samy' && userName !== 'hesham' && (
                  <>
                    <PermissionHolder permission="tasheed.complaints.nav">
                      <NavLink
                        to={`/complaints`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <DocumentSearchIcon className="h-6" />
                        <span className="mx-4">{t('complaints')}</span>
                      </NavLink>
                    </PermissionHolder>

                    <PermissionHolder permission="tasheed.complaintDepartments.nav">
                      <NavLink
                        to={`/complaintDepartments`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <DocumentSearchIcon className="h-6" />
                        <span className="mx-4">{t('complaintDepartments')}</span>
                      </NavLink>
                    </PermissionHolder>
                    <PermissionHolder permission="tasheed.userStatuses.nav">
                      <NavLink
                        to={`/userStatuses`}
                        activeClassName="bg-[#354e96] text-white border-white"
                        activeStyle={{
                          borderColor: '#fff',
                        }}
                        className="flex items-center duration-200 mt-4 py-2 px-6 border-l-0  hover:bg-[#354e96]  hover:text-[#fdce00]"
                      >
                        <DocumentSearchIcon className="h-6" />
                        <span className="mx-4">{t('userStatuses')}</span>
                      </NavLink>
                    </PermissionHolder>

                    <PermissionHolder permission="cms.managment">
                      <CmsRoutes title="CMS" layoutMenu={layoutMenu} sidebarToggle={sidebarToggle} />
                    </PermissionHolder>
                  </>
                )}
              </nav>
            </div>
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <header className="flex justify-between items-center py-4 px-6 bg-white shadow-lg border-b border-gray-200 z-40">
              <div className="flex items-center">
                <button className="text-gray-500 focus:outline-none lg:hidden">
                  <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4 6H20M4 12H20M4 18H11"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
                <button className="relative mx-4 lg:mx-0" onClick={() => setSidebarToggle(!sidebarToggle)}>
                  <span
                    className={`absolute inset-y-0 ${
                      i18n.language === 'ar' ? '-left-12' : 'left-0'
                    } left-0 pl-3 flex items-center`}
                  >
                    <svg
                      className="w-9 h-9"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h7"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
              <div className={`${document.dir === 'ltr' ? '' : ' space-x-reverse'} space-x-4 flex items-center`}>
                <LanguageSwitcher />

                <Link to="/notifications" className="flex relative  text-gray-400 focus:outline-none">
                  {/* <NotificationsCount /> */}
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    ></path>
                  </svg>
                </Link>
                <button className="flex  text-gray-400 focus:outline-none">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    ></path>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    ></path>
                  </svg>
                </button>
                <div className="relative">
                  <button
                    className="relative z-40 block h-10 w-10 rounded-full overflow-hidden bg-gray-100 focus:outline-none"
                    onClick={() => {
                      setUserToggle(!userToggle);
                    }}
                  >
                    <img className="absolute bottom-0 left-0" src={user} alt="Your avatar" />
                  </button>
                  <div className="fixed inset-0 h-full w-full z-10" style={{ display: 'none' }}></div>
                  <div
                    className={`absolute transition duration-300 transform scale-0 ease-in-out ${
                      i18n.language === 'ar' ? '-right-40' : 'right-0'
                    } mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 ${userToggle ? ' scale-100' : ''}`}
                    // style={{ display: userToggle ? 'block' : 'none' }}
                  >
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                      {t('Profile')}
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                      {t('Products')}
                    </a>
                    <button
                      href="/"
                      className={`block w-full ${
                        i18n.language === 'en' ? 'text-left' : 'text-right'
                      }  px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white`}
                      onClick={() => {
                        localStorage.removeItem('token');
                        window.location.reload();
                      }}
                    >
                      {t('Log out')}
                    </button>
                  </div>
                </div>
              </div>
            </header>
            <main className="flex-1 overflow-x-hidden overflow-y-auto  bg-gray-200">
              <div className="mx-auto px-8 py-8" style={{ minHeight: '90%' }}>
                <Routes />
              </div>
              <div className="flex  px-8 py-8">
                <span>
                  Copyright by <b className="text-red-900">KEYframe</b>. All rights reserved.
                </span>
              </div>
            </main>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}
