import { useLocation, useParams } from 'react-router-dom';
import { NegativePrintCompponent } from './NegativePrintCompponent';
import { PrintInformation } from './PrintInformation';

export default function FullInfo(props) {
  const params = useParams();
  const search = useLocation().search;
  const oldData = new URLSearchParams(search).get('data');
  // const newData = JSON.parse(oldData);

  const newData = {
    outId: new URLSearchParams(search).get('outId'),
    fullName: new URLSearchParams(search).get('fullName')?.replace(/_/g, ' '),
    status: new URLSearchParams(search).get('status'),
  };
  return (
    <>
      {newData.status !== 'undefined' ? (
        <PrintInformation data={newData} />
      ) : (
        <NegativePrintCompponent data={newData} />
      )}
    </>
  );
}
