import React, { Fragment, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import Loading from '../../../SharedComponents/Loading';
import AddSection from './AddSection';
import { getLanguages, getSections } from './ApiSections';
import SaveSectionOrder from './SaveSectionOrder';
import Section from './Section';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SectionList = React.memo(function SectionList({ sections, languageId, languages }) {
  return sections.map((section, index) => (
    // <Quote quote={quote} index={index} key={quote.id} />
    <Fragment key={section.id}>{section && <Section languages={languages} index={index} section={section} />}</Fragment>
  ));
});

export default function Sections(props) {
  // section enmum
  //     public enum SectionType
  //     {
  //         Card = 0,
  //         Tabs = 1,
  //         Collapse = 2,
  //         Banner = 3
  //     }
  // public enum NextBtnType
  //     {
  //         Arrows = 0,
  //         Dots = 1,
  //         ArrowsAndDots = 2,
  //     }
  //     public enum ImagePosition
  //     {
  //         Top = 0,
  //         Bottom = 1,
  //         Left = 2,
  //         Right = 3,
  //     }

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  const params = useParams();
  const pageId = params.id;
  const [state, setState] = useState({ sections: [] });

  const initialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    pageId: pageId,
  };
  const { data, isLoading, isError, isSuccess } = useQuery(['sections', initialState], getSections, {
    staleTime: 90000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      setState({
        ...state,
        sections: data.data.sort(function (a, b) {
          return a.orderNum - b.orderNum;
        }),
      });
    },
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const sections = reorder(state.sections, result.source.index, result.destination.index);

    setState({ sections });
  }

  if (isLoading)
    return (
      <div className="relative bg-white mt-24">
        <Loading />
      </div>
    );
  if (isError) return 'return';

  return (
    <>
      {/* {data.data.map((section) => {
        return <Section languages={languages} section={section} />;
      })} */}
      {data.data.length > 0 && <SaveSectionOrder state={state} />}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <SectionList sections={state.sections} languages={languages} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddSection orderNumber={data.data.length + 1} />
    </>
  );
}
