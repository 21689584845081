import { dir } from '../../utils/localisation';

export default function FormError(props) {
  const { errors, name, array, touched } = props;
  let jsonError = {};
  const x = errors[name] ? errors[name] : undefined;
  if (x) {
    jsonError = JSON.parse(x);
  }
  // const touchx = touched;
  return (
    <>
      {errors && touched && touched[name] && Object.keys(jsonError).length > 0 && (
        <span className={`${dir('text-left', 'text-right')} pt-1 text-xs font-base text-red-500`}>
          {dir(`${jsonError?.en}`, `${jsonError?.ar}`)}
        </span>
      )}
    </>
  );
}
