import axios from 'axios';

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const GetAllRequests = async ({ queryKey }) => {
  const { data } = await axios.post('/FrmAnswer/GetAllRequests', queryKey[1]);
  return data;
};

export const ChangeRequestStatus = async (e) => {
  const { data } = await axios.post('/FrmAnswer/ChangeRequestStatus', e);
  return data;
};
export const CheckRequestFormHaveAnswer = async (e) => {
  console.log('queryKey', e);
  const { data } = await axios.post('/FrmAnswer/CheckRequestFormHaveAnswer', e);
  return data;
};

export const getRequestDataWithId = async (body) => {
  console.log('body', body);
  const { data } = await axios.get(
    `/FrmAnswer/GetRequestDataWithId?RequestDataId=${parseInt(body.queryKey[1].RequestDataId)}`
  );
  return data;
};

export const getEnglishName = async (body) => {
  console.log('body', body);
  const { data } = await axios.get(`/FrmAnswer/GetEnglishName?answerId=${parseInt(body.queryKey[1].answerId)}`);
  return data;
};

export const GetAllForms = async ({ queryKey }) => {
  const { data } = await axios.post('/FrmQuestion/GetAllBy', queryKey[1]);
  return data;
};

export const ConnectFormToOwner = async (body) => {
  console.log('queryKey', body);
  const { data } = await axios.post('/FrmAnswer/ConnectFormToOwner', body);
  return data;
};
export const GetVisitorData = async (body) => {
  console.log('queryKey', body);
  const { data } = await axios.post('/FrmAnswer/GetVisitorData', body.queryKey[1]);
  return data;
};

export const GetRequestId = async (body) => {
  const data = await axios.post('/FrmAnswer/AddRequest');
  return data;
};

export const GetDataOfRequest = async (body) => {
  const { data } = await axios.get(
    `/FrmAnswer/GetDataOfRequest?formId=${body.queryKey[1].formID}&RequestDataId=${parseInt(
      body.queryKey[1].requestId
    )}`
  );
  return data;
};

export const AddAnswerList = async (body) => {
  const { data } = await axios.post('/FrmAnswer/AddAnswerList', body);
  return data;
};
export const EditAnswerList = async (body) => {
  const { data } = await axios.post('/FrmAnswer/EditAnswerList', body);
  return data;
};
