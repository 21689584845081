import { Field, FieldArray, Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import LanguageList from '../../../../SharedComponents/LanguageList';
import Notification from '../../../../SharedComponents/Notification';
import ChevronRight from '../../../../SharedComponents/Svg/ChevronRight';
import { editSection } from '../ApiSections';
import DeleteSection from '../DeleteSection';
import EditSection from '../EditSection';
import SectionHeader from '../SectionHeader';
import ToggleSectionStatus from '../ToggleSectionStatus';
import AddSliderItem from './AddSliderItem';
import { addSliderItem, editSlider } from './ApiSlider';
import SliderItem from './SliderItem';

export default function Slider(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { section, languages, provided } = props;
  const [languageId, setLanguageId] = useState(1);
  const { t, i18n } = useTranslation();
  const nextBtnTypeIds = [
    { id: 0, name: 'Arrows' },
    { id: 1, name: 'Dots' },
    { id: 2, name: 'ArrowsAndDots' },
  ];
  const imagePositionIds = [
    { id: 0, name: 'Top' },
    { id: 1, name: 'Bottom' },
    { id: 2, name: 'Left' },
    { id: 3, name: 'Right' },
  ];
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(editSection, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
        setIsOpen(false);
      }
    },
  });
  const onSubmit = async (values) => {
    try {
      await mutation.mutate(values);
    } catch (error) {
      console.log('error', error);
    }
  };

  // Mutate Data to Create New Page
  const addSliderItemMutation = useMutation(addSliderItem, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // queryClient.invalidateQueries(['sections']);
        queryClient.invalidateQueries(['sections']);
      }
      queryClient.invalidateQueries(['sections']);
    },
  });

  const editSliderMutation = useMutation(editSlider, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries(['sections']);
      }
    },
  });

  const onSaveSettings = async (values) => {
    try {
      await editSliderMutation.mutate(values.pgSlider);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  return (
    <>
      {editSliderMutation.isSuccess && <Notification message={t('Section Updated')} />}

      <div className="block bg-white w-full shadow border mb-4">
        <SectionHeader
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section={section}
          languageId={languageId}
          provided={provided}
        />
        <Formik initialValues={section}>
          {({ values, setFieldValue, setValues }) => {
            return (
              <>
                <div className={`bg-white px-4  overflow-hidden ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
                  <div className="">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {t('Section Type')} : {t('Slider')}
                      </div>
                      <div>
                        <div>
                          <LanguageList
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                            languages={languages?.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                          />
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4">
                    <div> {t('Section Settings')} </div>
                    <hr className="mt-2 mb-4 block" />
                    <div className="grid grid-cols-3 gap-6 items-center flex">
                      <div>
                        <Field
                          className="border rounded p-2 w-full"
                          as="select"
                          name="pgSlider.nextBtnTypeId"
                          onChange={(e) => {
                            setFieldValue('pgSlider.nextBtnTypeId', parseInt(e.target.value));
                          }}
                        >
                          {nextBtnTypeIds.map((nextBtnTypeIds, index) => (
                            <option value={parseInt(nextBtnTypeIds.id)} key={nextBtnTypeIds.id}>
                              {t(nextBtnTypeIds.name)}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div>
                        <Field
                          className="border rounded p-2 w-full"
                          as="select"
                          name="pgSlider.imagePositionId"
                          onChange={(e) => {
                            setFieldValue('pgSlider.imagePositionId', parseInt(e.target.value));
                          }}
                        >
                          {imagePositionIds.map((imagePositionIds, index) => (
                            <option value={parseInt(imagePositionIds.id)} key={imagePositionIds.id}>
                              {t(imagePositionIds.name)}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4">
                      <button
                        type="button"
                        onClick={() => {
                          onSaveSettings(values);
                        }}
                        className="bg-darkblue focus:outline-none space-x-1 text-sm font-bold text-white flex items-center hover:bg-darkbluehover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
                      >
                        {t('Save Settings')}
                      </button>
                    </div>
                  </div>
                  {values &&
                    values.pgSlider &&
                    values.pgSlider.pgSliderItems &&
                    values.pgSlider.pgSliderItems.map((pgSliderItem, index) => (
                      <FieldArray
                        key={index}
                        name="pgSlider.pgSliderItems"
                        render={(arrayHelpers) => (
                          <>
                            <SliderItem
                              setFieldValue={setFieldValue}
                              languageId={languageId}
                              pgSliderItem={pgSliderItem}
                              pgSliderItemIndex={index}
                              pgSliderItemsHelper={arrayHelpers}
                            />
                          </>
                        )}
                      />
                    ))}

                  <div className={`flex justify-end py-4 space-x-1 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                    <AddSliderItem
                      pgSliderId={values.pgSliderId}
                      orderNum={values.pgSlider.pgSliderItems.length}
                      addSliderItemMutation={addSliderItemMutation}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        onSubmit(values);
                      }}
                      className="bg-lightgreen py-2 px-2 flex justify-center text-center h-full text-sm font-bold text-white items-center hover:bg-lightgreenhover text-centerhover shadow  rounded transition duration-300 ease-in-out"
                    >
                      {t('Save Section')}
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
        {addSliderItemMutation.isSuccess && <Notification message="Slider Item Added" />}
      </div>
    </>
  );
}
