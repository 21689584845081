import React from 'react';
import { useBarcode } from 'react-barcodes';

function Barcode(props) {
  const { value } = props;
  const { inputRef } = useBarcode({
    value: value,
    options: {
      displayValue: false,
      background: '#fff',
      height: 50,
      margin: 0,
    },
  });

  return <svg ref={inputRef} />;
}

export default Barcode;
