import { useQuery } from 'react-query';
import { getFormQuestionOption } from '../Forms/ApiForms';
import DropDown from './Components/DropDown';

export default function ReasonOfVisitList(props) {
  const { values, errors, setFieldValue, title, placeholder, dropData } = props;
  let reasonOfVisitListQueryinitialState = {
    typeId: 1008,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const reasonOfVisitListQuery = useQuery(
    ['formQuestionOption', reasonOfVisitListQueryinitialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  return (
    <>
      {reasonOfVisitListQuery.isLoading && (
        <>
          <label className="relative col-span-2 lg:col-span-1 mb-4">
            <div className="block mb-2 py-4 w-1/2 text-lg font-semibold bg-white animate-pulse"></div>
            <div className="px-4 py-6 w-full bg-white rounded-md outline-none animate-pulse"></div>
          </label>
        </>
      )}
      {reasonOfVisitListQuery.data && (
        <DropDown
          title={title}
          placeholder={placeholder}
          list={reasonOfVisitListQuery.data.data}
          loading={reasonOfVisitListQuery.loading}
          error={reasonOfVisitListQuery.error}
          values={values}
          errors={errors}
          disabled={false}
          setFieldValue={setFieldValue}
          FieldValue={values.reasonsofvisit}
          FieldName="reasonsofvisit"
          dropData={dropData}
        />
      )}
    </>
  );
}
