import { CheckCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import Button from '../../SharedComponents/Button/Button';
import SelectV2 from '../../SharedComponents/Inputs/components/SelectV2';
import Notification from '../../SharedComponents/Notification';
import { generatepassword } from '../../utils/generatepassword';

export default function OldMemeberRequest(props) {
  const queryClient = useQueryClient();
  const comingFromData = queryClient.getQueryData(`comingfrom`);
  const servicesData = queryClient.getQueryData(`services`);
  const [confirmation, setConfirmation] = useState(false);
  const user = props.data.data[0];
  const memeberinitialValues = {
    receivedDateString: DateTime.now(),
    commingFromId: comingFromData.data[0].id,
    serviceId: servicesData.data[0].id,
    companyName: user.fullName,
    userId: user.id,
    notes: '',
    memberShipNumber: user.memberShip,
    password: generatepassword(),
  };

  const mutationFunction = async (e) => {
    const { data } = await axios.post(`UserAccount/AddNewRequest`, e);
    return data;
  };
  const mutation = useMutation(mutationFunction, {
    onSuccess: (data) => {
      toast(<Notification message={data.message} />);
    },
  });

  const onSubmit = (e) => {
    const newData = {
      ...e,
      receivedDateString: DateTime.fromISO(e.receivedDateString).toISODate(),
    };
    mutation.mutate(newData);
  };
  return (
    <>
      <div>
        <Formik onSubmit={onSubmit} initialValues={memeberinitialValues}>
          {({ values, errors }) => {
            return (
              <Form>
                <div className="grid grid-cols-2 gap-6 mt-4">
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}>رقم الطلب</span>
                      <Field
                        disabled
                        name="id"
                        value={mutation?.data?.outId}
                        className="border rounded py-2 px-3"
                        type="number"
                      />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> تاريخ استلام الطلب</span>
                      <div className="relative w-full">
                        <Field
                          disabled
                          value={DateTime.fromISO(values.receivedDateString)
                            .setLocale('ar-eg')
                            .toLocaleString(DateTime.DATE_MED)}
                          className="border absolute left-0 bg-white  z-20 rounded py-2 px-3"
                          style={{ width: '95%' }}
                        />
                        <Field
                          type="date"
                          name="receivedDateString"
                          className=" absolute z-10 focus:outline-none  left-0 right-0 rounded py-2 px-3"
                        />
                      </div>{' '}
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}>اسم الشركة</span>
                      <Field disabled name="companyName" className="border rounded py-2 px-3" />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> رقم العضوية</span>
                      <Field disabled name="memberShipNumber" className="border rounded py-2 px-3" type="number" />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> وارد من *</span>
                      <SelectV2
                        selectList={comingFromData.data}
                        disabled={mutation.data ? true : false}
                        wrapperClass="mt-0"
                        errors={errors}
                        name="commingFromId"
                      />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> الخدمة *</span>
                      <SelectV2
                        selectList={servicesData.data}
                        disabled={mutation.data ? true : false}
                        wrapperClass="mt-0"
                        errors={errors}
                        name="serviceId"
                      />
                    </label>
                  </div>
                  <div className="col-span-2">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> ملاحظات</span>
                      <Field disabled={mutation.data} name="notes" className="border rounded py-2 px-3" as="textarea" />
                    </label>
                  </div>
                  {!mutation.data && (
                    <div className="flex space-x-2 print:hidden rtl:space-x-reverse">
                      <div>
                        <Button loading={mutation.isLoading} type="submit" className="px-10" />
                      </div>
                      <div>
                        <Button text="الغاء" className="bg-red-500 hover:bg-red-400 px-10" />
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        {mutation.data && (
          <div className=" flex items-center space-x-2 rtl:space-x-reverse mt-8 font-bold">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
            <span>تم الحفظ بنجاح للطلب رقم</span>
            <span className="bg-[#f3f3f3] border px-8 py-2 rounded-lg">{mutation.data.outId}</span>
            <span>و كلمة السر</span>
            <span className="bg-[#f3f3f3] border px-8 py-2 rounded-lg">{mutation.data.data}</span>
          </div>
        )}
        <div className="flex justify-center space-x-2 rtl:space-x-reverse font-bold ">
          <span>موقع الاتحاد : </span>
          <span>https://tasheed.org</span>
        </div>
      </div>
    </>
  );
}
