import React from 'react';

export default function Print(props) {
  const { className } = props;
  return (
    <svg className={`${className ? className : ' text-white w-6 h-6'}`}>
      <path
        data-name="Path 3380"
        d="M15 15h2a2 2 0 002-2V9a2 2 0 00-2-2H3a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H7a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V3a2 2 0 00-2-2H7a2 2 0 00-2 2v4z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}
