import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import Button from '../../SharedComponents/Button/Button';
import Text from '../../SharedComponents/Inputs/components/Text';
import Modal from '../../SharedComponents/Modal/Modal';
import { PlusIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import Notification from '../../SharedComponents/Notification';

export default function DeleteRequestPosition(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { name, infoId, mutationData } = props;
  const sharedProps = { isOpen, setIsOpen, name, infoId, mutationData };
  return (
    <>
      <div className="inline-block">
        <Button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          noText
          icon={<TrashIcon className="w-6 h-6" />}
          className="bg-red-500 text-white  mx-4 py-2 rounded-md"
        />
      </div>

      {isOpen && <ModalContainer {...sharedProps} />}
    </>
  );
}

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { isOpen, setIsOpen, infoId, mutationData } = props;
  const sharedProps = { isOpen, setIsOpen };

  const initialValues = { id: infoId };
  const mutationFunction = async (e) => {
    const { data } = await axios.post(`/UserAccount/DeleteRequestPosition`, { id: infoId });

    return data;
  };
  const params = useParams();
  const id = parseInt(params.id);

  const mutation = useMutation(mutationFunction, {
    onSuccess: async (data, values) => {
      await queryClient.invalidateQueries([`singleTalab-${id}`]);
      await queryClient.invalidateQueries([`signleTalabRquestPositions-${id}`]);
      toast(<Notification message={data.message} />);

      setIsOpen(false);
    },
  });
  const onSubmit = (e) => {
    mutation.mutate(mutationData);
  };
  return (
    <>
      {isOpen && (
        <Modal modalTitle="حذف الموقف" {...sharedProps}>
          <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ values, errors }) => {
              return (
                <Form className="rtl" dir="rtl">
                  {' '}
                  <div className="flex flex-col space-y-4">
                    <span className="text-right my-4">
                      برجاء العلم ان البيانات سيتم حذفها من خوادمنا. هل انت متأكد انك تريد الحذف
                    </span>
                  </div>
                  <div className="flex space-x-2 space-x-reverse">
                    <Button
                      type="submit"
                      text="حذف"
                      loading={mutation.isLoading}
                      disabled={mutation.isLoading}
                      className="bg-green-500 hover:bg-green-600 w-1/4"
                    />
                    <Button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      text="الغاء"
                      className="bg-red-500 hover:bg-red-600 w-1/4"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};

const mutationFunction = async (e) => {
  const { data } = await axios.post(`ComplainDepartment/Delete`, {
    id: e.id,
  });
  return data;
};
