export default {
  'Job employment': 'التوظيف',
  Recruitments: 'التوظيف',
  'Recruitments ID': 'التوظيف ID',
  'Company Name': 'اسم الشركة',
  Position: 'الموقع',
  'Work Type': 'نوع العمل',
  Status: 'الحالة',
  Subscriptions: 'الاشتراكات',
  Inprogress: 'قيد المراجعه',
  Closed: 'مغلق',
  Pennding: 'قيد الانتظار',
  'Add / Update Recruitments': 'إضافة / تعديل الوظيفه',
  View: 'مشاهده',
  Subscriber: 'مشترك',
  Workplace: 'مكان العمل',
  Recruitment: 'التوظيف',
  Filtering: 'تصفية',
  Germany: 'ألمانيا',
  'Work Time': 'دوام العمل',
  'Full Day': 'يوم كامل',
  'Half-day': 'نصف يوم',
  'by piece': 'بالقطعة',
  'Available jobs': 'الوظائف المتاحة',
  'Choose the job': 'اختر الوظيفة',
  'Years of Experience': 'سنوات الخبرة',
  Language: 'اللغة',
  Arabic: 'عربي',
  Einglish: 'إنجليزي',
  German: 'ألمانيا',
  Male: 'ذكر',
  Female: 'انثى',
  Wanted: 'مطلوب',
  'Release Date': 'تاريخ الاصدار',
  'Registration deadline': 'اخر موعد للتسجيل',
  'Register now': 'سجل الان',
  'Registration done': 'تم التسجيل',
  Details: 'التفاصيل',
  Gender: 'الجنس',
  'Job details': 'تفاصيل الوظيفة',
  Phone: 'الهاتف',
  'Register for this job': 'سجل على هذه الوظيفة',
  Watch: 'مشاهدة',
  'Required certificates': 'الشهادات المطلوبة',
  'Required skills': 'المهارات المطلوبة',
  'Job description': 'وصف الوظيفة',
  Company: 'الشركة',
  'Start Date': 'تاريخ البدء',
  'End Date': 'تاريخ الانتهاء',
  'E-mail': 'بريد الالكتروني',
  Deutsch: 'الألمانية',
  'Add New': 'اضف جديد',
  Skills: 'المهارات',
  Certificates: 'الشهادات',
  both: 'كلاهما',
  'Full Time': 'وقت كامل',
  'Part Time': 'دوام جزئى',
  Contract: 'عقد',
  Freelance: 'عمل حر',
  Egypt: 'مصر',
  English: 'إنجليزي',
  Rejected: 'مرفوض',
  'Job Added': 'تمت إضافة الوظيفة',
  'Job has been modified successfully': 'تم تعديل الوظيفة بنجاح',
};
