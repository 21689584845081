import { CloudDownloadIcon, XCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavLink, useParams } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTable from '../../SharedComponents/CustomTable';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Eye from '../../SharedComponents/Svg/Eye';

export default function SubscribersDataById() {
  const params = useParams();
  const { id } = params;
  console.log('====================================');
  console.log('router', params);
  console.log('====================================');
  const queryInitialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    langId: 1,
    id: parseInt(id),
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [`getSubmittionsByFormId-${parseInt(id)}`, queryInitialState],
    getSubmittionsByFormId,
    {
      // onSuccess: (data) => {
      // 	pagination(data);
      // },
    }
  );
  if (isLoading) return '';
  if (isError) return '';

  if (data.data.length === 0) {
    return (
      <>
        <div className="bg-white h-[500px] flex items-center justify-center">
          <div className="flex flex-col space-y-4 items-center">
            <span className="text-3xl text-red-500">عفوا</span>
            <span className="text-2xl">لا يوجد طلبات في هذا القسم</span>
          </div>
        </div>
      </>
    );
  }

  const tableData = data.data[0].answerSubmittions
    // .filter((x) => x.question.frmQuestionLngs.filter((s) => s.languageId === 1))
    .filter((x) => x.question.frmQuestionLngs[0].description !== 'dropDown')
    ?.sort((a, b) => a.question.orderNum - b.question.orderNum)
    .slice(0, 5)
    .map((e, index) => {
      return {
        Header: e.question.frmQuestionLngs[0].title,
        accessor: `answerSubmittions[${index}].answer`, // accessor is the "key" in the data
        Cell: (cell) => {
          if (cell.row.original.answerSubmittions.length === 0) {
            console.log('answerSubmittions', cell.row.original.answerSubmittions.length);
            return 'empty';
          } else {
            const value = cell.row.original.answerSubmittions.filter((x) => x.questionId === e.questionId)[0]?.answer;
            const questionType = cell.row.original.answerSubmittions.filter((x) => x.questionId === e.questionId)[0]
              ?.questionTypeId;
            if (questionType === 4) {
              console.log('answerSubmittions', value);
              const parsedArray = JSON.parse(value);
              return (
                <div className="flex space-x-2 space-x-reverse">
                  {parsedArray?.map((r, index) => {
                    return (
                      <>
                        <div className="flex">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="bg-green-500 hover:bg-green-600 font-normal  text-xs text-white py-2 px-4 rounded  flex space-x-2 space-x-reverse items-center"
                            href={`${window.env.REACT_APP_IMAGE_PATH}/${r.cdnPath}`}
                          >
                            <span>ملف - {index + 1}</span>
                            {/* <CloudDownloadIcon className="h-4" /> */}
                          </a>
                        </div>
                      </>
                    );
                  })}
                </div>
              );
            } else {
              return <>{value}</>;
            }
          }
        },
      };
    });
  console.log('tableData', tableData);

  const dataColumns = [
    ...tableData,

    {
      Header: 'حالة الطلب',
      accessor: 'submitionStatus',
      Cell: ({ cell }) => {
        return (
          <div className="flex space-x-2">
            {cell.value === 0 && (
              <div className="text-blue-500 border-blue-500 px-4 py-1 border rounded text-sm font-normal">
                قيد المراجعة
              </div>
            )}
            {cell.value === 1 && (
              <div className="text-green-500 border-green-500 px-4 py-1 border rounded text-sm font-normal">
                تم القبول{' '}
              </div>
            )}
            {cell.value === 2 && (
              <div className="text-red-500 border-red-500 px-4 py-1 border rounded text-sm font-normal">تم الرفض</div>
            )}
          </div>
        );
      },
    },
    {
      Header: 'خيارات',
      accessor: 'options',
      Cell: ({ cell }) => {
        return (
          <div className="flex space-x-2">
            <NavLink to={`/subscriber/${cell.row.original.id}`} className="bg-green-500 text-white p-1 rounded">
              <Eye className="h-4" />
            </NavLink>
          </div>
        );
      },
      width: 0,
      maxWidth: 10,
      sortDescFirst: true,
    },
  ];
  console.log('data', data);

  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('Requests')} />

          {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('Requests')}</h2>
        </div>

        <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
      </div>
    </>
  );
}

export const getSubmittionsByFormId = async ({ queryKey }) => {
  const { data } = await axios.post('Submittion/GetByFormId', queryKey[1]);
  return data;
};
