import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import DownloadExcel from './DownloadExcel';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import Print from '../../SharedComponents/Svg/Print';
import Trash from '../../SharedComponents/Svg/Trash';
import TablePagination from '../../SharedComponents/TablePagination';
import TableSearch from '../../SharedComponents/TableSearch';
import { getLanguages } from '../Page/Pages/ApiPage';
import { GetAllAnswersByFrmQoptionFiltered, GetAllAnswersByFrmQoptionTypeId } from './ApiAnswers';
import DownloadPdf from './DownloadPdf';
import DeleteBySubmitId from './DeleteBySubmitId';
import TablePaginationNew from '../../SharedComponents/TablePaginationNew';
import { dir } from '../../utils/localisation';
import Eye from '../../SharedComponents/Svg/Eye';
import { getVisitorsReport } from '../Reports/ApiReports';

export default function Answers(props) {
  const { qOptionName, qOptionId, purposeOfRequest } = useParams();
  const { t, i18n } = useTranslation();
  const [languageId, setLanguageId] = useState(2);
  const [entries, setEntries] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationCount, setPaginationCount] = useState([]);
  function pagination() {
    let paginationCount = [];
    for (var i = 1; i <= numberOfPages + 0.8; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {
    pagination();
  }, [numberOfPages]);

  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  // const initialState = {
  //   pageNumber: pageNumber,
  //   pageSize: 99,
  //   numberOfPages: 0,
  //   isPaging: false,
  //   isFilter: true,
  //   langId: languageId,
  //   isSearch: false,
  //   newsnumber: 0,
  //   parentId: 0,
  //   checkIsRequest: false,
  //   isRequest: true,
  //   filter: [
  //     {
  //       filterBy: 'visitors',
  //       filterType: 'String',
  //       dateFilteration: {
  //         from: 'string',
  //         to: 'string',
  //       },
  //       searchTerm: [
  //         {
  //           langId: languageId,
  //           term: '1',
  //         },
  //       ],
  //     },
  //     {
  //       filterBy: 'purposeOfRequest',
  //       filterType: 'String',
  //       dateFilteration: {
  //         from: 'string',
  //         to: 'string',
  //       },
  //       searchTerm: [
  //         {
  //           langId: languageId,
  //           term: purposeOfRequest,
  //         },
  //       ],
  //     },
  //   ],
  //   searchFilter: {
  //     langId: 0,
  //     term: 'string',
  //   },
  //   optionId: qOptionId,
  // };

  const initialState = {
    pageNumber: pageNumber,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: qOptionId,
    purposeofrequest: purposeOfRequest,
  };
  const { data, isLoading, isError } = useQuery(['formAnswer', initialState], GetAllAnswersByFrmQoptionFiltered, {
    onSuccess: (data) => {
      setNumberOfPages(data.paging.totalPages);
    },
  });
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  const filteredData = data.data
    .map((e) => e.answer.filter((e) => e.questionId === 1057))
    .filter((e) => e.length > 0)
    .filter((e) => e[0].answer === (languageId === 1 ? 'هجرة' : 'Immigration'));
  const tableHeaderen = [
    { title: t('ID') },
    { title: t('En Name') },
    { title: t('Mobile No.') },
    { title: t('ID NO.') },
    { title: t('Reason Of Visit') },
    { title: t('Date') },
    { title: t('OPTIONS'), className: 'w-64' },
  ];
  const tableHeaderar = [
    { title: t('ID') },
    { title: t('Ar NAME') },
    { title: t('Mobile No.') },
    { title: t('ID NO.') },
    { title: t('REASON OF VISIT') },
    { title: t('Date') },
    { title: t('OPTIONS'), className: 'w-64' },
  ];

  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name={t(qOptionName)} />

        <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          <DownloadPdf languageId={languageId} />
          <DownloadExcel languageId={languageId} />
        </div>
      </header>

      {/* <div>
          <CreateForm languages={languages} />
        </div> */}

      {/* table */}
      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{qOptionName}</h2>
              <h4 className="bg-move text-white py-1 px-3 rounded-md">{data.data.length}</h4>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <div className=" px-5  mt-4">
          <div className=" flex   justify-between relative  ">
            {qOptionId === '1089' && (
              <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
                <Link
                  to={`/requests/${qOptionId}/0/${purposeOfRequest}`}
                  className={`${dir(
                    '',
                    'space-x-reverse'
                  )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
                >
                  <PlusCircle className="w-5 h-5" /> <span>{t('Add New')}</span>
                </Link>
              </div>
            )}

            <div className="flex transform  relative z-10 ">
              <TableSearch
                entries={entries}
                //   onChangeEntries={onChangeEntries}
                //   onSearch={onSearch}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {languageId === 1 &&
                        tableHeaderar.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }    uppercase font-bold text-sm ${e.className}`}
                            >
                              {e.title}
                            </th>
                          );
                        })}
                      {languageId === 2 &&
                        tableHeaderen.map((e) => {
                          return (
                            <th
                              key={e.title}
                              scope="col"
                              className={`px-6 py-3 ${
                                i18n.language === 'en' ? 'text-left' : 'text-right'
                              }    uppercase font-bold text-sm ${e.className}`}
                            >
                              {e.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {data.data.map((item) => {
                      // const item = itemx[0];
                      console.log('item', item);
                      return (
                        <tr key={item.id} className="">
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            {item.answer.filter((e) => e.questionId === 1046)[0]?.submitId}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            {languageId === 2 && item.answer.filter((e) => e.questionId === 1029)[0]?.answer}
                            {languageId === 1 && item.answer.filter((e) => e.questionId === 1030)[0]?.answer}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            {item.answer.filter((e) => e.questionId === 1035)[0]?.answer}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            {item.answer.filter((e) => e.questionId === 1031)[0]?.answer}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            {item.answer.filter((e) => e.questionId === 1039)[0]?.answer}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            {item.answer.filter((e) => e.questionId === 1045)[0]?.answer}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">
                            <div className={`flex space-x-2 ${dir('', 'space-x-reverse')}`}>
                              <Link
                                to={`/${qOptionName}/${item.answer[0].formId}/${item.answer[0].requestDataId}/${item.requestStatus}`}
                                className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
                              >
                                <Eye className="w-5 h-5" />
                              </Link>
                              <DeleteBySubmitId id={item.submitId} />
                            </div>
                            {/* {form.frmFormLngs.map((e) => {
                              return (
                                <Fragment key={e.id}>
                                  {languageId === e.languageId && (
                                    <>
                                      <div className="flex items-center space-x-2">
                                        <b>{e.title}</b>
                                      </div>
                                    </>
                                  )}
                                </Fragment>
                              );
                            })} */}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.mobile}</td>

                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.idNo}</td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.reason}</td>
                          <td className="px-6 py-4 whitespace-nowrap font-bold text-base">{form.date}</td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap font-bold text-base flex items-center space-x-2 ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <Link className="bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out">
                              <PecnilAlt className="w-5 h-5" />
                            </Link>
                            <Link className="bg-red-100 text-red-800 hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out">
                              <Trash className="w-5 h-5" />
                            </Link>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <TablePaginationNew
                  data={data}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
