import { InformationCircleIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import Button from '../../SharedComponents/Button/Button';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Select from '../../SharedComponents/Inputs/components/Select';
import LoadingSpinner from '../../SharedComponents/Loading/LoadingSpinner';
import PageLoading from '../../SharedComponents/Loading/PageLoading';
import TablePagination from '../../SharedComponents/TablePagination';
import { PrintTalabatList } from './PrintTalabatList';
import { fakeOrders, followUpOffice, serviceselectList, talabStatus } from './talabatlists';
import TalabInformationModal from './TalabInformationModal';

export default function Talabat(props) {
  const [paginationCount, setPaginationCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [filter, setFilter] = useState([
    {
      filterBy: 'datefromto',
      dateFilteration: {
        from: DateTime.now().startOf('month').toISODate(),
        to: DateTime.now().toISODate(),
      },
    },
  ]);
  const [initialData, setInitialData] = useState({
    id: '',
    membershipnumber: '',
    from: DateTime.now().startOf('month').toISODate(),
    to: DateTime.now().toISODate(),
  });
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  const dataColumns = [
    {
      Header: 'رقم الطلب',
      accessor: 'id',
    },
    {
      Header: 'رقم العضوية',
      accessor: 'memberShipNumber',
    },
    {
      Header: 'الشركة',
      accessor: 'companyName',
    },
    {
      Header: 'الخدمة',
      accessor: 'service.name',
    },
    {
      Header: 'تاريخ الموقف',
      accessor: 'date',
      Cell: ({ cell }) => {
        const requestPositions = cell.row.original.requestPositions.sort((a, b) => {
          return b.id - a.id;
        });

        console.log('requestPositions', requestPositions[0]);
        return (
          <>
            {requestPositions[0] !== undefined
              ? DateTime.fromISO(requestPositions[0]?.positionDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)
              : DateTime.fromISO(cell.row.original.receivedDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
          </>
        );
      },
    },
    {
      Header: 'جهة المتابعة',
      accessor: 'x1',
      Cell: ({ cell }) => {
        const requestPositions = cell.row.original.requestPositions;
        return (
          <>
            {requestPositions[0] !== undefined
              ? requestPositions[0]?.requestFollowup.name
              : cell.row.original.commingFrom?.name}
          </>
        );
      },
    },
    {
      Header: 'حالة الطلب',
      accessor: 'requestPositions',
      Cell: ({ cell }) => {
        return <>{cell.value[0] !== undefined ? cell.value[0]?.requestStatus.name : 'جاري'}</>;
      },
    },
    {
      Header: 'كلمة المرور',
      accessor: 'password',
    },
    {
      Header: 'خيارات',
      accessor: 'options',
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center space-x-2 rtl:space-x-reverse">
            <TalabInformationModal
              message={
                cell.row.original.requestPositions[0] !== undefined
                  ? cell.row.original.requestPositions[0]?.notes
                  : cell.row.original.notes
              }
            />
            <NavLink to={`/talabat/${cell.row.original.id}`} className="bg-[#075985] text-white p-1 rounded">
              <ArrowLeftIcon className="h-4" />
            </NavLink>

            {cell.row.original.newRequestComments.length > 0 &&
            cell.row.original.newRequestComments?.sort((a, b) => b.id - a.id).filter((e) => e?.commentStatus === 0)[0]
              ?.createdBy === 0 ? (
              <NavLink to={`/talabat/${cell.row.original.id}`} className="bg-red-500 text-white p-1 rounded">
                <InformationCircleIcon className="h-4" />
              </NavLink>
            ) : (
              ''
            )}
          </div>
        );
      },
      width: 0,
      maxWidth: 10,
    },
  ];

  const initialValues = initialData;

  const { data, isLoading, isError } = useQuery(
    [
      `getTalabat`,
      {
        pageNumber: pageNumber,
        pageSize: 10,
        numberOfPages: 0,
        isPaging: true,
        isFilter: true,
        filter: filter,
        searchFilter: {
          langId: 0,
          term: 'string',
        },
      },
    ],
    query,
    {
      onSuccess: (data) => {
        pagination(data);
      },
    }
  );
  if (isLoading) return <PageLoading />;
  if (isError) return '';
  const onSubmit = (values, { resetForm }) => {
    const filterId = {
      filterBy: 'id',
      searchTerm: [
        {
          term: values.id,
        },
      ],
    };
    const filterBymemeberShipNumber = {
      filterBy: 'membershipnumber',
      searchTerm: [
        {
          term: values.membershipnumber,
        },
      ],
    };
    const serviceId = {
      filterBy: 'service',
      searchTerm: [
        {
          langId: '1',
          term: `${values.serviceId}`,
        },
      ],
    };
    const dateFromTo = {
      filterBy: 'datefromto',
      dateFilteration: {
        from: values.from ? values.from : DateTime.now().startOf('month').toISODate(),
        to: values.to ? values.to : DateTime.now().toISODate(),
      },
    };
    setInitialData({
      id: values.id,
      from: values.from ? values.from : DateTime.now().startOf('month').toISODate(),
      to: values.to ? values.to : DateTime.now().toISODate(),
      membershipnumber: values.membershipnumber,
      serviceId: values.serviceId,
    });

    const filters = [
      values.id ? filterId : undefined,
      values.membershipnumber ? filterBymemeberShipNumber : undefined,
      values.from || values.to ? dateFromTo : undefined,
      values.serviceId ? serviceId : undefined,
    ];

    setFilter(
      filters.filter((element) => {
        return element !== undefined;
      })
    );
  };

  return (
    <>
      {' '}
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs generalRoute="طلبات" generalRouteTo="/talabat" name="استعلام عن طلبات" />
        <div className={`flex items-center space-x-3 space-x-reverse`}>
          {/* <DownloadPdf languageId={languageId} /> */}
          {/* <DownloadExcel languageId={languageId} /> */}
          <PrintTalabatList filter={filter} dateFrom={initialData.from} dateTo={initialData.to} />
        </div>
      </header>
      <div className="p-4 border shadow rounded-lg bg-white mt-8">
        <div className="border-b pb-4 ">
          <span className="font-bold text-xl text-[#003eef]">استعلام عن طلبات</span>
        </div>

        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, resetForm, setFieldValue }) => {
            return (
              <Form>
                {filter.length > 0 && (
                  <div className="bg-gray-50 border border-gray-100 p-4 mt-4">
                    <div className="flex items-center justify-between  border-b pb-2">
                      <span className="font-bold block ">محددات البحث</span>
                      <div>
                        <Button
                          type="button"
                          text="الغاء محددات البحث"
                          onClick={() => {
                            resetForm();
                            setFieldValue('id', '');
                            setFieldValue('from', DateTime.now().startOf('month').toISODate());
                            setFieldValue('to', DateTime.now().toISODate());
                            setFieldValue('membershipnumber', '');

                            setFilter([]);
                          }}
                          className="px-8 py-2 text-sm mt-0 bg-red-500 hover:bg-red-400"
                        />
                      </div>
                    </div>
                    <div className="flex mt-2 space-x-4 rtl:space-x-reverse">
                      {values.Id && (
                        <div className="flex space-x-4 rtl:space-x-reverse">
                          <span className="font-bold">رقم الطلب :</span>
                          <span>{values.id}</span>
                        </div>
                      )}
                      {values.membershipnumber && (
                        <div className="flex space-x-4 rtl:space-x-reverse">
                          <span className="font-bold">رقم العضوية :</span>
                          <span>{values.membershipnumber}</span>
                        </div>
                      )}
                      {values.serviceId && (
                        <div className="flex space-x-4 rtl:space-x-reverse">
                          <span className="font-bold">نوع الخدمة :</span>
                          <span>
                            {data?.services?.data?.data?.filter((e) => e.id === parseInt(values.serviceId))[0]?.name}
                          </span>
                        </div>
                      )}
                      <div className="flex space-x-4 rtl:space-x-reverse">
                        <span className="font-bold">من تاريخ : </span>
                        <span>
                          {DateTime.fromISO(values.from).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
                        </span>
                      </div>
                      <div className="flex space-x-4 rtl:space-x-reverse">
                        <span className="font-bold">الى تاريخ : </span>
                        <span>{DateTime.fromISO(values.to).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-4 flex items-end space-x-2 rtl:space-x-reverse">
                  <div className="w-[20%]">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={` text-base text-right rtl:text-right `}>رقم الطلب </span>
                      <Field name="id" className="border rounded py-2 px-3" type="number" />
                    </label>
                  </div>
                  <div className="w-[20%]">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={` text-base text-right rtl:text-right `}>رقم العضوية </span>
                      <Field name="membershipnumber" className="border rounded py-2 px-3" type="number" />
                    </label>
                  </div>
                  <div className="w-[20%]">
                    <Select
                      required
                      selectList={[{ id: null, name: '' }, ...data?.services?.data?.data]}
                      selectListName="name"
                      classNames="py-1"
                      wrapperClass="mt-0"
                      label="نوع الخدمة"
                      name="serviceId"
                    />
                  </div>

                  <div className="w-[20%]">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={` text-base text-right rtl:text-right `}>الفترة من</span>
                      <Field name="from" className="border rounded py-2 px-3" type="date" />
                    </label>
                  </div>
                  <div className="w-[20%]">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={` text-base text-right rtl:text-right `}>الى </span>
                      <Field name="to" className="border rounded py-2 px-3" type="date" />
                    </label>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      text="بحث"
                      //   onClick={() => {
                      //     setValid(true);
                      //   }}
                      className="px-8 bg-green-500 hover:bg-green-400"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <CustomTableAr className="lg:px-0" dataColumns={dataColumns} queryData={data.data} />
        <TablePagination
          data={data}
          paginationCount={paginationCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
}

export const query = async ({ queryKey }) => {
  const { data } = await axios.post('UserAccount/GetAllNewRequest', queryKey[1]);
  const services = await axios.post('UserAccount/GetAllService', {});
  return { ...data, services };
};
