import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Modal from '../../SharedComponents/Modal';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import { dir } from '../../utils/localisation';
import { AddCalenderVisit, GetAllEmployee, GetVisitorsNames } from './ApiCalender';
import Notification from '../../SharedComponents/Notification';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import EmployeesList from './EmployeesList';
import { getLanguages } from '../Settings/ApiSettings';
import Loading from '../../SharedComponents/Loading';
import LanguageList from '../../SharedComponents/LanguageList';
import VisitorsList from './VisitorsList';

export default function AddVisit() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className={`bg-lightgreen space-x-1 ${dir(
          '',
          'space-x-reverse'
        )} text-sm font-bold text-white flex items-center hover:bg-lightgreenhover mb-4 shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add Visit')}</span>
      </button>
      {isOpen && <ModalContainer isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}
const ModalContainer = (props) => {
  const { isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState(1);

  const queryClient = useQueryClient();
  const history = useHistory();
  const AddCalenderVisitMutation = useMutation(AddCalenderVisit, {
    onSuccess: async (data) => {
      toast(<Notification message={t('Added, Successfully')} />);
      queryClient.invalidateQueries(['GetAllCalenders']);
      setIsOpen(false);
      window.location.reload();
    },
  });

  const GetVisitorsNamesQuery = useQuery(
    ['GetVisitorsNames', { id: languageId === 2 ? 1029 : 1030 }],
    GetVisitorsNames,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  let getAllEmployeesInitialState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: false,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: 'string',
  };

  const GetAllEmployeeQuery = useQuery(['GetAllEmployee', getAllEmployeesInitialState], GetAllEmployee, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });

  const onSubmit = (e) => {
    console.log('submitted', e);
    AddCalenderVisitMutation.mutate(e);
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ visitorId: null, userId: null, location: '', visitReson: '', visitTime: '', visitDate: '' }}
    >
      {({ values, errors, setFieldValue }) => {
        return (
          <Form>
            <Modal className="sm:max-w-3xl" isOpen={isOpen} setIsOpen={setIsOpen} title={t('Add Visit')} add={true}>
              {isLoading && <Loading />}
              {isError && 'error'}
              {/* {data && (
                  <LanguageList
                    className="flex transform translate-y-1 relative z-10 mb-4"
                    languages={data?.data}
                    languageId={languageId}
                    setLanguageId={setLanguageId}
                  >
                    <hr className="border w-full absolute bottom-0 left-0 z-0" />
                  </LanguageList>
                )} */}
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Select Day')}</h3>
                <Field
                  type="date"
                  name="visitDate"
                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                />
              </label>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Select time')}</h3>
                <Field
                  type="time"
                  name="visitTime"
                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                />
              </label>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Reason')}</h3>
                <Field
                  as="textarea"
                  name="visitReson"
                  className="border rounded  w-full  p-2 text-sm focus:outline-none"
                />
              </label>
              <label className={`mb-3 block ${dir('', 'text-right')} `}>
                <h3 className="text-[#3d4151] text-base ">{t('Location')}</h3>
                <Field type="text" name="location" className="border rounded  w-full  p-2 text-sm focus:outline-none" />
              </label>
              {GetAllEmployeeQuery.data && (
                <EmployeesList
                  languageId={languageId}
                  setFieldValue={setFieldValue}
                  FieldName="userId"
                  FieldValue={values.userId}
                  list={GetAllEmployeeQuery.data.data}
                  errors={errors}
                />
              )}
              {GetVisitorsNamesQuery.data && (
                <VisitorsList
                  languageId={languageId}
                  setFieldValue={setFieldValue}
                  FieldName="visitorId"
                  FieldValue={values.visitorId}
                  list={GetVisitorsNamesQuery.data.data}
                  errors={errors}
                />
              )}
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
