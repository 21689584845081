import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import Notification from '../../SharedComponents/Notification';
import { AddAnswerList, GetAllForms, GetRequestId } from '../Requests/ApRequests';
import { getLanguages } from '../Settings/ApiSettings';
import ComplainFormContainer from './ComplainFormContainer';
import ConnectComplainToUser from './ConnectComplainToUser';

export default function CreateComplaintRequest() {
  const { status } = useParams();
  const formID = +status === 0 ? 1017 : 1;
  const { t } = useTranslation();
  const [val, setVal] = useState({});
  const history = useHistory();
  const [languageId, setLanguageId] = useState(1);

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  // useEffect(() => {
  //   setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  // }, [languages.data]);

  const steps = [{ id: 1, title: 'Add Complain' }];
  return (
    <>
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/requests/0" name={t('Create Complaint')} />
      </header>
      <div className="bg-white mt-4 pt-4 px-6 flex justify-between border-b">
        <h2 className="text-xl font-bold px-4 ">{t('ADD NEW VISITOR')}</h2>
        <div>
          <div className="">
            <div>
              {languages.data && languages.data?.data && (
                <LanguageList
                  className="flex justify-end transform translate-y-1 relative z-10 mb-1 "
                  languages={languages.data?.data}
                  languageId={languageId}
                  setLanguageId={setLanguageId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-4">
        <div className="flex space-x-12 mx-6 border-b">
          {steps.map((step, index) => {
            return (
              <div className={`flex  relative py-4 px-2`}>
                <span
                  className={` ${
                    step.id <= 1 ? 'bg-move text-white' : 'text-gray-400'
                  } text-xl rounded  w-8 h-8 flex items-center justify-center`}
                >
                  {step.id}
                </span>
                <span className={`text-lg px-2 ${step.id <= 1 ? 'text-move' : 'text-gray-400'} `}>{t(step.title)}</span>
                {}
                <hr
                  className={`absolute bottom-0 left-0 w-full  border ${step.id <= 1 ? 'border-darkblue' : 'hidden'} `}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="bg-white ">
        <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
        {parseInt(status) === 0 && <ComplainFormContainer languageId={languageId} formID={1025} requestStatus={0} />}
        {parseInt(status) === 1 && <ConnectComplainToUser />}
      </div>
    </>
  );
}
