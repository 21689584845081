import LoadingSpinner from './LoadingSpinner';
/**
 *
 *
 * @export
 * @param {*} { className ,}
 * @returns
 */
export default function PageLoading(props) {
	const { className } = props;
	return (
		<div
			className={`flex flex-col items-center justify-center w-full ${
				className ? className : 'h-screen '
			} `}
		>
			<div className='relative'>
				<LoadingSpinner className='w-14 h-14 text-blue-500 left-0 relative' />
			</div>
		</div>
	);
}
