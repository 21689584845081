import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import Button from '../../../SharedComponents/Button/Button';
import Text from '../../../SharedComponents/Inputs/components/Text';
import Modal from '../../../SharedComponents/Modal/Modal';
import { PlusIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import * as Yup from 'yup';

export default function DeleteComplaintDepartment(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { name, infoId } = props;
  const sharedProps = { isOpen, setIsOpen, name, infoId };
  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        noText
        icon={<TrashIcon className="h-4" />}
        className="text-sm bg-red-500 hover:bg-red-600 px-2 mt-0"
      />

      {isOpen && <ModalContainer {...sharedProps} />}
    </>
  );
}

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { isOpen, setIsOpen, infoId } = props;
  const sharedProps = { isOpen, setIsOpen };

  const initialValues = { id: infoId };
  const mutation = useMutation(mutationFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries('complaintDepartments');
      setIsOpen(false);
    },
  });
  const onSubmit = (e) => {
    mutation.mutate(e);
  };
  return (
    <>
      {isOpen && (
        <Modal modalTitle="حذف البيانات" {...sharedProps}>
          <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ values, errors }) => {
              return (
                <Form className="rtl" dir="rtl">
                  {' '}
                  <div className="flex flex-col space-y-4">
                    <span className="text-right my-4">
                      برجاء العلم ان البينات سيتم مسحها من الخوادم. هل انت متأكد انك تريد الحذف؟
                    </span>
                  </div>
                  <div className="flex space-x-2 space-x-reverse">
                    <Button
                      type="submit"
                      text="حذف"
                      loading={mutation.isLoading}
                      className="bg-green-500 hover:bg-green-600 w-1/4"
                    />
                    <Button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      text="الغاء"
                      className="bg-red-500 hover:bg-red-600 w-1/4"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};

const mutationFunction = async (e) => {
  const { data } = await axios.post(`ComplainDepartment/Delete`, {
    id: e.id,
  });
  return data;
};
