import { useState } from 'react';

export const generatepassword = (props) => {
  const numbers = '0123456789';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const specialCharacters = '!^+%&/()=?_#${[]}|;:>÷<.*-@';
  const characterList = numbers + upperCaseLetters;
  let password = '';
  const characterListLength = characterList.length;

  for (let i = 0; i < 10; i++) {
    const characterIndex = Math.round(Math.random() * characterListLength);
    password = password + characterList.charAt(characterIndex);
  }
  return password;
};
