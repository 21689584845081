import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../SharedComponents/Modal';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Loading from '../../../../SharedComponents/Loading';
import { getLanguages } from '../ApiSections';
import AddSliderImage from './AddSliderImage';
import SliderPagesList from './SliderPagesList';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function AddSliderItem(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId, activeMenu, orderNum, addSliderItemMutation, pgSliderId } = props;
  const { t } = useTranslation();
  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="bg-lightgreen space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out"
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New Slider Item')}</span>
      </button>

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          orderNum={orderNum}
          addSliderItemMutation={addSliderItemMutation}
          pgSliderId={pgSliderId}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, orderNum, addSliderItemMutation, pgSliderId } = props;
  const [languageId, setLanguageId] = useState(1);

  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const [activePage, setActivePage] = useState();
  // create a loop for localisation
  const pgSliderItemLng = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    btnTitle: '',
    subTitle: '',
    text: '',
  }));
  const initialValues = {
    isExternalLink: 'true',
    toLinkUrl: '',
    imageUrl: '',
    orderNum: orderNum,
    pgSliderId: pgSliderId,
    pgSliderItemLng: pgSliderItemLng,
  };

  const queryClient = useQueryClient();
  const onSubmit = async (e) => {
    const newData = {
      ...e,
      isExternalLink: e.isExternalLink === 'true' || e.isExternalLink === true ? true : false,
    };
    try {
      await addSliderItemMutation.mutate(newData);
      // await setIsOpen(false);
      queryClient.resetQueries(['sections']);
    } catch (error) {
      console.log('error', error);
    }
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {}, [activePage]);
  return (
    <>
      {data && data.data && (
        <Formik
          // onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={() => onSubmit(values)}
                  title={t('Add Item')}
                  isLoading={addSliderItemMutation.isLoading}
                  add={true}
                  submitType="button"
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      className="flex transform translate-y-1 relative z-10 mb-4"
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                        {(values.imageUrl && (
                          <>
                            <div className="flex items-center justify-center h-full p-2">
                              <img
                                className="w-full h-full object-contain"
                                alt="img"
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                              />{' '}
                            </div>
                            <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                              <AddSliderImage edit={true} setField={setFieldValue} fieldName="imageUrl" />
                            </div>
                          </>
                        )) || (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <AddSliderImage fieldName="imageUrl" setField={setFieldValue} />
                          </div>
                        )}
                      </div>
                      <div className="mt-4">
                        {values &&
                          values.pgSliderItemLng &&
                          values?.pgSliderItemLng.map((pgSliderItemLng, index) => (
                            <FieldArray
                              key={index}
                              name="pgSliderItemLng"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === pgSliderItemLng.languageId && (
                                    <>
                                      <label>
                                        <Field
                                          className="border rounded  w-full  p-2"
                                          type="text"
                                          placeholder={t('Button Title')}
                                          name={`pgSliderItemLng[${index}].btnTitle`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                      </div>
                      <div className="mt-4 relative">
                        <SliderPagesList setFieldValue={setFieldValue} languageId={languageId} />
                      </div>
                    </div>
                    <div>
                      {values &&
                        values.pgSliderItemLng &&
                        values?.pgSliderItemLng.map((pgSliderItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSliderItemLng"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSliderItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full mb-4  p-2"
                                        type="text"
                                        placeholder={t('Add Title')}
                                        name={`pgSliderItemLng[${index}].title`}
                                      />
                                    </label>
                                    <label>
                                      <Field
                                        className="border rounded  w-full mb-4  p-2"
                                        type="text"
                                        placeholder={t('Subtitle')}
                                        name={`pgSliderItemLng[${index}].subTitle`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}

                      {values &&
                        values.pgSliderItemLng &&
                        values?.pgSliderItemLng.map((pgSliderItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSliderItemLng"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSliderItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        as="textarea"
                                        rows="3"
                                        placeholder={t('Add Description')}
                                        name={`pgSliderItemLng[${index}].text`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                      <div className="grid grid-cols-2 space-x-2 mt-4 ">
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                          <span>{t('External Link')}</span>
                        </label>
                        <label className="flex items-center space-x-2">
                          <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                          <span>{t('Pages')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <label className="mt-2 block">
                    <Field
                      type="number"
                      name="orderNum"
                      placeholder="Order Number"
                      className="border rounded  w-full  p-2"
                    />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
