import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import { dir } from '../../utils/localisation';
import Trash from '../../SharedComponents/Svg/Trash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ChangeJobStatus, editJob, GetJob } from './api/api';
import { useParams } from 'react-router';
import Loading from '../../SharedComponents/Loading';
import { Field, FieldArray, Form, Formik } from 'formik';
import Notification from '../../SharedComponents/Notification';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

export default function EditJobEmployment() {
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState(2);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isError, isLoading } = useQuery(['GetJob', { id, langId: languageId }], GetJob);
  const editJobStatusMutation = useMutation(ChangeJobStatus, {
    onSuccess: () => {
      toast(<Notification message={t('Job has been modified successfully')} />);

      queryClient.invalidateQueries(['GetJobs']);
      queryClient.invalidateQueries(['GetJob']);
    },
    onError: () => {
      toast(<Notification isSuccess={false} isError={true} message={t('error while adding')} />);
    },
  });

  const editJobMutation = useMutation(editJob, {
    onSuccess: () => {
      toast(<Notification message={t('Job has been modified successfully')} />);

      queryClient.invalidateQueries(['GetJobs']);
      queryClient.invalidateQueries(['GetJob']);
    },
    onError: () => {
      toast(<Notification isSuccess={false} isError={true} message={t('error while adding')} />);
    },
  });

  const onSubmit = (e) => {
    // delete e.closeDate;
    console.log('e', e);
    delete e.createDate;
    const body = {
      ...e,
      // lastApllied: new Date(),
      lastApllied: DateTime.fromISO(e.lastApllied).toISODate(),
      startDate: DateTime.fromISO(e.startDate).toISODate(),

      jobsLngs: [
        {
          ...e.jobsLngs[0],
          jobSkills: JSON.stringify(e.jobsLngs[0].jobSkills.map((e) => e.text)),
          certifications: JSON.stringify(e.jobsLngs[0].certifications.map((e) => e.text)),
        },
      ],
    };
    editJobMutation.mutate(body);
  };

  if (isLoading) return <Loading />;
  if (isError) return 'error';

  const parseAndCheckValidJsonTextToArray = (text) => {
    if (text && text.length !== 0) {
      try {
        return JSON.parse(text)?.map((e) => ({ text: e }));
      } catch (e) {
        return [{ text: text }];
      }
    }
    return [];
  };

  const initialValues = {
    ...data?.data,
    jobLocation: data.data.jobLocation.toString(),
    jobLanguage: data.data.jobLanguage.toString(),
    startDate: DateTime.fromISO(data.data.startDate).toISODate(),
    lastApllied: DateTime.fromISO(data.data.lastApllied).toISODate(),
    jobsLngs: [
      {
        ...data?.data?.jobsLngs[0],
        jobSkills: parseAndCheckValidJsonTextToArray(data?.data?.jobsLngs[0]?.jobSkills),
        certifications: parseAndCheckValidJsonTextToArray(data?.data?.jobsLngs[0]?.certifications),
      },
    ],
  };

  console.log('data', data.data);
  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name={t('Job employment')} />
      </header>
      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex pb-4    justify-between relative  ">
            <div className={` flex items-center space-x-3   ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Add / Update Recruitments')}</h2>
            </div>

            <div className={`flex transform items-center  relative z-10 space-x-12 ${dir('', 'space-x-reverse')} `}>
              <span
                className={`${
                  data.data?.jobStatus === 1
                    ? 'text-[#84cc16] border-[#84cc16]'
                    : data.data?.jobStatus === 2
                    ? 'text-[#4f46e5] border-[#4f46e5]'
                    : data.data?.jobStatus === 3
                    ? 'text-[#84cc16] border-[#84cc16]'
                    : data.data?.jobStatus === 4
                    ? 'text-[#84cc16] border-[#84cc16]'
                    : data.data?.jobStatus === 5
                    ? 'text-[#e80000] border-[#e80000]'
                    : 'text-[#e80000] border-[#e80000]'
                } border rounded-full  flex items-center justify-center  text-base h-[30px] px-4 font-bold`}
              >
                {t(
                  data.data?.jobStatus === 1
                    ? 'Pennding'
                    : data.data?.jobStatus === 2
                    ? 'Inprogress'
                    : data.data?.jobStatus === 3
                    ? 'Review'
                    : data.data?.jobStatus === 4
                    ? 'Accepted'
                    : data.data?.jobStatus === 5
                    ? 'Rejected'
                    : 'Closed'
                )}
              </span>

              <span className="block font-bold text-sm">
                {data.data?.numberOfViews} {t('View')}
              </span>
              <span className="block font-bold text-sm text-[#4f46e5]">
                {data.data?.numberOfSubmissions} {t('Subscriber')}
              </span>
            </div>
          </div>
        </div>

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, errors, setFieldValue }) => (
            <>
              <Form className="py-6 px-5">
                <div className="grid grid-cols-2 gap-x-8">
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Company')}</span>
                    <Field name="jobsLngs[0].companyName" type="text" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Position')}</span>
                    <Field name="jobsLngs[0].title" type="text" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Work Type')}</span>

                    <select
                      onChange={(e) => {
                        setFieldValue('jobType', e.target.value);
                      }}
                      className="w-full   px-3 border rounded h-[42px]"
                      value={values.jobType}
                    >
                      <option value="2">{t('Full Time')}</option>
                      <option value="1">{t('Part Time')} </option>
                      <option value="3">{t('Contract')} </option>
                      <option value="4">{t('Freelance')} </option>
                    </select>
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Start Date')}</span>
                    <Field name="startDate" type="date" className="border rounded py-2 px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('End Date')}</span>
                    <Field name="lastApllied" type="date" className="border rounded py-2 px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Years of Experience')}</span>
                    <Field type="number" name="yearOfExperanice" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Email')}</span>
                    <Field type="email" name="email" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Phone')}</span>
                    <Field type="number" name="phone" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Address')}</span>
                    <Field type="text" name="jobsLngs[0].address" className="border h-[42px] rounded  px-3" />
                  </label>

                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Gender')}</span>
                    <select
                      onChange={(e) => {
                        setFieldValue('sex', e.target.value);
                      }}
                      className="w-full   px-3 border rounded h-[42px]"
                      value={values.sex}
                    >
                      <option value="1">{t('Male')}</option>
                      <option value="2">{t('Female')} </option>
                      <option value="0">{t('both')} </option>
                    </select>
                  </label>
                  <div>
                    <h3 className="text-[#666666] text-sm font-normal mb-5">{t('Location')}</h3>
                    <div className={`flex  items-center space-x-6 ${dir('', 'space-x-reverse')}`}>
                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLocation" value="1" type="radio" />
                          <span className="text-[#666666] text-base">{t('Egypt')}</span>
                        </label>
                      </div>

                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLocation" value="2" type="radio" />
                          <span className="text-[#666666] text-base">{t('Germany')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-[#666666] text-sm font-normal mb-5">{t('Language')}</h3>
                    <div className={`flex  items-center space-x-6 ${dir('', 'space-x-reverse')}`}>
                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLanguage" value="2" type="radio" />
                          <span className="text-[#666666] text-base">{t('English')}</span>
                        </label>
                      </div>

                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLanguage" value="1" type="radio" />
                          <span className="text-[#666666] text-base">{t('Arabic')}</span>
                        </label>
                      </div>
                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLanguage" value="3" type="radio" />

                          <span className="text-[#666666] text-base">{t('Deutsch')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-x-8 mt-8 mb-4">
                  <div>
                    <FieldArray
                      name="jobsLngs[0].certifications"
                      render={(arrayHelpers) => (
                        <>
                          <label className={`flex  flex-col mb-2 justify-start space-y-2`}>
                            <span className="text-[#666666] text-sm font-normal">{t('Certificates')}</span>
                            {arrayHelpers?.form?.values?.jobsLngs[0]?.certifications?.map((certifications, index) => (
                              <div key={certifications} className="relative w-full border rounded overflow-hidden">
                                <Field
                                  name={`jobsLngs[0].certifications[${index}].text`}
                                  type="text"
                                  className="w-full h-[42px] py-2 px-tline-none px-2"
                                />
                                <button
                                  onClick={() => arrayHelpers.remove(index)}
                                  type="button"
                                  className={`absolute ${dir(
                                    'right-0',
                                    'left-0'
                                  )} h-full w-[42px] bg-[#e80000] flex items-center justify-center top-0 `}
                                >
                                  <Trash className="w-5 h-5 text-white" />
                                </button>
                              </div>
                            ))}
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({ text: '' });
                            }}
                            className="text-[#84cc16] font-normal text-sm "
                          >
                            + {t('Add New')}
                          </button>
                        </>
                      )}
                    />
                  </div>

                  <div>
                    <FieldArray
                      name="jobsLngs[0].jobSkills"
                      render={(arrayHelpers) => (
                        <>
                          <label className={`flex  flex-col mb-2 justify-start space-y-2`}>
                            <span className="text-[#666666] text-sm font-normal">{t('Skills')}</span>
                            {arrayHelpers?.form?.values?.jobsLngs[0]?.jobSkills?.map((jobSkills, index) => (
                              <div key={jobSkills} className="relative w-full border rounded overflow-hidden">
                                <Field
                                  name={`jobsLngs[0].jobSkills[${index}].text`}
                                  type="text"
                                  className="w-full h-[42px] py-2 px-tline-none px-2"
                                />
                                <button
                                  onClick={() => arrayHelpers.remove(index)}
                                  type="button"
                                  className={`absolute ${dir(
                                    'right-0',
                                    'left-0'
                                  )} h-full w-[42px] bg-[#e80000] flex items-center justify-center top-0 `}
                                >
                                  <Trash className="w-5 h-5 text-white" />
                                </button>
                              </div>
                            ))}
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({ text: '' });
                            }}
                            className="text-[#84cc16] font-normal text-sm "
                          >
                            + {t('Add New')}
                          </button>
                        </>
                      )}
                    />
                  </div>
                </div>
                <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                  <span className="text-[#666666] text-sm font-normal">{t('Description')}</span>
                  <Field as="textarea" name="jobsLngs[0].description" className="border rounded py-2 px-3 h-[154px]" />
                </label>
                <div className={`space-x-4 flex items-center ${dir('', 'space-x-reverse')}`}>
                  <button
                    type="submit"
                    className="bg-darkblue w-24 flex justify-center items-center hover:bg-darkbluehover text-white px-4 py-2 rounded"
                  >
                    {t('Update')}
                  </button>

                  <button
                    type="submit"
                    className="bg-[#929292] w-24 flex justify-center items-center hover:bg-gray-400 text-white px-4 py-2 rounded"
                    onClick={() => {
                      editJobStatusMutation.mutate({
                        id: id,
                        jobStatus: 6,
                      });
                    }}
                  >
                    {t('Cancel')}
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      editJobStatusMutation.mutate({
                        id: id,
                        jobStatus: 5,
                      });
                    }}
                    className="bg-[#e80000] w-24 flex justify-center items-center hover:bg-red-500 text-white px-4 py-2 rounded"
                  >
                    {t('Deactivate')}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
