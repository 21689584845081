import { CheckIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export default function SubscriptionReplies(props) {
  const params = useParams();
  const { id } = params;
  const initialQuery = {
    langId: 1,
    id: id,
  };
  const { data, isLoading, isError } = useQuery(['GetByRepliesForSubmittion', initialQuery], GetByRepliesForSubmittion);
  if (isLoading || isError) return '';
  console.log('data', data);

  return (
    <>
      <div className="w-full">
        <ul className="divide-y divide-gray-200">
          {data.data.map((item) => (
            <li key={item.id} className="py-4">
              <div className="flex space-x-3 space-x-reverse">
                {/* <img className="h-6 w-6 rounded-full" src={item.person.imageUrl} alt="" /> */}
                <span className="h-8 w-8 rounded-full text-white  bg-[#42b72a] flex items-center justify-center">
                  <CheckIcon className=" h-6 w-6 " />
                </span>
                <div className="flex-1 space-y-1">
                  <div className="flex items-center justify-between space-x-2 space-x-reverse">
                    <h3 className="text-sm font-medium">{item.createdByFullName}</h3>
                    <p className="text-sm text-gray-500">
                      {DateTime.fromISO(item.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
                    </p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {item.reply}
                    {/* Deployed {item.project} ({item.commit} in master) to {item.environment} */}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export const GetByRepliesForSubmittion = async ({ queryKey }) => {
  const { data } = await axios.post(`/Submittion/GetByRepliesForSubmittion`, queryKey[1]);
  return data;
};
