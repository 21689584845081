import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import Trash from '../../../SharedComponents/Svg/Trash';
import { deleteFormQuestionOptions, deleteFormQuestionOptionsType } from '../ApiForms';
import Notification from '../../../SharedComponents/Notification';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

export default function DeleteFormQuestionOptionsType(props) {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { id } = props;
  const params = useParams();
  const { name } = params;
  const { mutate, data, isLoading, isError, isSuccess } = useMutation(deleteFormQuestionOptions, {
    onSuccess: () => {
      queryClient.invalidateQueries(['formQuestionOption']);
      toast(<Notification message={t('Deleted, Successfully')} />);
    },
    onError: () => {
      toast(<Notification error message={t('kindly delete the related data first')} />);
    },
  });
  const onSubmit = async () => {
    try {
      await mutate({ id });
      if (data?.isDone) {
        setIsOpen(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="bg-[#fde8e7] text-[#a93c3c] hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <Trash className="w-5 h-5" />
      </button>
      <Formik
        initialValues={{ id: id }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        <Form>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            // onSubmit={onSubmit}
            confirmTitle={t('Delete')}
            title={t('Are you sure ?')}
            isLoading={isLoading}
            isDone={data?.isDone}
          >
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('EnterName')}
              className="border rounded  w-full mb-8 p-1 hidden"
              name="id"
              type="text"
            />
            <div className="bg-white ">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0  sm:text-left">
                  <div className="">
                    <p className="text-base font-normal text-gray-400 whitespace-pre-line">
                      {t('This Data Will Be Removed, Are you sure ?')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
