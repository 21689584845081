import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import Button from '../../SharedComponents/Button/Button';
import Text from '../../SharedComponents/Inputs/components/Text';
import Modal from '../../SharedComponents/Modal/Modal';
import { PlusIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import * as Yup from 'yup';
import { DateTime } from 'luxon';

export default function PrintConfirmation(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { name, infoId, mutationData, dayData } = props;
  const isPrinted = dayData.map((e) => e.fullName).includes(mutationData.fullName);

  const sharedProps = { isOpen, setIsOpen, name, infoId, mutationData, isPrinted };
  return (
    <>
      <div className="inline-block">
        <Button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          text="طباعة"
          className="bg-blue-500 text-white px-4 mx-4 py-2 rounded-md"
        />
      </div>

      {isOpen && <ModalContainer {...sharedProps} />}
    </>
  );
}

const ModalContainer = (props) => {
  const queryClient = useQueryClient();
  const { isOpen, setIsOpen, infoId, mutationData, isPrinted } = props;
  const sharedProps = { isOpen, setIsOpen };

  const initialValues = { id: infoId };
  const addPrintingStatus = async (e) => {
    let ses = {
      statusId: e.statusId ? e.statusId : null,
      userId: e.statusId ? e.id : null,
      name: e.fullName,
      createdDatestring: DateTime.now().toISODate(),
    };

    const { data } = await axios.post(`/UserAccount/AddPrintingStatus`, ses);

    return data;
  };

  const mutation = useMutation(addPrintingStatus, {
    onSuccess: (data, values) => {
      // setPrintId(data.outId);
      // handlePrint();
      setTimeout(() => {
        const info = `?outId=${data.outId}&fullName=${values.fullName.replace(/ /g, '_')}&&status=${
          values.status?.name
        }`;
        window.open(`/information/info${info}`, '_blank');
      }, 500);
      setIsOpen(false);
    },
  });
  const onSubmit = (e) => {
    mutation.mutate(mutationData);
  };
  return (
    <>
      {isOpen && (
        <Modal modalTitle="طباعة البيانات" {...sharedProps}>
          <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ values, errors }) => {
              return (
                <Form className="rtl" dir="rtl">
                  {' '}
                  <div className="flex flex-col space-y-4">
                    {isPrinted ? (
                      <span className="text-right text-red-500 my-4">
                        برجاء العلم ان البيانات قد تم طباعتها من قبل اليوم . هل انت متأكد انك تريد الطباعة؟
                      </span>
                    ) : (
                      <span className="text-right my-4">
                        برجاء العلم ان البيانات سيتم طباعتها. هل انت متأكد انك تريد الطباعة؟
                      </span>
                    )}
                  </div>
                  <div className="flex space-x-2 space-x-reverse">
                    <Button
                      type="submit"
                      text="طباعة"
                      loading={mutation.isLoading}
                      disabled={mutation.isLoading}
                      className="bg-green-500 hover:bg-green-600 w-1/4"
                    />
                    <Button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      text="الغاء"
                      className="bg-red-500 hover:bg-red-600 w-1/4"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};

const mutationFunction = async (e) => {
  const { data } = await axios.post(`ComplainDepartment/Delete`, {
    id: e.id,
  });
  return data;
};
