import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import * as yup from 'yup';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export default function AddConsultReply(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props;
  const queryClient = useQueryClient();
  console.log('queryClient', queryClient);
  // Mutate Data to Create New Category
  //   const editCategoryMutation = useMutation(editCategory, {
  //     onSuccess: async (data) => {
  //       if (data.isDone) {
  //         queryClient.invalidateQueries(['categories']);
  //         setIsOpen(false);
  //       }
  //     },
  //   });
  const mutation = useMutation(addConsultingReplyFunction, {
    onSuccess: async (data) => {
      if (data.isDone) {
        queryClient.invalidateQueries('getConsultations');

        setIsOpen(false);
      }
    },
  });

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-100 text-blue-800 px-4 hover:bg-blue-200  p-1 rounded transition duration-300 ease-in-out"
      >
        إضافة رد
      </button>

      {isOpen && <ModalContainer id={id} mutation={mutation} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, mutation, id } = props;
  const token = localStorage.getItem('token');
  const userId = JSON.parse(token).userId;
  const { t, i18n } = useTranslation();

  const initialValues = {
    reply: '',
    consultingId: id,
    createdBy: parseInt(userId),
  };
  const onSubmit = async (e) => {
    console.log('e', e);
    try {
      mutation.mutate({ ...e, createdDatestring: DateTime.now().toISODate() });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // onSubmit={onSubmit}
                title={t('اضافة رد')}
                isLoading={mutation.isLoading}
                add={true}
              >
                {/* Submit Form */}

                <label>
                  <b className={`font-semibold block text-gray-700 ${i18n.language === 'en' ? '' : 'text-right'}`}>
                    {t('الرسالة')}
                  </b>
                  <Field className="border rounded  w-full mb-4 p-2" as="textarea" rows="5" name={`reply`} />
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const addConsultingReplyFunction = async (e) => {
  const { data } = await axios.post(`/ConsultingReply/Add`, e);

  return data;
};
