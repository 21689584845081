import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import logo1 from '../../assets/img/logo1.png';
import logo2 from '../../assets/img/logo2.png';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data, printId } = props;
  return (
    <div dir="rtl" className=" bg-white h-[842px] print p-8 px-24" ref={ref}>
      <div className="flex justify-between">
        <img src={logo1} alt="l1" />

        <img src={logo2} alt="l1" />
      </div>
      <div className="flex items-center justify-center">
        <h3 className="text-2xl underline font-bold">شهادة</h3>
      </div>
      <div className="mt-8 flex flex-col space-y-8 text-2xl font-bold">
        <div>
          <span>بناء على الطلب المقدم من السيد المهندس /</span>
          <span> {data.fullName} </span>
          <span> بتاريخ </span>
          <span> {DateTime.now().setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
        </div>
        <div>بشأن عدم قيده بسجلات الإتحاد المصري لمقاولي التشييد و البناء</div>
        <div>
          <span>
            و بعد الإطلاع على سجلات الاتحاد من قاعدة بيانات الاتحاد تبين ان المذكور غير مسجل بالإتحاد المصري لمقاولي
            التشييد و البناء حتى تاريخه
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center text-xl mt-24 underline font-bold">
        وهذه شهادة بناء على طلب المذكور دون أدنى مسئولية 
      </div>
      <div className="flex items-end justify-end mt-24 ">
        <div className="flex flex-col items-center">
          {/* <span>الاتحاد المصري</span>
          <span>لمقاولى التشييد و البناء</span> */}
          <br />
          <br />
          <br />
        </div>
      </div>
      <div>
        <span>تحريرا في : </span>
        <span> {DateTime.now().setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)} </span>
      </div>
      <div className="flex justify-center">
        <div>
          <span> مسلسل رقم : </span>
          <span> ( {printId} ) </span>
        </div>
      </div>
    </div>
  );
});

export const NegativePrintCompponent = (props) => {
  const { data } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printId, setPrintId] = useState(data.outId);

  const addPrintingStatus = async (e) => {
    const { data } = await axios.post(`/UserAccount/AddPrintingStatus`, e);

    return data;
  };
  const mutation = useMutation(addPrintingStatus, {
    onSuccess: (data) => {
      setPrintId(data.outId);
      handlePrint();
    },
  });
  const onClick = (props) => {
    // mutation.mutate({
    //   statusId: null,
    //   userId: null,
    //   name: data.fullName,
    //   createdDatestring: DateTime.now().toISODate(),
    // });
    handlePrint();
  };

  return (
    <div>
      <ComponentToPrint data={data} printId={printId} ref={componentRef} />
      <button className="bg-blue-500 text-white px-4 mx-4 py-2 rounded-md" onClick={onClick}>
        طباعة
      </button>
    </div>
  );
};
