import { UserIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export default function ComplaintReplies(props) {
  const messagesEndRef = useRef(null);
  const [messageReload, setMessageReload] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messageReload]);

  const { id } = useParams();
  const userData = localStorage.getItem('token');
  const user = userData ? JSON.parse(userData) : 0;
  const initialQuery = {
    id: parseInt(id),
  };

  const fetchcomplaintReplies = async (e) => {
    const { data } = await axios.post(`/ComplainReply/GetByComplainId`, e.queryKey[1]);
    return data;
  };

  const { data, isLoading, isError } = useQuery(['complaintReplies', initialQuery], fetchcomplaintReplies, {
    onSuccess: () => {
      setMessageReload(!messageReload);
    },
    refetchInterval: 1000,
  });
  if (isLoading) return '';
  if (isError) return '';

  return (
    <div className="bg-[#f2f2f2] h-[350px] lg:h-[460px] flex flex-col px-5 py-6 w-full overflow-x-hidden overflow-y-auto">
      {data.data.map((e, index) => {
        if (parseInt(e.userID) === parseInt(user.userId)) {
          return <Me e={e} key={index} />;
        } else {
          return <Them e={e} key={index} />;
        }
      })}
      {/* {connection && <SocketMessages connection={connection} />} */}
      <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef}></div>
    </div>
  );
}

const Me = (props) => {
  const { e } = props;
  return (
    <div className="w-full flex justify-start">
      <div className="flex items-start justify-start  pb-9 space-x-4 max-w-[250px] lg:max-w-[428px] min-w-[250px] lg:min-w-[428px] self-end flex-row-reverse ">
        <div className="w-full ">
          <div className="border-[#dfdfe0]  justify-between px-4 py-3 w-full break-words bg-white border rounded-md ">
            {e.message}
          </div>
          <div className="flex justify-between px-4 ">
            <div>{e.createdByName}</div>
            <div className="text-xs mt-2">
              {DateTime.fromISO(e.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
            </div>
          </div>
        </div>
        <div className="flex-col items-center justify-center">
          <div className="flex items-end justify-center w-8 h-8 bg-white rounded-full overflow-hidden">
            <UserIcon className="text-gray-300 rounded-full translate-y-1" />
          </div>
          {/* <h4 className='mt-2 text-center text-xs font-medium'>12:00</h4> */}
        </div>
      </div>
    </div>
  );
};

const Them = (props) => {
  const { e } = props;
  return (
    <div className="w-full flex justify-end">
      <div className="flex items-start justify-start  pb-9 space-x-4 max-w-[250px] lg:max-w-[428px] min-w-[250px] lg:min-w-[428px] self-end flex-row-reverse ">
        <div className="flex-col items-center justify-center">
          <div className="flex items-end justify-center w-8 h-8 bg-white rounded-full overflow-hidden">
            <UserIcon className="text-gray-300 rounded-full translate-y-1" />
          </div>
          {/* <h4 className='mt-2 text-center text-xs font-medium'>12:00</h4> */}
        </div>
        <div className="w-full">
          <div className="border-[#dfdfe0]  justify-between px-4 py-3 w-full break-words bg-white border rounded-md ">
            {e.message}
          </div>
          <div className="flex justify-between px-4 ">
            <div>
              <span className="font-bold text-xs text-[#26365e]">{e.createdByName}</span>
            </div>
            <div className="text-xs mt-2">
              {DateTime.fromISO(e.createDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
