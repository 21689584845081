import axios from 'axios';
import { useQuery } from 'react-query';
import { NavLink, useParams } from 'react-router-dom';
import { BeakerIcon, CloudDownloadIcon } from '@heroicons/react/solid';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import Eye from '../../SharedComponents/Svg/Eye';
import { useTranslation } from 'react-i18next';
import AgendaFlagAttended from './components/AgendaFlagAttended';
import AgendaFlagNotAttended from './components/AgendaFlagNotAttended';
import TablePagination from '../../SharedComponents/TablePagination';
import { useState } from 'react';

export default function AgendaList() {
  const params = useParams();
  const [paginationCount, setPaginationCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  function pagination(data) {
    let paginationCount = [];
    for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  const queryInitialState = {
    pageNumber: pageNumber,
    pageSize: 10,
    numberOfPages: 0,
    isPaging: true,
    langId: 1,
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(['getAgenda', queryInitialState], getAgenda, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    onSuccess: (data) => {
      pagination(data);
    },
  });
  console.log('data', data);
  if (isLoading) return '';
  if (isError) return '';

  const dataColumns = [
    {
      Header: 'التاريخ',
      accessor: 'dateString',
    },

    {
      Header: 'الوقت',
      accessor: 'agendaTime.time',
    },
    {
      Header: 'الاسم',
      accessor: 'createdByFullName',
    },
    {
      Header: 'الحالة',
      accessor: 'attended',
      Cell: ({ cell }) => {
        return (
          <>
            {cell.value === true && 'تم الحضور'}
            {cell.value === false && 'لم يتم الحضور'}
            {cell.value === null && 'لم يتم تحديد حالة'}
          </>
        );
      },
    },
    {
      Header: 'خيارات',
      accessor: 'options',
      Cell: ({ cell }) => {
        return (
          <div className="flex space-x-2 space-x-reverse">
            {cell.row.original.attended === null && (
              <>
                <AgendaFlagAttended data={cell.row.original} />
                <AgendaFlagNotAttended data={cell.row.original} />
              </>
            )}
          </div>
        );
      },
      width: 0,
      maxWidth: 10,
      sortDescFirst: true,
    },
  ];
  console.log('data', data);

  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('agenda')} />

          {/* <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <DownloadPdf languageId={languageId} />
            <DownloadExcel languageId={languageId} />
          </div> */}
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('agenda')}</h2>
        </div>

        <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
        <TablePagination
          data={data}
          paginationCount={paginationCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
}

export const getAgenda = async ({ queryKey }) => {
  const { data } = await axios.post(`/Agenda/GetAll`, queryKey[1]);
  return data;
};
