import { ArchiveIcon, ChartBarIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';

export default function SuggestionsCount(props) {
  const name = 'الاقتراحات';
  const icon = <ArchiveIcon className="w-12 h-12 bg-gray-900 p-2 rounded-full" />;
  const initialQuery = {
    fromDate: DateTime.now().startOf('month').toISODate(),
    toDate: DateTime.now().endOf('month').toISODate(),
  };

  const fetchSuggestionsCount = async (e) => {
    const { data } = await axios.post('/Suggestions/GetSuggetionsCount', e.queryKey[1]);
    return data;
  };

  const { data, isLoading, isError } = useQuery([`SuggestionsCount`, initialQuery], fetchSuggestionsCount);

  if (isLoading)
    return (
      <div className="bg-white h-32 text-center flex justify-center items-center relative rounded-md shadow border p-4">
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  if (isError) return '';
  const info = data;
  console.log('info', info);
  return (
    <>
      <div className="bg-blue-500 text-white rounded-md shadow border p-4 flex items-center ">
        <div className="flex  items-center justify-between w-full">
          <div className="flex space-x-2 items-center space-x-reverse">
            {icon}
            <span className="text-3xl">{name}</span>
          </div>
          <div>
            <span className="text-3xl">{info.outId}</span>
          </div>
        </div>
      </div>
    </>
  );
}
