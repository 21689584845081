import axios from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { BeakerIcon, CloudDownloadIcon } from '@heroicons/react/solid';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import AcceptSubmission from './components/AcceptSubmission';
import RejectSubmission from './components/RejectSubmission';
import SubscriptionReplies from './components/SubscriptionReplies';
import AddUserMemberShipId from './components/AddUserMemberShipId';
import ChangeUserStatus from './components/ChangeUserStatus';

export default function SubscriptionDataById(props) {
  const params = useParams();
  const { id } = params;
  console.log('params', id);
  const { data, isLoading, isError } = useQuery(['getSubmittionsById', { id }], getSubmittionsById);
  if (isLoading || isError) return '';

  console.log('data', data.data);
  return (
    <div>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/subscribers" name={'الطلبات'} />
      </header>
      <div className="flex justify-between bg-white p-4 rounded mb-4">
        <div className="text-2xl font-bold"> بيانات الطلب رقم {data.data.id}</div>
        <div>
          {data.data.submitionStatus === 0 && (
            <div className="text-blue-500 border-blue-500 px-4 py-1 border rounded text-sm font-normal">
              قيد المراجعة
            </div>
          )}
          {data.data.submitionStatus === 1 && (
            <div className="text-green-500 border-green-500 px-4 py-1 border rounded text-sm font-normal">
              تم القبول{' '}
            </div>
          )}
          {data.data.submitionStatus === 2 && (
            <div className="text-red-500 border-red-500 px-4 py-1 border rounded text-sm font-normal">
              تم رفض العضوية
            </div>
          )}
        </div>
      </div>
      <div className="bg-white p-4 rounded">
        <div className="grid grid-cols-2 gap-6">
          {data.data.answerSubmittions
            ?.sort((a, b) => a.question.orderNum - b.question.orderNum)
            .map((e) => {
              if (e.question.frmQuestionLngs[0].description === 'dropDown') {
                return (
                  <>
                    <div className="col-span-2 text-[#202b3d] text-xl font-bold border-b pb-4">
                      {e.question.frmQuestionLngs[0].title}
                    </div>
                  </>
                );
              } else {
                return (
                  <div className="flex flex-col space-y-2">
                    <b>{e.question.frmQuestionLngs[0].title}</b>
                    {e.questionTypeId === 4 ? <FilesContainer answer={e.answer} /> : <span>{e.answer}</span>}
                  </div>
                );
              }
            })}
          {/* href={`${window.env.REACT_APP_IMAGE_PATH}${document.fileUrl}`} */}
        </div>
      </div>
      <div className="bg-white mt-4 p-4 rounded flex space-x-2 space-x-reverse">
        <AcceptSubmission submitionUserId={data.data.userId} />
        <RejectSubmission submitionUserId={data.data.userId} />
        <AddUserMemberShipId submitionUserId={data.data.userId} />
        <ChangeUserStatus submitionUserId={data.data.userId} />
      </div>
      <div className="bg-white mt-4 p-4 rounded flex space-x-2 space-x-reverse">
        <SubscriptionReplies />
      </div>
    </div>
  );
}

const FilesContainer = (props) => {
  const { answer } = props;
  console.log('answer', answer);

  if (answer.includes('[')) {
    const parsedArray = JSON.parse(answer);
    return (
      <div className="flex space-x-2 space-x-reverse">
        {parsedArray.map((r, index) => {
          return (
            <>
              <div className="flex">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="bg-green-500 hover:bg-green-600 font-normal  text-xs text-white py-2 px-4 rounded  flex space-x-2 space-x-reverse items-center"
                  href={`${window.env.REACT_APP_IMAGE_PATH}/${r.cdnPath}`}
                >
                  <span>ملف - {index + 1}</span>
                  {/* <CloudDownloadIcon className="h-4" /> */}
                </a>
              </div>
            </>
          );
        })}
      </div>
    );
  } else {
    return (
      <>
        {' '}
        <div className="flex">
          <a
            target="_blank"
            rel="noreferrer"
            className="bg-green-500 text-white py-2 px-4 rounded  flex space-x-2 space-x-reverse items-center"
            href={`${window.env.REACT_APP_IMAGE_PATH}/${answer}`}
          >
            <span>تحميل المرفق</span>
            <CloudDownloadIcon className="h-6" />
          </a>
        </div>
      </>
    );
  }
};

export const getSubmittionsById = async ({ queryKey }) => {
  const { data } = await axios.get(`/Submittion/Get?id=${queryKey[1].id}&&langId=1`);
  return data;
};
