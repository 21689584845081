import axios from 'axios';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DeleteRequestPosition from './DeleteRequestPosition';
import EditRequestPosition from './EditRequestPosition';

export default function GetAllRequestPositions(props) {
  const params = useParams();
  const id = parseInt(params.id);
  const { data, isLoading, isError } = useQuery(
    [
      `signleTalabRquestPositions-${id}`,
      {
        pageNumber: 0,
        pageSize: 0,
        numberOfPages: 0,
        isPaging: true,
        newRequestId: id,
      },
    ],
    query
  );
  if (isLoading) return '';
  if (isError) return '';

  return (
    <>
      {data.data.map((e) => {
        return <OldInfo data={e} />;
      })}
    </>
  );
}

const OldInfo = (props) => {
  const token = localStorage.getItem('token');
  const parsedData = JSON.parse(token);
  console.log('parsedData', parsedData?.name);
  const { data } = props;
  return (
    <div className="bg-[#f3f3f3] border-r-4  border-[#4f46e5] p-4 mt-4 shadow-md">
      <div className="grid grid-cols-3 gap-1">
        <div className="font-bold">
          <span>تاريخ الموقف : </span>
          <span>{DateTime.fromISO(data.positionDateString).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
        </div>
        <div className="font-bold">
          <span>حالة الطلب : </span>
          <span>{data.requestStatus.name}</span>
        </div>
        <div className="font-bold">
          <span>جهة المتابعة : </span>
          <span>{data.requestFollowup.name}</span>
        </div>
        {/* <div className="font-bold">
          <span>المستخدم الذى سجل بيان المتابعة : </span >
          <span>{data.createdByName}</span>
        </div> */}

        <div className="col-span-3  whitespace-pre">{data.notes}</div>
        <div className="col-span-3  whitespace-pre">{data.internalNotes}</div>
      </div>
      {(parsedData?.name.toLowerCase() === 'admin' ||
        parsedData?.name.toLowerCase() === 'afia' ||
        parsedData?.outObject?.roleName === 'طلبات خاصة') && (
        <div className="flex justify-end px-4 space-x-2 rtl:space-x-reverse ">
          <EditRequestPosition mutationData={data} />
          <DeleteRequestPosition infoId={data.id} />
        </div>
      )}
    </div>
  );
};

export const query = async ({ queryKey }) => {
  const { data } = await axios.post('UserAccount/GetAllRequestPositionByRequestId', queryKey[1]);
  return data;
};
