import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTable from '../../SharedComponents/CustomTable';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import { dir } from '../../utils/localisation';
import AddNotificationToaUser from './components/AddNotificationToaUser';
import MuteToggle from './components/MuteToggle';
export default function NotificationsSettings(props) {
  const [languageId, setLanguageId] = useState(1);
  const { t } = useTranslation();

  const dataColumns = [
    {
      Header: '#',
      accessor: 'userId',
      width: 0,
      maxWidth: 10,
      sortDescFirst: true,
    },
    {
      Header: t('Notification Type'),
      accessor: 'notificationType', // accessor is the "key" in the data
      Cell: ({ cell }) => {
        // console.log('cell', cell);
        return (
          <>
            {languageId === 1 && cell.value.arName}
            {languageId === 2 && cell.value.enName}
          </>
        );
      },
    },
    {
      Header: t('Mute'),
      accessor: 'mute',
      Cell: (e) => {
        const data = e.data[e.row.index];

        return (
          <>
            <MuteToggle data={data} />
          </>
        );
      },
    },
  ];
  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs to="/NotificationsSettings" name={t('Notifications')} />

        <div className={`flex items-center space-x-3 ${dir('', 'space-x-reverse')}`}>
          {/* <DownloadPdf languageId={languageId} /> */}
          {/* <DownloadExcel languageId={languageId} /> */}
        </div>
      </header>
      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Notifications Settings')}</h2>
              {/* <h4 className="bg-move text-white py-1 px-3 rounded-md">{data.data.length}</h4> */}
            </div>

            <div className="flex transform  relative z-10 ">
              <LanguageList languageId={languageId} setLanguageId={setLanguageId} />
            </div>
          </div>
        </div>
        <div class=" px-5  mt-4">
          <div class=" flex   justify-between relative  ">
            <div class=" flex items-center space-x-3 pb-4 ">
              <AddNotificationToaUser />{' '}
            </div>
          </div>
        </div>
        <QueryContainer>
          {(props) => {
            const { data } = props;
            const queryData = data.data;

            return <CustomTable dataColumns={dataColumns} queryData={queryData} />;
          }}
        </QueryContainer>
      </div>
    </>
  );
}

const QueryContainer = (props) => {
  const initState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    isFilter: false,
    langId: 0,
    isSearch: true,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    filter: [
      {
        filterBy: 'string',
        filterType: 'string',
        dateFilteration: {
          from: 'string',
          to: 'string',
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
    searchFilter: {
      langId: 0,
      term: 'string',
    },
    optionId: 'string',
    purposeofrequest: 'string',
  };

  const { data, isLoading, isError } = useQuery(
    ['GetNotificationsTypeAllUserQuery', initState],
    GetNotificationsTypeAllUser
  );
  if (isLoading)
    return (
      <div className="relative bg-white h-72">
        <Loading />
      </div>
    );
  if (isError) return '';
  return <> {props.children({ data: data })}</>;
};

export const GetNotificationsTypeAllUser = async (e) => {
  const { data } = await axios.post('/Notifications/GetNotificationsTypeAllUser', e.queryKey[1]);
  return data;
};
