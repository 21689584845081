import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import LanguageList from '../../SharedComponents/LanguageList';
import Loading from '../../SharedComponents/Loading';
import Excel from '../../SharedComponents/Svg/Excel';
import PecnilAlt from '../../SharedComponents/Svg/PencilAlt';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';
import Print from '../../SharedComponents/Svg/Print';
import Trash from '../../SharedComponents/Svg/Trash';
import TablePaginationNew from '../../SharedComponents/TablePaginationNew';
import TableSearch from '../../SharedComponents/TableSearch';
import { dir } from '../../utils/localisation';
import { getLanguages } from '../Settings/ApiSettings';
import { GetJobs } from './api/api';
import DeleteJob from './DeleteJob';

export default function JobEmployment() {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState(2);
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchLanguage, setSearchlanguage] = useState(0);

  const [paginationCount, setPaginationCount] = useState([]);
  function pagination() {
    let paginationCount = [];
    for (var i = 1; i <= numberOfPages + 0.8; i++) {
      paginationCount.push(i);
    }
    setPaginationCount(paginationCount);
  }
  useEffect(() => {
    pagination();
  }, [numberOfPages]);
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setSearchlanguage(languageId);
  };

  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const tableHeaderar = [
    { title: languageId === 2 ? t('Recruitments ID') : 'التوظيف ID' },
    { title: languageId === 2 ? t('Company Name') : 'اسم الشركة' },
    { title: languageId === 2 ? t('Position') : 'الموقع' },
    { title: languageId === 2 ? t('Work Type') : 'نوع العمل' },
    { title: languageId === 2 ? t('Status') : 'الحالة' },
    { title: languageId === 2 ? t('Subscriptions') : 'الاشتراكات' },
    { title: languageId === 2 ? t('OPTIONS') : 'خيارات' },
  ];

  const initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    numberOfPages: numberOfPages,
    isPaging: true,
    isFilter: false,
    langId: languageId,
    newsnumber: 0,
    parentId: 0,
    checkIsRequest: false,
    isRequest: true,
    isSearch: searchTerm ? true : false,
    searchFilter: {
      langId: searchLanguage,
      term: searchTerm,
    },
  };

  const { data, isLoading, isError } = useQuery(['GetJobs', initialState], GetJobs, {
    onSuccess: (data) => {
      setNumberOfPages(data?.paginationResult?.numberOfPages);
    },
  });

  useEffect(() => {
    setNumberOfPages(data?.paginationResult?.numberOfPages);
  }, [data]);

  if (isLoading) return <Loading />;
  if (isError) return 'error';

  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name={t('Job employment')} />

        <div className={`flex items-center space-x-3 ${dir('', 'space-x-reverse')}`}>
          <button
            className={` py-2 rounded px-5 flex text-white  bg-[#7e22ce] space-x-2 items-center text-sm font-bold ${dir(
              '',
              'space-x-reverse'
            )}`}
          >
            <Print />
            <span>{t('Print')}</span>
          </button>
          <button
            className={`py-2 rounded px-5 flex text-white  bg-lightgreen space-x-2 items-center text-sm font-bold ${dir(
              '',
              'space-x-reverse'
            )}`}
          >
            <Excel />
            <span>{t('Excel')}</span>
          </button>
        </div>
      </header>

      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Recruitments')}</h2>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <div className=" px-5  mt-4">
          <div className=" flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <Link
                to="/addJobEmployment"
                className={`${dir(
                  '',
                  'space-x-reverse'
                )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
              >
                <PlusCircle className="w-5 h-5" /> <span>{t('Add New')}</span>
              </Link>
            </div>

            <div className="flex transform  relative z-10 ">
              <TableSearch onChangeEntries={onChangeEntries} entries={entries} onSearch={onSearch} />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {tableHeaderar.map((e) => {
                        return (
                          <th
                            key={e.title}
                            scope="col"
                            className={`px-6 py-3 ${dir('text-left', 'text-right')}    uppercase font-bold text-sm ${
                              e.className
                            }`}
                          >
                            {e.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {data?.data?.map((item) => (
                      <tr>
                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          {item?.id}
                        </td>

                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          {item?.jobsLngs[0]?.companyName}
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          {item?.jobsLngs[0]?.title}
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          {t(
                            item?.jobType === 2
                              ? 'Full Time'
                              : item?.jobType === 1
                              ? 'Part Time'
                              : item?.jobType === 3
                              ? 'Contract'
                              : 'Freelance'
                          )}
                        </td>
                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          <span
                            className={`${
                              item?.jobStatus === 1
                                ? 'text-[#84cc16] border-[#84cc16]'
                                : item?.jobStatus === 2
                                ? 'text-[#4f46e5] border-[#4f46e5]'
                                : item?.jobStatus === 3
                                ? 'text-[#84cc16] border-[#84cc16]'
                                : item?.jobStatus === 4
                                ? 'text-[#84cc16] border-[#84cc16]'
                                : item?.jobStatus === 5
                                ? 'text-[#e80000] border-[#e80000]'
                                : 'text-[#e80000] border-[#e80000]'
                            }  border rounded-full  text-base py-[6px] px-4 font-bold`}
                          >
                            {t(
                              item?.jobStatus === 1
                                ? 'Pennding'
                                : item?.jobStatus === 2
                                ? 'Inprogress'
                                : item?.jobStatus === 3
                                ? 'Review'
                                : item?.jobStatus === 4
                                ? 'Accepted'
                                : item?.jobStatus === 5
                                ? 'Rejected'
                                : 'Closed'
                            )}
                          </span>
                        </td>

                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          <span>{item.numberOfSubmissions}</span>
                        </td>

                        <td
                          className={`px-6 py-4 whitespace-nowrap font-bold text-base ${dir(
                            'text-left',
                            'text-right'
                          )}`}
                        >
                          <div className={`flex space-x-2 ${dir('', 'space-x-reverse')}`}>
                            <Link
                              to={`/editJobEmployment/${item.id}`}
                              className="bg-[#d1fae5] text-[#0b5a45] hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out"
                            >
                              <PecnilAlt className="w-5 h-5" />
                            </Link>
                            <DeleteJob id={item.id} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <TablePaginationNew
                  entries={entries}
                  setEntries={setEntries}
                  data={{ ...data, paging: { ...data.paginationResult } }}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
