import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import EditMenu from '../EditMenu';
import AddMenuItem from './AddMenuItem';
import { getMenu } from './ApiMenu';
import DeleteMenuItem from './DeleteMenuItem';
import EditMenuItem from './EditMenuItem';
import MenuItem from './MenuItem';
import OptionsMenuItem from './OptionsMenuItem';
import ToggleMenuItem from './ToggleMenuItem';
import { List, arrayMove } from 'react-movable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SaveMenuItemOrder from './SaveMenuItemOrder';
import { useTranslation } from 'react-i18next';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuoteList = React.memo(function QuoteList({ menuItems, languageId, activeMenu }) {
  return menuItems.map((menuItem, index) => (
    // <Quote quote={quote} index={index} key={quote.id} />
    <Fragment key={menuItem.id}>
      {menuItem && (
        <MenuItem activeMenu={activeMenu} languageId={languageId} index={index} key={menuItem.id} menuItem={menuItem} />
      )}
    </Fragment>
  ));
});

export default function Menu(props) {
  const { activeMenu, languageId, menuType } = props;
  const [state, setState] = useState({ menuItems: [] });
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  let initialState = {
    pageNumber: pageNumber,
    pageSize: entries,
    isPaging: false,
    langId: 0,
    typeId: activeMenu,
  };
  const { t, i18n } = useTranslation();
  const { data, isLoading, isError } = useQuery(['Menu', initialState], getMenu, {
    // const newMenuItems = menuItems.sort(function (a, b) {
    //   return a.orderNum - b.orderNum;
    // });
    staleTime: 0,
    cacheTime: 0,

    onSuccess: (data) => {
      setState({
        ...state,
        menuItems: data.data
          .sort(function (a, b) {
            return a.orderNum - b.orderNum;
          })
          .filter((e) => e.parentId === null || e.parentId === 0),
      });
    },
  });

  // const newMenuItems = menuItems.sort(function (a, b) {
  //   return a.orderNum - b.orderNum;
  // });

  useEffect(() => {}, [state]);
  if (isLoading)
    return (
      <div className="relative h-72">
        <Loading />
      </div>
    );
  if (isError) return 'error';

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const menuItems = reorder(state.menuItems, result.source.index, result.destination.index);

    setState({ menuItems });
  }

  const newData = state.menuItems.map((e, index) => {
    return { orderNumber: index, menuItemId: e.id };
  });
  return (
    <>
      <div className="flex justify-between mb-4">
        <div></div>
        <div className={`flex space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          <SaveMenuItemOrder state={state} />
          <EditMenu menuType={menuType} />
          <AddMenuItem languageId={languageId} activeMenu={activeMenu} />
        </div>
      </div>
      {/* {data.data.map((e) => {
        return <MenuItem languageId={languageId} key={e.id} menuItem={e} />;
      })} */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <QuoteList menuItems={state.menuItems} activeMenu={activeMenu} languageId={languageId} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
