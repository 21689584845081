import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import { dir } from '../../utils/localisation';
import Trash from '../../SharedComponents/Svg/Trash';
import { Field, Form, Formik, FieldArray } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AddJob } from './api/api';
import { toast } from 'react-toastify';
import Notification from '../../SharedComponents/Notification';
import { useHistory } from 'react-router';
import { getLanguages } from '../Settings/ApiSettings';
import LanguageList from '../../SharedComponents/LanguageList';

export default function AddJobEmployment() {
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState(2);
  const history = useHistory();

  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const queryClient = useQueryClient();
  const addJobMutation = useMutation(AddJob, {
    onSuccess: () => {
      toast(<Notification message={t('Job Added successfully')} />);
      history.goBack();
      queryClient.invalidateQueries(['GetJobs']);
    },
    onError: () => {
      toast(<Notification isSuccess={false} isError={true} message={t('error while adding')} />);
    },
  });

  const jobsLngs = data?.data?.map((language) => ({
    languageId: language.id,
    companyName: '',
    title: '',
    description: '',
    address: '',
    jobSkills: [],
    certifications: [],
  }));

  const initialValues = {
    jobType: 2,
    jobStatus: 1,
    jobLocation: 1,
    sex: 1,
    jobLanguage: 1,
    startDate: '',
    metaTags: '',
    lastApllied: '',
    phone: '',
    email: '',
    yearOfExperanice: 0,
    jobsLngs: jobsLngs,
  };

  const onSubmit = (e) => {
    const body = {
      ...e,
      lastApllied: new Date(e.lastApllied),
      startDate: new Date(e.startDate),
      jobsLngs: [
        {
          ...e.jobsLngs[0],
          jobSkills: JSON.stringify(e.jobsLngs[0].jobSkills.map((e) => e.text)),
          certifications: JSON.stringify(e.jobsLngs[0].certifications.map((e) => e.text)),
        },
      ],
    };
    addJobMutation.mutate(body);
  };
  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name={t('Job employment')} />
      </header>
      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex pb-4    justify-between relative  ">
            <div className={` flex items-center space-x-3   ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Add / Update Recruitments')}</h2>
            </div>
            <div className="flex transform  relative z-10 ">
              {data && <LanguageList languages={data?.data} languageId={languageId} setLanguageId={setLanguageId} />}
            </div>
          </div>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, errors, setFieldValue }) => (
            <>
              <Form className="py-6 px-5">
                <div className="grid grid-cols-2 gap-x-8">
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Company')}</span>
                    <Field name="jobsLngs[0].companyName" type="text" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Position')}</span>
                    <Field name="jobsLngs[0].title" type="text" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Work Type')}</span>

                    <select
                      onChange={(e) => {
                        setFieldValue('jobType', e.target.value);
                      }}
                      className="w-full   px-3 border rounded h-[42px]"
                    >
                      <option value="2">{t('Full Time')}</option>
                      <option value="1">{t('Part Time')} </option>
                      <option value="3">{t('Contract')} </option>
                      <option value="4">{t('Freelance')} </option>
                    </select>
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Start Date')}</span>
                    <Field name="startDate" type="date" className="border rounded py-2 px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('End Date')}</span>
                    <Field name="lastApllied" type="date" className="border rounded py-2 px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Years of Experience')}</span>
                    <Field type="number" name="yearOfExperanice" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Email')}</span>
                    <Field type="email" name="email" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Phone')}</span>
                    <Field type="number" name="phone" className="border h-[42px] rounded  px-3" />
                  </label>
                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Address')}</span>
                    <Field type="text" name="jobsLngs[0].address" className="border h-[42px] rounded  px-3" />
                  </label>

                  <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span className="text-[#666666] text-sm font-normal">{t('Gender')}</span>
                    <select
                      onChange={(e) => {
                        setFieldValue('sex', e.target.value);
                      }}
                      className="w-full   px-3 border rounded h-[42px]"
                    >
                      <option value="1">{t('Male')}</option>
                      <option value="2">{t('Female')} </option>
                      <option value="0">{t('both')} </option>
                    </select>
                  </label>
                  <div>
                    <h3 className="text-[#666666] text-sm font-normal mb-5">{t('Location')}</h3>
                    <div className={`flex  items-center space-x-6 ${dir('', 'space-x-reverse')}`}>
                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLocation" value="1" type="radio" />
                          <span className="text-[#666666] text-base">{t('Egypt')}</span>
                        </label>
                      </div>

                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLocation" value="2" type="radio" />
                          <span className="text-[#666666] text-base">{t('Germany')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-[#666666] text-sm font-normal mb-5">{t('Language')}</h3>
                    <div className={`flex  items-center space-x-6 ${dir('', 'space-x-reverse')}`}>
                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLanguage" value="2" type="radio" />
                          <span className="text-[#666666] text-base">{t('English')}</span>
                        </label>
                      </div>

                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLanguage" value="1" type="radio" />
                          <span className="text-[#666666] text-base">{t('Arabic')}</span>
                        </label>
                      </div>
                      <div>
                        <label className={`flex items-center space-x-2 ${dir('', 'space-x-reverse')}`}>
                          <Field name="jobLanguage" value="3" type="radio" />

                          <span className="text-[#666666] text-base">{t('Deutsch')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-x-8 mt-8 mb-4">
                  <div>
                    <FieldArray
                      name="jobsLngs[0].certifications"
                      render={(arrayHelpers) => (
                        <>
                          <label className={`flex  flex-col mb-2 justify-start space-y-2`}>
                            <span className="text-[#666666] text-sm font-normal">{t('Certificates')}</span>
                            {arrayHelpers.form.values?.jobsLngs[0].certifications.map((certifications, index) => (
                              <div key={certifications} className="relative w-full border rounded overflow-hidden">
                                <Field
                                  name={`jobsLngs[0].certifications[${index}].text`}
                                  type="text"
                                  className="w-full h-[42px] py-2 px-tline-none px-2"
                                />
                                <button
                                  onClick={() => arrayHelpers.remove(index)}
                                  className={`absolute ${dir(
                                    'right-0',
                                    'left-0'
                                  )} h-full w-[42px] bg-[#e80000] flex items-center justify-center top-0 `}
                                >
                                  <Trash className="w-5 h-5 text-white" />
                                </button>
                              </div>
                            ))}
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({ text: '' });
                            }}
                            className="text-[#84cc16] font-normal text-sm "
                          >
                            + {t('Add New')}
                          </button>
                        </>
                      )}
                    />
                  </div>

                  <div>
                    <FieldArray
                      name="jobsLngs[0].jobSkills"
                      render={(arrayHelpers) => (
                        <>
                          <label className={`flex  flex-col mb-2 justify-start space-y-2`}>
                            <span className="text-[#666666] text-sm font-normal">{t('Skills')}</span>
                            {arrayHelpers.form.values?.jobsLngs[0].jobSkills.map((jobSkills, index) => (
                              <div key={jobSkills} className="relative w-full border rounded overflow-hidden">
                                <Field
                                  name={`jobsLngs[0].jobSkills[${index}].text`}
                                  type="text"
                                  className="w-full h-[42px] py-2 px-tline-none px-2"
                                />
                                <button
                                  onClick={() => arrayHelpers.remove(index)}
                                  className={`absolute ${dir(
                                    'right-0',
                                    'left-0'
                                  )} h-full w-[42px] bg-[#e80000] flex items-center justify-center top-0 `}
                                >
                                  <Trash className="w-5 h-5 text-white" />
                                </button>
                              </div>
                            ))}
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              arrayHelpers.push({ text: '' });
                            }}
                            className="text-[#84cc16] font-normal text-sm "
                          >
                            + {t('Add New')}
                          </button>
                        </>
                      )}
                    />
                  </div>
                </div>
                <label className={`flex  flex-col mb-8 justify-start space-y-2`}>
                  <span className="text-[#666666] text-sm font-normal">{t('Description')}</span>
                  <Field as="textarea" name="jobsLngs[0].description" className="border rounded py-2 px-3 h-[154px]" />
                </label>
                <div className={`space-x-4 flex items-center ${dir('', 'space-x-reverse')}`}>
                  <button
                    type="submit"
                    className="bg-lightgreen ` flex justify-center items-center  text-white px-4 py-2 rounded"
                  >
                    {t('Add New')}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
