import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import logo1 from '../../assets/img/logo1.png';
import logo2 from '../../assets/img/logo2.png';
import CustomTableAr from '../../SharedComponents/CustomTableAr';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data, printId, dateFrom, dateTo } = props;

  const totalPages = [...Array(Math.ceil(data.data.length / 10)).keys()];

  return (
    <div dir="rtl" className=" print  hidden " ref={ref}>
      {totalPages.map((e, index) => {
        const dataColumns = [
          {
            Header: 'م',
            accessor: 'idx',
            Cell: (cell) => {
              return (
                <>
                  {index > 0 && cell.row.index !== 9 && index}
                  {cell.row.index === 9 && `${(cell.row.index + 1) * (index + 1)}`}
                  {cell.row.index !== 9 && cell.row.index + 1}
                </>
              );
            },
          },
          {
            Header: 'رقم الطلب',
            accessor: 'id',
          },
          {
            Header: 'الاسم',
            accessor: 'fullName',
            Cell: (cell) => {
              return <>{cell.value ? cell.value : cell.row.original.name}</>;
            },
          },
          {
            Header: 'الحالة',
            accessor: 'status.name',
            Cell: (cell) => {
              return <>{cell.value ? cell.value : 'غير مسجل'}</>;
            },
          },
          {
            Header: 'تاريخ الطباعة',
            accessor: 'createDate',
            Cell: (cell) => {
              return <>{DateTime.fromISO(cell.value).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</>;
            },
          },
          // {
          //   Header: 'تاريخ الشكوى',
          //   accessor: 'createDate',
          //   Cell: (cell) => {
          //     return <>{DateTime.fromISO(cell.value).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</>;
          //   },
          // },

          {
            Header: 'اسم المستخدم',
            accessor: 'createdByName',
          },
        ];
        return (
          <div style={{ height: 780, margin: 10 }} className=" bg-white overflow-hidden ">
            <div className="px-8">
              <div className="text-center">
                <h3 className="text-xl font-bold  pb-4 ">تقرير الشهادات السلبية</h3>
              </div>
              <div className="flex items-center justify-between my-4 font-bold">
                <div>
                  <span>العدد : </span>
                  <span>{data.data.length}</span>
                </div>
                <div className="flex flex-items space-x-4 rtl:space-x-reverse ">
                  <div>
                    <span>الفترة من : </span>
                    <span>{DateTime.fromISO(dateFrom).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
                  </div>
                  <div>
                    <span>إلى </span>
                    <span>{DateTime.fromISO(dateTo).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED)}</span>
                  </div>
                </div>
              </div>
            </div>
            <CustomTableAr
              dataColumns={dataColumns}
              queryData={data.data.sort((a, b) => a.id - b.id).slice(index * 10, (index + 1) * 10)}
            />
            <div className="flex justify-center items-center">
              <div className="flex space-x-2 space-x-reverse ">
                <span>صفحة</span>
                <span>( {index + 1} )</span>
                <span>من</span>
                <span>( {totalPages.length} )</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export const PrintInformationList = (props) => {
  const { dateFrom, dateTo } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [printId, setPrintId] = useState();

  const queryInitialState = {
    pageNumber: 0,
    pageSize: 10,
    isPaging: false,
    langId: 1,
    isFilter: true,

    filter: [
      {
        filterBy: 'datefromto',
        filterType: 'string',
        dateFilteration: {
          from: dateFrom,
          to: dateTo,
        },
        searchTerm: [
          {
            langId: 0,
            term: 'string',
          },
        ],
      },
    ],
  };
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery([`getInformationListx`, queryInitialState], getInformationList, {
    onSuccess: (data) => {},
  });
  if (isLoading) return '';
  if (isError) return '';
  return (
    <div>
      <ComponentToPrint dateFrom={dateFrom} dateTo={dateTo} data={data} printId={printId} ref={componentRef} />
      <button className="bg-blue-500 text-white px-4 mx-4 py-2 rounded-md" onClick={handlePrint}>
        طباعة
      </button>
    </div>
  );
};

export const getInformationList = async ({ queryKey }) => {
  const { data } = await axios.post(`/UserAccount/GetAllPrintingStatusAduit`, queryKey[1]);
  return data;
};
