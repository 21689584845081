import axios from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

export default function ConsultingFAQ(props) {
  const { consult, id } = props;
  const [activeToggle, setActiveToggle] = useState(consult.faq);
  console.log(consult, 'setActiveToggle');

  const editConsultingMutation = useMutation(editConsulting, {
    onSuccess: async (data) => {
      if (data.isDone) {
        setActiveToggle((e) => !e);
      }
    },
  });

  const handleClick = () => {
    const newData = {
      id: consult.id,
      message: consult.message,
      faq: !activeToggle,
      //   consultingReplies: consult.consultingReplies,
    };
    editConsultingMutation.mutate(newData);
  };
  return (
    <button onClick={handleClick} className="flex items-center cursor-pointer focus:outline-none">
      {/* <!-- toggle --> */}
      <div className="relative">
        {/* <!-- line --> */}
        <div className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}></div>
        {/* <!-- dot --> */}
        <div
          className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
            activeToggle ? 'right-0' : 'left-0'
          } `}
        ></div>
      </div>
      {/* <!-- label --> */}
    </button>
  );
}

const editConsulting = async (e) => {
  const { data } = await axios.post(`/consulting/Edit`, e);

  return data;
};
