import axios from 'axios';

export const AddJob = async (e) => {
  const { data } = await axios.post('/Jobs/AddJob', e);
  return data;
};

export const GetJobs = async (e) => {
  const { data } = await axios.post('/Jobs/GetAllJobs', e.queryKey[1]);
  return data;
};

export const deleteJob = async (e) => {
  const { data } = await axios.post('/Jobs/DeleteJob', e);
  return data;
};

export const GetJob = async (e) => {
  const { data } = await axios.get(`/Jobs/GetJobToCms?id=${e.queryKey[1].id}&langId=${e.queryKey[1].langId}`);
  return data;
};
export const ChangeJobStatus = async (e) => {
  const { data } = await axios.post('/Jobs/CloseJob', e);
  return data;
};

export const editJob = async (e) => {
  const { data } = await axios.post('/Jobs/EditJob', e);
  return data;
};
