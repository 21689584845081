import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from 'react-query';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './localization/ar';
import en from './localization/en';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 } } });
// localStorage.getItem('lang') === 'en' ? 'en' : 'ar'
i18n.use(initReactI18next).init({
  resources: {
    ar: {
      translation: ar,
    },
    en: {
      translation: en,
    },
  },
  supportedLngs: ['en', 'ar'],
  lng: localStorage.getItem('lang') === 'en' ? 'en' : 'ar',
  // fallbackLng: localStorage.getItem('lang') === 'ar' ? 'ar' : 'en',
  interpolation: {
    escapeValue: false,
  },
});
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
