import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import { dir } from '../../utils/localisation';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useQuery } from 'react-query';
import axios from 'axios';
import EditVisit from '../Calendar/EditVisit';

export default function Calendar() {
  const { t } = useTranslation();
  const ref = useRef();
  const userData = localStorage.getItem('token');
  const userId = userData ? JSON.parse(userData).userId : 0;
  function renderEventContent(eventInfo) {
    return <EditVisit eventInfo={eventInfo} />;
  }

  const { data, isLoading, isError } = useQuery(['GetMyCalenders', { id: userId }], GetAllCalendersByEmployeeId);
  useEffect(() => {
    console.log('reloaded');
  }, [data]);
  if (isLoading) return 'loading';
  if (isError) return 'error';

  const INITIAL_EVENTS = data.data.map((event) => {
    return {
      id: event.id,
      title: JSON.stringify({
        time: event.visitTime,
        visitorArName: event.visitorArName,
        visitorEnName: event.visitorEnName,
        employeeName: 'employee',
        data: event,
      }),
      allDay: false,
      start: `${event.visitDate}T${event.visitTime}:00`,

      // start: event.visitDate,
    };
  });

  const handleDateSelect = (selectInfo) => {};

  const handleEventClick = (clickInfo) => {
    console.log(clickInfo, 'handleEventClick');
  };
  const handleEvents = (clickInfo) => {
    console.log(clickInfo, 'handleEvents');
  };
  console.log(ref);
  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <BreadCrumbs to="/mycalender" name={t('My Calender')} />
      </header>
      <div className="bg-white rounded border shadow-md p-4 ">
        <div className="p-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Calendar')}</h2>
            </div>
          </div>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          ref={ref}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialEvents={INITIAL_EVENTS}
          eventContent={renderEventContent}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: true,
          }}
          weekends={true}
          editable={false}
          selectable={false}
          selectMirror={false}
          dayMaxEvents={true}
          // select={handleDateSelect}
          // eventClick={handleEventClick}
          // eventsSet={handleEvents}
        />
      </div>
    </>
  );
}

export const GetAllCalendersByEmployeeId = async (e) => {
  const { id } = e.queryKey[1];
  const { data } = await axios.get(`/FrmAnswer/GetAllCalendersByEmployeeId?EmpId=${id}`);
  return data;
};
