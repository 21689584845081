import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import successfully from '../../assets/img/successfully.svg';
import email from '../../assets/img/email.svg';
import close from '../../assets/img/close.svg';
import bell from '../../assets/img/bell.svg';
import { dir } from '../../utils/localisation';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import Loading from '../../SharedComponents/Loading';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import { DateTime } from 'luxon';
import LanguageList from '../../SharedComponents/LanguageList';
import { getLanguages } from '../Page/Pages/ApiPage';
import { Link } from 'react-router-dom';
import PlusCircle from '../../SharedComponents/Svg/PlusCircle';

export default function ViewNotifications() {
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState(2);

  const initState = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
    isFilter: false,
    searchFilter: {
      langId: 0,
      term: '1',
    },
  };
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(['GetNotificationsByUserId', initState], GetNotificationsByUserId, {
    onSuccess: () => {
      queryClient.invalidateQueries(['GetNotificationsCount']);
    },
  });
  if (isLoading) return <Loading />;
  if (isError) return 'error';
  console.log('data', data);
  return (
    <>
      <header className="flex justify-between items-center mb-8">
        <BreadCrumbs name={t('Notifications')} />
        <div>
          <Link
            to={`/MyNotificationsSettings`}
            className={`${dir(
              '',
              'space-x-reverse'
            )} bg-lightgreen space-x-1   text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
          >
            <span>{t('Change Notifications Settings')}</span>
          </Link>
        </div>
      </header>
      <div className="bg-white rounded-xl border shadow-md overflow-hidden">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{t('Notifications')}</h2>
              <h4 className="bg-move text-white py-1 px-3 rounded-md">{data.data.length}</h4>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <section className=" p-5 ">
          <div className="flex flex-col">
            {data.data.map((notification, index) => {
              return (
                <div key={index} className=" flex flex-col py-4 lg:flex-row">
                  <div className="bg-[#84cc16] flex items-center justify-center mb-4 mx-auto w-11 h-11 rounded-full lg:mb-0 lg:mx-0">
                    <img src={successfully} alt="Successfully" />
                  </div>
                  <div className={dir('pl-4', 'pr-4')}>
                    {languageId === 2 && (
                      <h3 className="text-[#666666] text-sm font-semibold">{notification.englishMessage} </h3>
                    )}
                    {languageId === 1 && (
                      <h3 className="text-[#666666] text-sm font-semibold">{notification.arabicMessage} </h3>
                    )}
                    {/* <h3 className="text-[#666666] text-sm font-semibold">{notification.germanMessage} </h3> */}
                  </div>
                  <p className={`text-[#999999] ${dir('ml-auto', 'mr-auto')} text-xs`}>
                    {dir(
                      `${DateTime.fromISO(notification.arrivalTime).setLocale('').toFormat('dd LLL yyyy')}`,
                      `${DateTime.fromISO(notification.arrivalTime).setLocale('ar-EG').toFormat('dd LLL yyyy')}`
                    )}
                  </p>
                </div>
              );
            })}

            {/* <div className="border-[#7070701f] flex flex-col py-4 border-b lg:flex-row">
              <div className="bg-[#4f46e5] flex items-center justify-center mb-4 mx-auto w-11 h-11 rounded-full lg:mb-0 lg:mx-0">
                <img src={email} alt="email" />
              </div>
              <div className={dir('pl-4', 'pr-4')}>
                <h3 className="text-[#666666] text-sm font-semibold">
                  تم قبول الشكوي المقدمة في البداية ، حيث ستقام من خلال الاتصال مباشرة خلال يومين عمل
                </h3>
                <h4 className="text-[#666666] text-sm">محمد أشرف</h4>
              </div>
              <p className={`text-[#999999] ${dir('ml-auto', 'mr-auto')} text-xs`}> سبتمبر 2021 04</p>
            </div>

            <div className="border-[#7070701f] flex flex-col py-4 border-b lg:flex-row">
              <div className="bg-[#dd0000] flex items-center justify-center mb-4 mx-auto w-11 h-11 rounded-full lg:mb-0 lg:mx-0">
                <img src={close} alt="email" />
              </div>
              <div className={dir('pl-4', 'pr-4')}>
                <h3 className="text-[#666666] text-sm font-semibold">
                  تم قبول الشكوي المقدمة في البداية ، حيث ستقام من خلال الاتصال مباشرة خلال يومين عمل
                </h3>
                <h4 className="text-[#666666] text-sm">محمد أشرف</h4>
              </div>
              <p className={`text-[#999999] ${dir('ml-auto', 'mr-auto')} text-xs`}> سبتمبر 2021 04</p>
            </div>
            <div className="border-[#7070701f] flex flex-col py-4 border-b lg:flex-row">
              <div className="bg-[#f59e0b] flex items-center justify-center mb-4 mx-auto w-11 h-11 rounded-full lg:mb-0 lg:mx-0">
                <img src={bell} alt="email" />
              </div>
              <div className={dir('pl-4', 'pr-4')}>
                <h3 className="text-[#666666] text-sm font-semibold">
                  تم قبول الشكوي المقدمة في البداية ، حيث ستقام من خلال الاتصال مباشرة خلال يومين عمل
                </h3>
                <h4 className="text-[#666666] text-sm">محمد أشرف</h4>
              </div>
              <p className={`text-[#999999] ${dir('ml-auto', 'mr-auto')} text-xs`}> سبتمبر 2021 04</p>
            </div>

            <div className="border-[#7070701f] flex flex-col py-4 border-b border-t lg:flex-row">
              <div className="bg-[#84cc16] flex items-center justify-center mb-4 mx-auto w-11 h-11 rounded-full lg:mb-0 lg:mx-0">
                <img src={successfully} alt="Successfully" />
              </div>
              <div className={dir('pl-4', 'pr-4')}>
                <h3 className="text-[#666666] text-sm font-semibold">
                  تم قبول الشكوي المقدمة في البداية ، حيث ستقام من خلال الاتصال مباشرة خلال يومين عمل
                </h3>
                <h4 className="text-[#666666] text-sm">محمد أشرف</h4>
              </div>
              <p className={`text-[#999999] ${dir('ml-auto', 'mr-auto')} text-xs`}> سبتمبر 2021 04</p>
            </div>*/}
          </div>
        </section>
      </div>
    </>
  );
}

export const GetNotificationsByUserId = async (e) => {
  const body = e.queryKey[1];
  const { data } = await axios.post('Notifications/GetNotificationsByUserId', body);
  return data;
};
