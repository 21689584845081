import { CheckCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import { useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import Button from '../../SharedComponents/Button/Button';
import DynamicPrinting from '../../SharedComponents/DynamicPrinting';
import SelectV2 from '../../SharedComponents/Inputs/components/SelectV2';
import Notification from '../../SharedComponents/Notification';
import GetAllRequestComments from './GetAllRequestComments';
import GetAllRequestPositions from './GetAllRequestPositions';
import SubmitComment from './SubmitComment';
import SubmitStatus from './SubmitStatus';
import { fakeOrders, followUpOffice, fromselectList, serviceselectList, talabStatus } from './talabatlists';

export default function SingleTalab(props) {
  const componentRef = useRef(null);

  const initialValues = {
    memeberId: '12',
  };
  const generalDataQueries = useQueries([
    { queryKey: ['comingfrom'], queryFn: comingFromFunction },
    { queryKey: ['services'], queryFn: servicesunction },
    { queryKey: ['followup'], queryFn: followUpFunction },
    { queryKey: ['requestStatus'], queryFn: requestStatus },
  ]);
  const params = useParams();
  const id = parseInt(params.id);
  const query = async ({ queryKey }) => {
    const { data } = await axios.get(`UserAccount/GetNewRequest?id=${id}`);
    return data;
  };
  const { data, isLoading, isError } = useQuery([`singleTalab-${id}`], query, {
    // onSuccess: (data) => {
    // 	pagination(data);
    // },
  });

  if (isLoading) return '';
  if (isError) return '';

  return (
    <>
      <BreadCrumbs generalRoute="طلبات" generalRouteTo="/talabat" name="بيانات الطلب" />
      <div ref={componentRef} dir="rtl" className="p-4 print:mpx-8 border shadow rounded-lg bg-white mt-8">
        <div className="border-b pb-4 flex justify-between items-center">
          <span className="font-bold  text-xl text-[#003eef]">بيانات الطلب</span>
          <DynamicPrinting componentRef={componentRef} />
        </div>
        <NewMemeberRequest data={data.data} />
        <div className="border-b pb-4 mt-4">
          <span className="font-bold text-xl text-[#003eef]">موقف الطلب</span>
        </div>
        <SubmitStatus />

        {data.data.requestPositions.length > 0 ? (
          <GetAllRequestPositions />
        ) : (
          <span className="mt-4 block">مازال الطلب قيد المراجعة</span>
        )}
        <div className="border-b pb-4 mt-4">
          <span className="font-bold  text-xl text-[#003eef]">الشكاوي / الاقتراحات</span>
        </div>
        <SubmitComment />
        {data.data.newRequestComments.length > 0 ? (
          <GetAllRequestComments />
        ) : (
          <span className="mt-4 block">لا توجد شكاوي / اقتراحات</span>
        )}
      </div>
    </>
  );
}

const NewMemeberRequest = (props) => {
  const queryClient = useQueryClient();
  const comingFromData = queryClient.getQueryData(`comingfrom`);
  const servicesData = queryClient.getQueryData(`services`);
  const memeberinitialValues = {
    ...props.data,
    recievedDate: DateTime.fromISO(props.data.receivedDate).setLocale('ar-eg').toLocaleString(DateTime.DATE_MED),
  };

  return (
    <>
      <div>
        <Formik initialValues={memeberinitialValues}>
          {({ values, errors }) => {
            return (
              <>
                <div className="grid grid-cols-2 gap-6 mt-4">
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}>رقم الطلب</span>
                      <Field disabled name="id" className="border rounded py-2 px-3" type="number" />
                    </label>
                  </div>
                  {values.memberShipNumber && (
                    <div className="">
                      <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                        <span className={`font-bold text-base text-right rtl:text-right `}>رقم العضوية</span>
                        <Field disabled name="memberShipNumber" className="border rounded py-2 px-3" type="number" />
                      </label>
                    </div>
                  )}
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}>كلمة السر</span>
                      <Field disabled name="password" className="border rounded py-2 px-3" type="text" />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> تاريخ استلام الطلب</span>
                      <Field disabled name="recievedDate" className="border rounded py-2 px-3" />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}>اسم الشركة</span>
                      <Field disabled name="companyName" className="border rounded py-2 px-3" />
                    </label>
                  </div>
                  {values.memeberId && (
                    <div className="">
                      <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                        <span className={`font-bold text-base text-right rtl:text-right `}> رقم العضوية</span>
                        <Field disabled name="memeberId" className="border rounded py-2 px-3" type="number" />
                      </label>
                    </div>
                  )}
                  <div className="">
                    <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right  `}> وارد من *</span>
                      <SelectV2
                        selectList={comingFromData?.data}
                        loading={comingFromData === undefined}
                        disabled
                        wrapperClass="mt-0"
                        errors={errors}
                        name="commingFromId"
                      />
                    </label>
                  </div>
                  <div className="">
                    <label className={`flex flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> الخدمة *</span>
                      <SelectV2
                        selectList={servicesData?.data}
                        loading={servicesData === undefined}
                        disabled
                        wrapperClass="mt-0"
                        errors={errors}
                        name="serviceId"
                      />
                    </label>
                  </div>
                  <div className="col-span-2">
                    <label className={`flex  flex-col justify-start rtl:justify-end space-y-2`}>
                      <span className={`font-bold text-base text-right rtl:text-right `}> ملاحظات</span>
                      <Field name="notes" disabled className="border rounded py-2 px-3" as="textarea" />
                    </label>
                  </div>
                  {/* {!confirmation && (
                    <div className="flex space-x-2 rtl:space-x-reverse">
                      <div>
                        <Button
                          type="button"
                          onClick={() => {
                            setConfirmation(true);
                          }}
                          className="px-10"
                        />
                      </div>
                      <div>
                        <Button text="الغاء" className="bg-red-500 hover:bg-red-400 px-10" />
                      </div>
                    </div>
                  )} */}
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const comingFromFunction = async (e) => {
  const noPagination = {
    pageNumber: 1,
    pageSize: 100,
    isPaging: false,
  };
  const { data } = await axios.post(`UserAccount/GetAllCommingFrom`, noPagination);
  return data;
};
const servicesunction = async (e) => {
  const noPagination = {
    pageNumber: 1,
    pageSize: 100,
    isPaging: false,
  };
  const { data } = await axios.post(`UserAccount/GetAllService`, noPagination);
  return data;
};

const followUpFunction = async (e) => {
  const noPagination = {
    pageNumber: 1,
    pageSize: 100,
    isPaging: false,
  };
  const { data } = await axios.post(`UserAccount/GetAllRequestFollowup`, noPagination);
  return data;
};
const requestStatus = async (e) => {
  const noPagination = {
    pageNumber: 1,
    pageSize: 100,
    isPaging: false,
  };
  const { data } = await axios.post(`UserAccount/GetAllRequestStatus`, noPagination);
  return data;
};
