import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from '../../../SharedComponents/OutsideClickHandler';
import ChevronDown from '../../../SharedComponents/Svg/ChevronDown';
import { dir } from '../../../utils/localisation';

export default function UsersList(props) {
  const { errors, values, loading, error, list, FieldValue, setFieldValue, FieldName, label, setBranchId } = props;
  console.log('list', list);
  let defaultValue = list?.find((e) => e.id === FieldValue);
  const [value, setValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParam, setSearchParam] = useState(defaultValue ? defaultValue?.userName : '');
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  const { t, i18n } = useTranslation();
  const onClick = (data) => {
    setFieldValue(FieldName, data.id);
    // setBranchId(data.id);
    setSearchParam(data.userName);
    setIsOpen(false);
  };

  return (
    <OutsideClickHandler setIsOpen={setIsOpen}>
      <label className={`text-[#303030] mb-1 text-xs ${dir('', 'text-right')}`}>{label ? label : t('Users')}</label>
      <input
        autoComplete="off"
        name="userId"
        type="text"
        className={`border-[#f1f1f1] bg-[#fafbfe] text-[#758081] px-3 py-2 w-full text-sm border rounded-md outline-none `}
        // defaultvalue={defaultValue.name}
        value={searchParam}
        onChange={onSearchParam}
        onFocus={() => {
          setIsOpen(true);
        }}
      />
      <ChevronDown className={`text-[##758081] absolute ${dir('right-0 mr-4', 'left-0 ml-4')}  top-0  mt-9 w-4 h-4`} />
      <div
        className={` absolute bottom-0 z-10 left-0 ${
          isOpen ? 'block' : 'hidden'
        }   pl-0 w-full bg-white transform translate-y-full `}
      >
        <ul
          className="mt-2 max-h-64 text-sm border rounded divide-y overflow-y-scroll"
          style={{
            direction: dir('rtl', 'ltr'),
          }}
        >
          {list
            .filter((e) => e?.userName?.toLowerCase().includes(searchParam?.toLowerCase()))
            .map((e) => {
              return (
                <button
                  key={e.id}
                  onClick={(x) => {
                    onClick(e);
                  }}
                  className={`block px-2 py-1 w-full text-left hover:bg-gray-50 ${dir('text-left', 'text-right')}`}
                  type="button"
                >
                  {e.userName}
                </button>
              );
            })}
        </ul>
      </div>
    </OutsideClickHandler>
  );
}
