import { Field } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPages } from '../ApiSections';

export default function CollapsePagesList(props) {
  const { languageId, setActivePage, setFieldValue, isExternalLink } = props;
  const [isOpen, setIsOpen] = useState(false);
  // Query Data To Fetch Languages
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery('pages', getPages, {
    staleTime: 360000000,
  });
  if (isLoading) return '';
  if (isError) return 'error';

  const onClick = (data) => {
    data.pgPageLngs.map((e, index) => setFieldValue(`pgSectionCollapseItemsLngs[${index}].buttonTitle`, e.title));
    setFieldValue('toLinkUrl', data.uniqueUrl);
    setFieldValue('pageId', data.id);
    setIsOpen(false);
    // const newData = {
    //   uniqueUrl: data.uniqueUrl,
    //   pgPageLngs: data.pgPageLngs,
    // };
    // setActivePage(newData);
  };
  return (
    <>
      <label>
        <Field
          className="border rounded  w-full  p-2"
          type="text"
          placeholder={t('Link Url')}
          name="toLinkUrl"
          onFocus={() => {
            setIsOpen(true);
          }}
          // onBlur={() => {
          //   setIsOpen(false);
          // }}
        />
      </label>
      {(isExternalLink === 'false' || isExternalLink === false) && isOpen && (
        <div className="absolute bottom-0 bg-white w-full transform translate-x-full pl-0 block right-0 -mr-6">
          {t('Pages')}
          <ul className="border rounded divide-y overflow-y-scroll max-h-40">
            {data.data.map((e) => {
              return (
                <Fragment key={e.id}>
                  <button
                    onClick={(x) => {
                      onClick(e);
                    }}
                    type="button"
                    className=" w-full text-left block px-2 py-1 hover:bg-darkbluehover hover:text-white"
                  >
                    {e.pgPageLngs.map((x) => {
                      return <>{languageId === x.languageId && x.title}</>;
                    })}
                  </button>
                </Fragment>
              );
            })}
          </ul>
        </div>
      )}{' '}
    </>
  );
}
