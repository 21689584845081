import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../SharedComponents/Modal';
import PlusCircle from '../../../../SharedComponents/Svg/PlusCircle';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../../SharedComponents/Loading';
import * as yup from 'yup';
import { addCardItem, getLanguages } from '../ApiSections';
import AddCardImage from './AddCardImage';
import CardPagesList from './CardPagesList';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../../SharedComponents/LanguageList';

export default function AddCardItem(props) {
  const { id } = props.id ? props : 1;
  const { typeId, activeMenu, orderNum, addCardItemMutation, pgSectionCardId } = props;
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-lightgreen space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5" /> <span>{t('Add New Card Item')}</span>
      </button>

      {isOpen && (
        <ModalContainer
          id={id}
          typeId={typeId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          activeMenu={activeMenu}
          orderNum={orderNum}
          addCardItemMutation={addCardItemMutation}
          pgSectionCardId={pgSectionCardId}
        />
      )}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId, activeMenu, orderNum, addCardItemMutation, pgSectionCardId } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { t, i18n } = useTranslation();
  const imagePositions = [
    { id: 0, name: 'Top' },
    { id: 1, name: 'Bottom' },
    { id: 2, name: 'Left' },
    { id: 3, name: 'Right' },
  ];
  // Query Data To Fetch Languages
  const { data, isLoading, isError } = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [data]);

  const [activePage, setActivePage] = useState();
  // create a loop for localisation
  const pgSectionCardItemLngs = data?.data?.map((language) => ({
    languageId: language.id,
    title: '',
    description: '',
    btnTitle: '',
  }));
  const initialValues = {
    imagePositionId: 0,
    date: '2021-04-20T16:59:25.114Z',
    isExternalLink: 'true',
    toLinkUrl: '',
    imageUrl: '',
    orderNum: orderNum,
    pgSectionCardId: pgSectionCardId,
    pgSectionCardItemLngs: pgSectionCardItemLngs,
  };

  const onSubmit = async (e) => {
    const newData = {
      ...e,
      isExternalLink: e.isExternalLink === 'true' || e.isExternalLink === true ? true : false,
    };
    try {
      await addCardItemMutation.mutate(newData);
      setIsOpen(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {}, [activePage]);
  return (
    <>
      {data && data.data && (
        <Formik
          // onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Modal
                  className="sm:max-w-3xl"
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  onSubmit={() => onSubmit(values)}
                  title={t('Add Item')}
                  isLoading={addCardItemMutation.isLoading}
                  add={true}
                  submitType="button"
                >
                  {isLoading && <Loading />}
                  {isError && 'error'}
                  {data && (
                    <LanguageList
                      languages={data?.data}
                      languageId={languageId}
                      setLanguageId={setLanguageId}
                      className="flex transform translate-y-1 relative z-10 mb-4"
                    >
                      <hr className="border w-full absolute bottom-0 left-0 z-0" />
                    </LanguageList>
                  )}

                  {/* Submit Form */}
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <div className="h-52 focus:outline-none group rounded border-dashed border-2 mb-2 relative">
                        {(values.imageUrl && (
                          <>
                            <div className="flex items-center justify-center h-full p-2">
                              <img
                                className="w-full h-full object-contain"
                                alt="img"
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageUrl}`}
                              />{' '}
                            </div>
                            <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                              <AddCardImage edit={true} setField={setFieldValue} fieldName={t('imageUrl')} />
                            </div>
                          </>
                        )) || (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <AddCardImage fieldName={t('imageUrl')} setField={setFieldValue} />
                          </div>
                        )}
                      </div>

                      <Field
                        className="border rounded p-2 w-full mt-4"
                        as="select"
                        name="card.nextBtnTypeId"
                        onChange={(e) => {
                          setFieldValue('card.nextBtnTypeId', parseInt(e.target.value));
                        }}
                      >
                        {imagePositions.map((imagePosition, index) => (
                          <option value={parseInt(imagePosition.id)} key={imagePosition.id}>
                            {t(imagePosition.name)}
                          </option>
                        ))}
                      </Field>
                      <div className="mt-4">
                        {values &&
                          values.pgSectionCardItemLngs &&
                          values?.pgSectionCardItemLngs.map((pgSectionCardItemLng, index) => (
                            <FieldArray
                              key={index}
                              name="pgSectionCardItemLngs"
                              render={(arrayHelpers) => (
                                <>
                                  {languageId === pgSectionCardItemLng.languageId && (
                                    <>
                                      <label>
                                        <Field
                                          className="border rounded  w-full  p-2"
                                          type="text"
                                          placeholder={t('Button Title')}
                                          name={`pgSectionCardItemLngs[${index}].btnTitle`}
                                        />
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                            />
                          ))}
                      </div>
                      <div className="mt-4 relative">
                        <CardPagesList setFieldValue={setFieldValue} languageId={languageId} />
                      </div>
                    </div>
                    <div>
                      {values &&
                        values.pgSectionCardItemLngs &&
                        values?.pgSectionCardItemLngs.map((pgSectionCardItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionCardItemLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionCardItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        type="text"
                                        placeholder={t('Add Title')}
                                        name={`pgSectionCardItemLngs[${index}].title`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                      <label className={`my-4 block `}>
                        <Field className="w-full border p-2 rounded " type="date" name="date" />
                      </label>

                      {values &&
                        values.pgSectionCardItemLngs &&
                        values?.pgSectionCardItemLngs.map((pgSectionCardItemLng, index) => (
                          <FieldArray
                            key={index}
                            name="pgSectionCardItemLngs"
                            render={(arrayHelpers) => (
                              <>
                                {languageId === pgSectionCardItemLng.languageId && (
                                  <>
                                    <label>
                                      <Field
                                        className="border rounded  w-full  p-2"
                                        as="textarea"
                                        rows="3"
                                        placeholder={t('Add Description')}
                                        name={`pgSectionCardItemLngs[${index}].description`}
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}
                          />
                        ))}
                      <div className="grid grid-cols-2 space-x-2 mt-4 ">
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="radio" name="isExternalLink" value="true" className="w-4 h-4" />
                          <span>{t('External Link')}</span>
                        </label>
                        <label
                          className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                        >
                          <Field type="radio" name="isExternalLink" value="false" className="w-4 h-4" />
                          <span>{t('Pages')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <label className="mt-2 block">
                    <Field
                      type="number"
                      name="orderNum"
                      placeholder="Order Number"
                      className="border rounded  w-full  p-2"
                    />
                  </label>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
