import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import BreadCrumbs from '../../SharedComponents/BreadCrumbs';
import CustomTableAr from '../../SharedComponents/CustomTableAr';
import AddUserStatus from './components/AddUserStatus';
import DeleteUserStatus from './components/DeleteUserStatus';
import EditUserStatus from './components/EditUserStatus';

export default function UserStatuses(props) {
  const { t } = useTranslation();
  const initialQuery = {
    pageNumber: 0,
    pageSize: 0,
    numberOfPages: 0,
    isPaging: true,
  };
  const fetchUserStatuses = async (e) => {
    const { data } = await axios.post(`/UserAccount/GetAllUserStatus`, e.queryKey[1]);
    return data;
  };

  const { status, data, error, refetch, isLoading, isError } = useQuery(
    ['UserStatuses', initialQuery],
    fetchUserStatuses
  );

  if (isLoading) return '';
  if (isError) return '';

  const dataColumns = [
    {
      Header: 'الاسم',
      accessor: 'name',
    },
    {
      Header: 'خيارات',
      accessor: 'xxx',
      Cell: (cell) => {
        return (
          <div className="flex space-x-2 space-x-reverse">
            <EditUserStatus item={cell.row.original} />
            <DeleteUserStatus id={cell.row.original.id} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="">
        <header className="flex justify-between items-center mb-8">
          <BreadCrumbs name={t('UserStatuses')} />

          <div
            className={`flex items-center space-x-3 
          `}
          >
            <AddUserStatus />
          </div>
        </header>
        <div className="my-6">
          <h2 className="text-2xl font-bold">{t('UserStatuses')}</h2>
        </div>

        <CustomTableAr dataColumns={dataColumns} queryData={data.data} />
      </div>
    </>
  );
}
