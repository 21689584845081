import { useQuery } from 'react-query';

import { Fragment, useEffect, useState } from 'react';
import Loading from '../../../SharedComponents/Loading';
import ChevronLeft from '../../../SharedComponents/Svg/ChevronLeft';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import { useParams } from 'react-router-dom';
import DeleteFormQuestionOptions from './DeleteFormQuestionOptions';
import { getFormQuestionOption, getLanguages } from '../ApiForms';
import CreateFormQuestionOptions from './CreateFormQuestionOptions';
import EditFormQuestionOptions from './EditFormQuestionOptions';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import TablePagination from '../../../SharedComponents/TablePagination';
import LanguageList from '../../../SharedComponents/LanguageList';
import Item from '../../Page/Sections/Item';
import TableSearch from '../../../SharedComponents/TableSearch';
import DownloadExcel from './DownloadExcel';
import DownloadPdf from './DownloadPdf';
import { dir } from '../../../utils/localisation';

const ToggleButton = (props) => {
  const { statusId } = props;
  const [activeToggle, setActiveToggle] = useState(statusId);
  return (
    <button
      onClick={() => {
        setActiveToggle(!activeToggle);
      }}
      className="flex items-center cursor-pointer focus:outline-none"
    >
      {/* <!-- toggle --> */}
      <div className="relative">
        {/* <!-- line --> */}
        <div className={` w-12 h-6 ${activeToggle ? 'bg-lightgreen' : 'bg-gray-400'}  rounded-full shadow-inner`}></div>
        {/* <!-- dot --> */}
        <div
          className={`transition-all ease-in-out duration-700 absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 ${
            activeToggle ? 'right-0' : 'left-0'
          } `}
        ></div>
      </div>
      {/* <!-- label --> */}
    </button>
  );
};
export default function FormQuestionOptions() {
  const params = useParams();
  const { typeId, name, qOptionName } = params;
  const [entries, setEntries] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [languageId, setLanguageId] = useState(1);
  const [searchParam, setSearchParam] = useState('');

  const { t, i18n } = useTranslation();

  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
  };
  // useEffect(() => {
  //   const language = localStorage.getItem('lang');
  //   if (language === 'ar') {
  //     setLanguageId(1);
  //   } else if (language === 'en') {
  //     setLanguageId(2);
  //   }
  //   console.log('language', language);
  //   // setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  // }, [languages.data]);

  let initialState = {
    typeId: typeId,
    paginationFilter: {
      pageNumber: pageNumber,
      pageSize: entries,
      numberOfPages: 0,
      isPaging: true,
      isFilter: false,
      langId: 0,
      isSearch: true,
      newsnumber: 0,
      parentId: 0,
      checkIsRequest: false,
      isRequest: true,
      searchFilter: {
        langId: languageId,
        term: searchParam,
      },
    },
  };

  let govQueryinitialState = {
    typeId: 1011,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const govQuery = useQuery(['formQuestionOption', govQueryinitialState], getFormQuestionOption, {
    staleTime: 99900,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  let ocuppationCategoryQueryinitialState = {
    typeId: 1014,
    paginationFilter: {
      pageNumber: 1,
      pageSize: 99999,
      isPaging: false,
      langId: 0,
    },
  };

  const ocuppationCategoryQuery = useQuery(
    ['formQuestionOption', ocuppationCategoryQueryinitialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const { data, paginationResult, isDone, isLoading, isError, refetch } = useQuery(
    ['formQuestionOption', initialState],
    getFormQuestionOption,
    {
      staleTime: 99900,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
  let paginationCount = [];

  useEffect(() => {
    refetch();
  }, [entries, paginationResult]);
  if (isLoading) return <Loading />;
  if (isError) return 'error';

  for (var i = 1; i <= data.paginationResult.numberOfPages; i++) {
    paginationCount.push(i);
  }
  // to control how many rows to show in table

  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };
  const oldTh = [
    { title: t(name) },
    name === 'Areas' ? { title: 'Governorate' } : undefined,
    name === 'Occupations' ? { title: 'Category' } : undefined,

    { title: 'Options', className: 'w-32' },
  ];
  const tabltHeader = oldTh.filter((e) => e != null);
  return (
    <>
      <header className="flex justify-between items-center w ">
        <BreadCrumbs to="/formQuestionOptionsTypes" name={t(name)} />
        <div className={`flex items-center space-x-3 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
          <DownloadPdf languageId={languageId} />
          <DownloadExcel languageId={languageId} />
        </div>
      </header>
      {/* <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Form Question Option Types')}</h2>
      </div> */}
      {/* Table */}
      <div className="bg-white rounded border border-[#e6e7e9] shadow-md mt-5 ">
        <div className="pt-6 px-5">
          <div className="border-[#eaedf0] border-b flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <h2 className="text-sm font-bold uppercase">{name}</h2>
              <h4 className="bg-move text-white py-1 px-3 rounded-md">{data.data.length}</h4>
            </div>

            <div className="flex transform  relative z-10 ">
              {languages && (
                <LanguageList languages={languages.data?.data} languageId={languageId} setLanguageId={setLanguageId} />
              )}
            </div>
          </div>
        </div>
        <div className=" px-5  mt-4">
          <div className=" flex   justify-between relative  ">
            <div className={` flex items-center space-x-3 pb-4 ${dir('', 'space-x-reverse')}`}>
              <CreateFormQuestionOptions typeId={typeId} languages={languages} />
            </div>

            <div className="flex transform  relative z-10 ">
              <TableSearch onSearch={onSearchParam} />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      {tabltHeader.map((e) => {
                        return (
                          <th
                            key={e.title}
                            scope="col"
                            className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase  ${
                              e.className
                            }`}
                          >
                            {t(e.title)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-normal">
                    {data.data.map((form) => {
                      return (
                        <tr key={form.id} className="">
                          <td className="px-6 py-4 whitespace-nowrap">
                            {form.frmQoptionLngs.map((e) => {
                              return (
                                <Fragment key={e.id}>
                                  {languageId === e.languageId && (
                                    <>
                                      <div className="flex items-center space-x-2">
                                        <b>{e.title}</b>
                                      </div>
                                    </>
                                  )}
                                </Fragment>
                              );
                            })}
                          </td>
                          {name === 'Areas' && (
                            <>
                              {/* {console.log(form.isParent)} */}

                              <td className="px-6 py-4 whitespace-nowrap">
                                {name === 'Areas' &&
                                  govQuery.data?.data
                                    ?.filter((e) => form.isParent === e.id)
                                    ?.map((e) => {
                                      console.log('e', e);
                                      return e.frmQoptionLngs[0].title;
                                    })}
                              </td>
                            </>
                          )}
                          {name === 'Occupations' && (
                            <>
                              {/* {console.log(form.isParent)} */}

                              <td className="px-6 py-4 whitespace-nowrap">
                                {name === 'Occupations' &&
                                  ocuppationCategoryQuery.data?.data
                                    ?.filter((e) => form.isParent === e.id)
                                    ?.map((e) => {
                                      console.log('e', e);
                                      return e.frmQoptionLngs[0].title;
                                    })}
                              </td>
                            </>
                          )}

                          <td
                            className={`px-6 py-4 whitespace-nowrap flex items-center ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            } space-x-2`}
                          >
                            <EditFormQuestionOptions form={form} />
                            <DeleteFormQuestionOptions id={form.id} />
                          </td>
                        </tr>
                      );
                    })}
                    {/* {data &&
                      data.data &&
                      data?.data?.map((page) => {
                        return (
                          <tr key={page.id} className=''>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <div className=' '>
                                {page.pgPageLngs[0]?.title}
                              </div>
                            </td>

                            <td className='px-6 py-4 whitespace-nowrap flex items-center space-x-2'>
                        

                              <a
                                href={`./pages/${page.id}`}
                                className='bg-green-100 text-green-800 hover:bg-green-200  p-1 rounded transition duration-300 ease-in-out'
                              >
                                <PecnilAlt className='w-5 h-5' />
                              </a>
                              <DeleteForm id={page.id} />
                            </td>
                          </tr>
                        );
                      })} */}
                  </tbody>
                </table>

                {/* pagination */}
                <TablePagination
                  data={data}
                  paginationCount={paginationCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
