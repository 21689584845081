import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../SharedComponents/Modal';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Loading from '../../../SharedComponents/Loading';
import { useTranslation } from 'react-i18next';
import LanguageList from '../../../SharedComponents/LanguageList';
import { ChangeRequestStatus, getLanguages } from '../../Requests/ApRequests';
import { useHistory, useParams } from 'react-router';
import { dir } from '../../../utils/localisation';
import { sendMessage } from '../../../utils/sendMessage';
import { useNotifications } from '../../../context/useNotifications';

export default function AddMessage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = props.id ? props : 1;
  const { typeId } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-green-500 space-x-1 ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } text-sm font-bold text-white flex items-center hover:bg-green-600 shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        {' '}
        <span>{t('Approve')}</span>
      </button>

      {isOpen && <ModalContainer id={id} typeId={typeId} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
}

const ModalContainer = (props) => {
  const { isOpen, setIsOpen, id, typeId } = props;
  const [languageId, setLanguageId] = useState(1);
  const [uploadSucess, setUploadSucess] = useState(false);
  const { qOptionName, requestStatus, requestId, formID } = useParams();
  console.log('params', useParams());
  const { t, i18n } = useTranslation();
  const userData = localStorage.getItem('token');
  const userId = JSON.parse(userData).userId;
  // create a loop for localisation
  const connection = useNotifications();
  const initialValues = {
    arMessage: 'تمت الموافقة',
    enMessage: 'Approved',
    isApproved: true,
    requestDataId: requestId,
  };
  const history = useHistory();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Page
  const mutation = useMutation(ChangeRequestStatus, {
    onSuccess: async (data) => {
      if (data.isDone) {
        sendMessage(connection, data.data.userIds, data.data.notificationMessage);
        queryClient.invalidateQueries(['GetRequestMessages']);

        setIsOpen(false);
        history.push(`/${qOptionName}/${formID}/${requestId}/${parseInt(requestStatus) + 1}`);
      }
    },
  });

  const onSubmit = (e) => {
    console.log('e', e);

    try {
      mutation.mutate({
        id: requestId,
        requestStatus: parseInt(requestStatus) + 1,
        requestMessages: [
          {
            arMessage: e.arMessage,
            enMessage: e.enMessage,
            isApproved: true,
            createdBy: parseInt(userId),
            requestDataId: requestId,
          },
        ],
        langId: languageId,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Formik onSubmit={onSubmit} validateOnBlur={false} validateOnChange={false} initialValues={initialValues}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // onSubmit={onSubmit}
                title={t('Approve Note')}
                isLoading={mutation.isLoading}
                add={true}
              >
                {/* Submit Form */}

                <label className={`flex  flex-col ${dir('justify-start', 'justify-end')}  space-y-2`}>
                  <span className={`font-bold text-base ${dir('text-left', 'text-right')}`}>{t('Message')}</span>
                  {i18n.language === 'en' ? (
                    <Field name="enMessage" className="border rounded py-2 px-3" as="textarea" />
                  ) : (
                    <Field name="arMessage" className="border rounded py-2 px-3" as="textarea" />
                  )}
                </label>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
