import { ChartBarIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import Loading from '../../../SharedComponents/Loading';

export default function ConsultingCounts(props) {
  const name = 'الاستشارات';
  const icon = <ChartBarIcon className="w-4 h-4" />;
  const initialQuery = {
    fromDate: DateTime.now().startOf('month').toISODate(),
    toDate: DateTime.now().endOf('month').toISODate(),
  };

  const fetchConsultingCounts = async (e) => {
    const { data } = await axios.post('/Consulting/GetConsultingCount', e.queryKey[1]);
    return data;
  };

  const { data, isLoading, isError } = useQuery([`ConsultingCounts`, initialQuery], fetchConsultingCounts);

  if (isLoading)
    return (
      <div className="bg-white h-32 text-center flex justify-center items-center relative rounded-md shadow border p-4">
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  if (isError) return '';
  const info = data.data;
  return (
    <>
      <div className="bg-white rounded-md shadow border p-4">
        <div className="flex  items-center justify-between">
          <div className="flex space-x-2 items-center space-x-reverse">
            {icon}
            <span>{name}</span>
          </div>
          <div>
            <span>{info.totalCount}</span>
          </div>
        </div>
        <hr className="block my-4" />
        <div className="grid grid-cols-4 gap-2 divide-x divide-x-reverse">
          <div className="px-2">
            <div className="flex justify-between">
              <span>مجاوب</span>
              <span>{info.answered}</span>
            </div>
            <div className="bg-gray-100 h-2 w-full mt-2 relative">
              <div
                className="absolute h-full bg-green-500"
                style={{ width: `${(info.answered * 100) / info.totalCount}%` }}
              ></div>
            </div>
          </div>
          <div className="px-2">
            <div className="flex justify-between">
              <span>غير مجاوب</span>
              <span>{info.notAnswered}</span>
            </div>
            <div className="bg-gray-100 h-2 w-full mt-2 relative">
              <div
                className="absolute h-full bg-red-500"
                style={{ width: `${(info.notAnswered * 100) / info.totalCount}%` }}
              ></div>
            </div>
          </div>
          <div className="px-2">
            <div className="flex justify-between">
              <span>عام</span>
              <span>{info.faq}</span>
            </div>
            <div className="bg-gray-100 h-2 w-full mt-2 relative">
              <div
                className="absolute h-full bg-yellow-500"
                style={{ width: `${(info.faq * 100) / info.totalCount}%` }}
              ></div>
            </div>
          </div>
          <div className="px-2">
            <div className="flex justify-between">
              <span>خاص</span>
              <span>{info.notFAQ}</span>
            </div>
            <div className="bg-gray-100 h-2 w-full mt-2 relative">
              <div
                className="absolute h-full bg-blue-500"
                style={{ width: `${(info.notFAQ * 100) / info.totalCount}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
