import { Field, FieldArray, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import Loading from '../../../SharedComponents/Loading';
import { editForm, getForm } from '../ApiForms';

export default function GeneralFormSettings(props) {
  const [loading, setLoading] = useState(false);
  const { languageId, setFormTitles } = props;
  const params = useParams();
  const { id } = params;
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery(['form'], getForm(id, 0));

  const { t, i18n } = useTranslation();
  const mutateGeneralFormSettings = useMutation(editForm, {
    onSuccess: () => {
      queryClient.invalidateQueries(['forms']);
      queryClient.invalidateQueries(['form']);
      // setIsOpen(false);

      setLoading(false);
    },
  });
  if (isLoading) return <Loading />;
  if (isError) return 'error';
  const initialValues = data.data;
  setFormTitles(initialValues.frmFormLngs);
  const onSubmit = (e) => {
    try {
      setLoading(true);
      mutateGeneralFormSettings.mutate(e);
    } catch (error) {}
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        className="text-sm font-bold"
        initialValues={{
          ...initialValues,
          rejectResponseOnDate: DateTime.fromISO(initialValues.rejectResponseOnDate).toISODate(),
        }}
      >
        {({ values, setFieldValue }) => {
          // console.log('values', values);
          return (
            <Form>
              {/* Success   */}
              {/* Still Waiting for the API  */}
              <label
                className={`flex items-center space-x-2 ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } mb-2 text-sm font-bold text-gray-600`}
              >
                <Field type="checkbox" name="actionSuccessMessageEnabled" className="w-3 h-3" />
                <span>{t('Success Message?')}</span>
              </label>
              {values.frmFormLngs.map((page, index) => (
                <FieldArray
                  key={page.id}
                  name="frmFormLngs"
                  render={(frmFormLngs) => (
                    <>
                      {languageId === page.languageId && (
                        <Field
                          className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                          placeholder={t('Success Message')}
                          name={`frmFormLngs[${index}].actionSuccessMessage`}
                          type="text"
                        />
                      )}
                    </>
                  )}
                />
              ))}
              {/* fAILED mESSAGED   */}
              {/* Still Waiting for the API  */}
              <label
                className={`flex items-center space-x-2 mb-2 ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } text-sm font-bold text-gray-600`}
              >
                <Field type="checkbox" name="actionFailedMessageEnabled" className="w-3 h-3" />
                <span>{t('Failed Message?')}</span>
              </label>
              {values.frmFormLngs.map((page, index) => (
                <FieldArray
                  key={page.id}
                  name="frmFormLngs"
                  render={(frmFormLngs) => (
                    <>
                      {languageId === page.languageId && (
                        <Field
                          className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                          placeholder={t('Reject Message')}
                          name={`frmFormLngs[${index}].actionFailedMessage`}
                          type="text"
                        />
                      )}
                    </>
                  )}
                />
              ))}

              {/* Success Redirect Url  */}
              {/* Still Waiting for the API  */}
              <label
                className={`flex items-center space-x-2 ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } mb-2 text-sm font-bold text-gray-600`}
              >
                <Field type="checkbox" name="actionSuccessRedirectEnabled" className="w-3 h-3" />
                <span>{t('Success Redirect URL?')}</span>
              </label>
              <Field
                className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Success Url')}
                name="actionSuccessRedirectUrl"
                type="text"
              />
              <Field
                className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Enter Number In Seconds')}
                name="actionSucessRedirectDelay"
                type="number"
              />
              {/* FAiled Redirect Url  */}
              {/* Still Waiting for the API  */}
              <label
                className={`flex items-center space-x-2 ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } mb-2 text-sm font-bold text-gray-600`}
              >
                <Field type="checkbox" name="actionFailedRedirectEnabled" className="w-3 h-3" />
                <span>{t('Failed Redirect URL?')}</span>
              </label>
              <Field
                className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Failed Url')}
                name="actionFailedRedirectUrl"
                type="text"
              />
              <Field
                className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Enter Number In Seconds')}
                name="actionFailedRedirectDelay"
                type="number"
              />
              {/* Recaptcha */}
              <label
                className={`flex items-center space-x-2 ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } mb-2 text-sm font-bold text-gray-600`}
              >
                <Field type="checkbox" name="reCaptchaEnabeled" className="w-3 h-3" />
                <span>{t('Use Recaptcha?')}</span>
              </label>
              <Field
                className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Server Key')}
                name="reCaptchaServerKey"
                type="text"
              />
              <Field
                className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Public Key')}
                name="reCaptchaPublicKey"
                type="text"
              />
              {/* Reject */}
              <label
                className={`flex items-center space-x-2 ${
                  i18n.language === 'en' ? '' : 'space-x-reverse'
                } mb-2 text-sm font-bold text-gray-600`}
              >
                <Field type="checkbox" name="rejectResponseEnabled" className="w-3 h-3" />
                <span>{t('Reject Response?')}</span>
              </label>
              <Field
                className="border rounded text-gray-400  w-full mb-2 p-2 text-sm focus:outline-none"
                placeholder={t('Select Date')}
                name="rejectResponseOnDate"
                type="date"
              />
              {values.frmFormLngs.map((page, index) => (
                <FieldArray
                  key={page.id}
                  name="frmFormLngs"
                  render={(frmFormLngs) => (
                    <>
                      {languageId === page.languageId && (
                        <Field
                          className="border rounded  w-full mb-2 p-2 text-sm focus:outline-none"
                          placeholder={t('Reject Message')}
                          name={`frmFormLngs[${index}].rejectResponseMessage`}
                          type="text"
                        />
                      )}
                    </>
                  )}
                />
              ))}
              <div className="text-right my-2">
                <button
                  type="submit"
                  className="bg-darkblue hover:bg-darkbluehover transition duration-300  block rounded w-full text-white p-2 relative"
                >
                  {loading && (
                    <svg
                      className="animate-spin w-6 h-6 inline-block absolute right-0 mr-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {t('Update Settings')}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
