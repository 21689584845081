import { PencilIcon } from '@heroicons/react/outline';
import { FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import * as Yup from 'yup';
import Button from '../../../SharedComponents/Button/Button';
import Text from '../../../SharedComponents/Inputs/components/Text';
import Modal from '../../../SharedComponents/Modal/Modal';

export default function EditComplaintDepartment(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { name, info } = props;
  const sharedProps = { isOpen, setIsOpen, name, info };
  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        noText
        icon={<PencilIcon className="h-4" />}
        className="text-sm px-2 mt-0"
      />

      {isOpen && <ModalContainer {...sharedProps} />}
    </>
  );
}

const ModalContainer = (props) => {
  const queryClient = useQueryClient();

  const { isOpen, setIsOpen, name, info } = props;
  const sharedProps = { isOpen, setIsOpen };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('هذا الحقل مطلوب'),
  });
  const initialValues = info;
  const mutation = useMutation(mutationFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries('complaintDepartments');
      setIsOpen(false);
      toast.success('تم التعديل بنجاح');
    },
  });
  const onSubmit = (e) => {
    mutation.mutate(e);
  };
  return (
    <>
      {isOpen && (
        <Modal modalTitle="تعديل قسم شكوى" {...sharedProps}>
          <Formik onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
            {({ values, errors }) => {
              return (
                <Form className="rtl" dir="rtl">
                  <div className="flex flex-col space-y-4">
                    <Text wrapperClass="mt-0" errors={errors} name="name" required label="الاسم" />
                  </div>
                  <div className="flex space-x-2 space-x-reverse">
                    <Button
                      type="submit"
                      loading={mutation.isLoading}
                      className="bg-green-500 hover:bg-green-600 w-1/4"
                    />
                    <Button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      text="الغاء"
                      className="bg-red-500 hover:bg-red-600 w-1/4"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};

const mutationFunction = async (e) => {
  const { data } = await axios.post(`ComplainDepartment/Edit`, e);

  return data;
};
