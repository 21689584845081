import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { PrinterIcon } from '@heroicons/react/outline';
import Button from './Button/Button';

const DynamicPrinting = (props) => {
  const { t, i18n } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => props.componentRef.current,
  });
  return (
    <div>
      <Button
        onClick={handlePrint}
        icon={<PrinterIcon className="w-4 h-4" />}
        text="طباعة"
        className=" print:hidden  mt-0 px-8"
      >
        <span className={` font-bold`}>{t('Print')}</span>
      </Button>
    </div>
  );
};

export default DynamicPrinting;
