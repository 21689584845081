import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import Loading from '../../SharedComponents/Loading';
import { AddAnswerList, GetAllForms, GetDataOfRequest } from './ApRequests';
import inputs from './utils/utils';
import { getDateHandler } from './utils/getDateHandler';
import Notification from '../../SharedComponents/Notification';
import toast from 'react-hot-toast';
import { dir } from '../../utils/localisation';
export default function CreateFormData(props) {
  const { formID } = props;
  const params = useParams();
  const { requestId } = params;
  const [languageId, setLanguageId] = useState(1);
  const { t } = useTranslation();
  const addAnswerListMutation = useMutation(AddAnswerList, {
    onSuccess: (data) => {
      toast.custom(<Notification message={t('Data Saved, Successfully')} />);
    },
    onError: () => {
      toast.custom(<Notification message={t("We're sorry, you don't have permission")} error={true} />);
    },
  });
  const onSubmit = (values) => {
    console.log('submitted');
    addAnswerList(requestId, values);
  };
  //   get All Form Inputs from form
  const { data, isLoading, isError } = useQuery(['GetAllForms', { formId: formID }], GetAllForms, {
    retry: false,
  });
  if (isLoading)
    return (
      <div
        className="relative bg-white"
        style={{
          minHeight: '500px',
        }}
      >
        <Loading />
      </div>
    );
  if (isError) return <div className="p-8">{t("WE Are Sorry You Don't Have The Permisssion to see this Form")}</div>;

  //   getInitialValues Function
  const getInitialValues = () => {
    const arrayOfKeys = data.data.map((item) => item.inputName);
    const object = {};

    for (let index = 0; index < arrayOfKeys.length; index++) {
      const key = arrayOfKeys[index];
      object[key] = '';
    }
    return object;
  };
  const initialValues = getInitialValues();

  const addAnswerList = (outId, val) => {
    const userData = localStorage.getItem('token');
    const userId = userData ? JSON.parse(userData).userId : 0;
    const entries = Object.entries(val);
    const newArr = entries?.map((e) => e);
    const newData = newArr.map((x, index) => {
      const questionId = data.data.find((e) => e.inputName === `${x[0]}`).id;
      let l = {
        questionId: questionId,
        answer: x[1],
        userId: userId,
        formId: formID,
        creationDateString: getDateHandler(),
        createDate: new Date(),
        RequestDataId: parseInt(outId),
        request: {
          requestStatus: 1,
        },
      };
      return l;
    });
    console.log(newData, 'newData');
    addAnswerListMutation.mutate(newData);
  };
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="flex flex-col   relative   px-6 py-6 text-gray-600 font-bold  ">
            {data.data.map((item, index) => {
              if (inputs[item.qtypeId] === 'textarea') {
                return (
                  <label key={index} className={`flex  flex-col mb-8 justify-start space-y-2`}>
                    <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                    <Field
                      name={item?.inputName}
                      value={values[item?.inputName]}
                      className="border rounded py-2 px-3"
                      type={inputs[item.qtypeId]}
                      as="textarea"
                      onChange={(e) => {
                        setFieldValue(item?.inputName, e.target.value);
                      }}
                    />
                  </label>
                );
              } else if (inputs[item.qtypeId] === 'checkbox') {
                return (
                  <label key={index} className={`flex  flex-col justify-start space-y-2`}>
                    <div className={`flex items-center space-x-3 ${dir('', 'space-x-reverse')} `}>
                      <Field
                        name={item?.inputName}
                        value={values[item?.inputName]}
                        className="border rounded py-2 px-3"
                        type={inputs[item.qtypeId]}
                        checked={values[item?.inputName]}
                        onChange={(e) => {
                          if (inputs[item.qtypeId] === 'checkbox') {
                            setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                          } else {
                            setFieldValue(item?.inputName, e.target.value);
                          }
                        }}
                      />
                      <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                    </div>
                  </label>
                );
              }
              return (
                <label key={index} className={`flex  flex-col mb-8 justify-start space-y-2`}>
                  <span>{item.frmQuestionLngs.filter((item) => item.languageId === languageId)[0]?.title}</span>
                  <Field
                    name={item?.inputName}
                    value={values[item?.inputName]}
                    className="border rounded py-2 px-3"
                    type={inputs[item.qtypeId]}
                    checked={values[item?.inputName]}
                    onChange={(e) => {
                      if (inputs[item.qtypeId] === 'checkbox') {
                        setFieldValue(item?.inputName, Boolean(!values[item?.inputName]));
                      } else {
                        setFieldValue(item?.inputName, e.target.value);
                      }
                    }}
                  />
                </label>
              );
            })}
            <button
              type="submit"
              className="bg-darkblue w-24 flex justify-center items-center hover:bg-darkbluehover text-white px-4 py-2 rounded"
            >
              {t('Next')}
              {/* {addAnswerListMutation.isLoading || (getRequestIdMutation.isLoading && <LoadingCircle />)} */}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}
