export const sendMessage = async (connection, users, message) => {
  console.log('data', connection);
  console.log('connection', connection);
  console.log('users', users);
  console.log('message', message);
  if (connection) {
    const usersIds = users.map((item) => item.toString());
    try {
      await connection?.invoke('SendMessage', usersIds, message);
    } catch (error) {
      console.log('sss', error);
    }
  }
  //   if (connection) await connection.recieve('RecieveMessage', 'Kenz');
  //   setInputText('');
};
